/* eslint-disable @typescript-eslint/no-unused-vars */
import { React } from '@/app'
import { Button, DashboardExports } from '@/components'
import { DashboardExportParams } from '@/services/api/dashboard'
import { Profile } from '@/types'
import { useState } from '@codeleap/common'

type ExportProps = {
  onExport: (e: DashboardExportParams['type']) => void
  isLoading: boolean
  profile: Profile
}

export const Export = ({ onExport, isLoading, profile }: ExportProps) => {
  const [type, setType] = useState<DashboardExportParams['type'] | string>('')

  const handleClose = () => {
    DashboardExports.Modal.close()
    setType('')

  }

  const handleExport = () => {
    handleClose()
    onExport(type as DashboardExportParams['type'])
  }

  return (
    <>
      <Button
        text='Export information'
        debugName='Export button'
        onPress={DashboardExports.Modal.open}
        variants={['flat', 'small', 'fontWeight:600', isLoading ? 'loadingLeft' : '']}
        icon='download'
        rightIcon={!isLoading ? 'chevron-down' : null}
        disabled={isLoading}
        loadingShowText
        loading={isLoading}
        debounce={1000}
      />

      <DashboardExports.Modal.Component
        type={type}
        setType={setType}
        handleExport={handleExport}
      />
    </>
  )
}
