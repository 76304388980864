import { AppStatus } from '@/redux'
import { getFirebaseAuth } from '../../firebaseApp'
import { QueryKeys } from '../queryKeys'
import { useSession } from './useSession'
import { ERRORS, isEmailAvailable } from './views'
import { SocialProviderTypes } from './social'
import { Profile } from '@/types'
import { APIClient } from '@/services'
import { ReactQuery } from '@codeleap/common'

export function useSignup() {
  const { warnEmailInUse } = useSession()
  const createProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: APIClient.Session.create,
    onSuccess: QueryKeys.me.refresh,
  })
  const sendEmailVerification = async (user) => {
    logger.debug('Sending email verification', user, 'Auth')
    await user.sendEmailVerification()
  }

  const signup = async (data:Partial<Profile>, source: SocialProviderTypes|'email' = 'email') => {
    const firebaseAuth = await getFirebaseAuth()
    data.email = data.email.toLowerCase()

    AppStatus.set('loading')
    try {
      const emailAvailable = await isEmailAvailable(data.email)
      logger.debug('Email available', emailAvailable, 'Auth')
      let currentUser = firebaseAuth().currentUser
      logger.debug('Firebase currentUser', currentUser, 'Sign Up')

      currentUser?.reload?.()

      logger.debug('Firebase currentUser (after reload)', currentUser, 'Sign Up')

      if (!emailAvailable) {
        warnEmailInUse()
        throw ERRORS.emailInUse
      }

      try {
        if (source === 'email') {
          logger.debug('Creating user with email and password', data, 'Sign Up')
          const newUser = await firebaseAuth().createUserWithEmailAndPassword(data.email, data.password)
          currentUser = newUser.user
        }

        currentUser?.reload?.()

        if (!currentUser?.emailVerified) {
          await sendEmailVerification(currentUser)
        }

        currentUser?.reload?.()

      } catch (e) {
        AppStatus.set('idle')
        logger.error('Error on signup', e, 'Auth')

        if (e.code === 'auth/email-already-in-use') {
          warnEmailInUse()
          return
        }
      }
      logger.debug('Creating profile', data, 'Sign Up')

      const { id } = await createProfile.mutateAsync(data)
      await QueryKeys.me.refresh()

      AppStatus.set('done')

      return {
        user: {
          id,
          ...currentUser,
        },
      }
    } catch (e) {
      AppStatus.set('idle')

      logger.error('Error on signup', e, 'Auth')
    }
  }

  return { signup, sendEmailVerification }
}
