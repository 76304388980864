import { View, ActivityIndicator } from '@codeleap/web'
import { Dashboard, DashboardChartColors, DashboardCharts, getOverviewData } from '..'
import { JournalSubmissionsDetails } from '@/types'
import { variantProvider } from '@/app'

type ChartProps = {
  isDetailsLoading: boolean
  isRefetching: boolean
  details: JournalSubmissionsDetails
  isExport?: boolean
}

export const Chart = (props: ChartProps) => {
  const { isDetailsLoading, isRefetching, details, isExport = false } = props

  const overviewData = getOverviewData(details)

  const alignment = isExport ? 'alignStart' : 'alignCenter'
  const direction = isExport ? 'row' : 'column'

  return (
    <View variants={['justifyCenter', 'gap:2', 'fullWidth', alignment, direction]} >
      <View
        variants={[isExport ? 'column' : 'row', 'gap:2', 'alignCenter', 'fullWidth']}
        responsiveVariants={{ small: ['fullWidth', 'alignStart', 'column', 'gap:1.5'] }}
        style={isExport ? { maxWidth: 300 } : {}}
      >
        {overviewData?.map((item, index) => {
          return (
            <Dashboard.OverviewCard
              value={item?.value ?? 0}
              percentage={item?.percentage ?? 0}
              description={item?.description}
              isDays={item?.isDays}
              isLoading={isDetailsLoading || isRefetching}
              key={index}
            />
          )
        })}
      </View>
      <View style={styles.barChartWrapper}>
        {isDetailsLoading || isRefetching ? (
          <View variants={['fullWidth', 'alignCenter', 'justifyCenter']}>
            <ActivityIndicator size={40} debugName={'Dashboard Overview ActivityIndicator'} />
          </View>
        ) : (
          <DashboardCharts.HorizontalBar columns={details?.status} chartColumnsColor={DashboardChartColors.statusChartColors} />
        )}
      </View>

    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  barChartWrapper: {
    borderRadius: theme.borderRadius.small,
    ...theme.spacing.padding(2),
    border: `1px solid ${theme.colors.neutral3}`,
    width: '100%',
  },
}), true)

