import { PermissionLevels } from '@/types'
import { createForm } from '@codeleap/common'

export const permissionForm = createForm('permissionForm', {
  journal: {
    type: 'select',
    label: 'Journal',
    placeholder: 'Select a journal',
  },
  'role_publisher': {
    type: 'checkbox',
    label: PermissionLevels.role_publisher,
    defaultValue: false,
    checkboxOnLeft: true,
  },
  'role_managing_editor': {
    type: 'checkbox',
    label: PermissionLevels.role_managing_editor,
    defaultValue: false,
    checkboxOnLeft: true,
  },
  'role_editor_chiefe': {
    type: 'checkbox',
    label: PermissionLevels.role_editor_chiefe,
    defaultValue: false,
    checkboxOnLeft: true,
  },
  'role_editor': {
    type: 'checkbox',
    label: PermissionLevels.role_editor,
    defaultValue: false,
    checkboxOnLeft: true,
  },

})
