/* eslint-disable max-lines */
import { variantProvider } from '../theme'
import { ButtonComposition, ButtonPresets } from '@codeleap/web'
import { shadeColor } from '@codeleap/common'
import { assignTextStyle } from './Text'

const createButtonStyle = variantProvider.createVariantFactory<ButtonComposition>()

export const AppButtonStyles = {
  ...ButtonPresets,
  default: createButtonStyle((theme) => ({
    wrapper: {
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      ...theme.presets.row,
      ...theme.presets.relative,
      backgroundColor: theme.colors.primary5,
      borderRadius: theme.borderRadius.tiny,
      transitionProperty: 'background, color, border, filter',
      transitionDuration: '0.2s',
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
      width: 'auto',

      '&:hover': {
        backgroundColor: theme.colors.primary4,
      },
      // '&:active': {
      //   backgroundColor: theme.colors.primary3,
      // },
      ...theme.spacing.padding(1),
      ...theme.spacing.paddingHorizontal(2),

    },
    text: {
      textAlign: 'center',
      color: theme.colors.neutral1,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    'loaderWrapper': {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
    icon: {
      color: theme.colors.neutral1,
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
      minWidth: theme.values.iconSize[1],
      minHeight: theme.values.iconSize[1],
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft('auto'),
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,

      cursor: 'not-allowed',

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
  })),
  'default:hover': createButtonStyle((theme) => ({
    wrapper: {
      '&:hover': {
        backgroundColor: theme.colors.primary5,

        p: {
          color: theme.colors.neutral1,
        },
      },
    },
    'wrapper:disabled': {
      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
  })),
  large: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
      borderRadius: theme.borderRadius.tiny,
    },
    text: {
      fontSize: theme.typography.base.styles.p1.size,
      fontWeight: '600',
    },
    'loaderWrapper': {
      width: theme.values.iconSize[5],
      height: theme.values.iconSize[5],
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  small: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.small,
      ...theme.spacing.paddingHorizontal(2),
      borderRadius: theme.borderRadius.nano,
    },
    text: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: '600',
    },
    loaderWrapper: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  tiny: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.tiny,
      ...theme.spacing.paddingHorizontal(2),
      borderRadius: theme.borderRadius.tiny,
    },
    text: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: '700',
    },
    loaderWrapper: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  floating: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  flat: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral4,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral4,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    text: {
      color: theme.colors.primary5,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    icon: {
      color: theme.colors.primary5,
    },
    'leftIcon': {
      ...theme.spacing.marginRight(1),
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.primary5,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  'flat:hover': createButtonStyle((theme) => ({
    wrapper: {
      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'wrapper:disabled': {
      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
  })),
  'minimal:hover': createButtonStyle((theme) => ({
    wrapper: {
      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
  })),
  'flat:destructive': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    leftIcon: {
      color: theme.colors.destructive2,
    },
  })),
  'primary:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    text: {
      color: theme.colors.primary3,
    },
    icon: {
      color: theme.colors.primary3,
    },
    loaderWrapper: {
      color: theme.colors.primary3,
    },
  })),
  minimal: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    text: {
      color: theme.colors.primary5,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    icon: {
      color: theme.colors.primary5,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.primary5,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  destructive: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.destructive2,

      '&:hover': {
        backgroundColor: theme.colors.destructive2,
        filter: 'brightness(90%)',
      },
      '&:active': {
        backgroundColor: theme.colors.destructive2,
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral1,
    },
    loaderWrapper: {
      color: theme.colors.neutral1,
    },
  })),
  'destructive:flat': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral4,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral4,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,
    },
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      backgroundColor: 'transparent',
    },
  })),
  'destructive:minimal': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    text: {
      color: theme.colors.destructive2,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.destructive2,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  'text:semiBold': createButtonStyle((theme) => ({
    text: {
      fontWeight: '600',
    },
  })),
  'text:neutral10': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral10,
    },
  })),
  destructive2: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.destructive2,

      '&:hover': {
        backgroundColor: shadeColor(theme.colors.destructive2, -30),
      },
    },
  })),
  list: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
      backgroundColor: theme.colors.neutral2,
      borderRadius: 0,
      borderTopStyle: 'solid',
      borderTopWidth: theme.values.borderWidth.small,
      borderTopColor: theme.colors.neutral3,
      ...theme.presets.justifyStart,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral3,
      },

      '&:first-of-type': {
        borderTopLeftRadius: theme.borderRadius.small,
        borderTopRightRadius: theme.borderRadius.small,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: theme.borderRadius.small,
        borderBottomRightRadius: theme.borderRadius.small,
        borderBottomWidth: 0,
      },
    },
    text: {
      fontWeight: '500',
      ...theme.spacing.marginLeft(1),
      color: theme.colors.neutral10,
      textAlign: 'left',
    },
    loaderWrapper: {
      width: theme.values.iconSize[5],
      height: theme.values.iconSize[5],
    },
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    leftIcon: {
      ...theme.spacing.marginLeft(1),
      marginRight: 0,
    },
    rightIcon: {
      ...theme.spacing.marginRight(1),
    },
  })),
  'list:first': createButtonStyle((theme) => ({
    wrapper: {
      borderTopWidth: 0,
      borderTopRightRadius: theme.borderRadius.small,
      borderTopLeftRadius: theme.borderRadius.small,
    },
  })),
  'list:last': createButtonStyle((theme) => ({
    wrapper: {
      borderBottomRightRadius: theme.borderRadius.small,
      borderBottomLeftRadius: theme.borderRadius.small,
    },
  })),
  'list:selected': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary3,

      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
      '&:active': {
        backgroundColor: theme.colors.primary4,
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  noPadding: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),
    },
  })),
  'text:capitalize': createButtonStyle((theme) => ({
    text: {
      textTransform: 'capitalize',
    },
  })),
  'text:uppercase': createButtonStyle((theme) => ({
    text: {
      textTransform: 'uppercase',
    },
  })),

  // Old variants
  circle: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
      ...theme.spacing.padding(1),
    },
  })),
  pill: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.paddingHorizontal(1),
      ...theme.spacing.paddingVertical(0.5),
    },
  })),
  icon: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: 'transparent',
      aspectRatio: 1,
      display: 'flex',
      ...theme.presets.center,
      ...theme.spacing.padding(0),
    },
    text: {
      flex: 1,
      textAlign: 'center',
    },
    loaderWrapper: {
      ...theme.spacing.margin(0),
    },
    icon: {
      ...theme.spacing.margin(0),
      ...theme.presets.center,
      height: null,
      width: null,
      color: theme.colors.neutral1,
    },
    leftIcon: {
      ...theme.spacing.marginRight(0),
    },
    rightIcon: {
      ...theme.spacing.marginRight(0),
    },
  })),
  neutral7: createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral7,
      fontWeight: 400,
    },
    icon: {
      color: theme.colors.neutral7,
    },
  })),
  neutral2: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    text: {
      color: theme.colors.neutral10,
    },
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  'text:neutral8': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral8,
    },
  })),
  'text:neutral10': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral10,
    },
  })),
  'icon:neutral8': createButtonStyle((theme) => ({
    icon: {
      color: theme.colors.neutral8,
    },
  })),
  'icon:neutral10': createButtonStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  'text:destructive2': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.destructive2,
    },
  })),
  'icon:destructive2': createButtonStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
  })),
  'text:primary': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.primary3,
    },
  })),
  'text:primary5': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.primary5,
    },
  })),
  'icon:primary5': createButtonStyle((theme) => ({
    icon: {
      color: theme.colors.primary5,
    },
  })),
  positive2: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.positive2,
    },
  })),
  select: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
      ...theme.presets.justifySpaceBetween,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },

      '&:active': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    icon: {
      size: theme.values.iconSize[2],
      color: theme.colors.neutral7,
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  white: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
    },
  })),
  round: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  'text:left': createButtonStyle((theme) => ({
    text: {
      textAlign: 'left',
    },
  })),
  hideText: createButtonStyle((theme) => ({
    text: {
      display: 'none',
    },
  })),
  link: createButtonStyle((theme) => ({
    'wrapper': {
      textDecoration: 'none',
      color: theme.colors.neutral10,
    },
    text: {
      textDecoration: 'none',
      color: theme.colors.neutral10,
    },
  })),
  'link:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),
      color: theme.colors.primary3,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  'center:all': createButtonStyle((theme) => ({
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  'text:p1': createButtonStyle((theme) => ({
    text: {
      ...assignTextStyle('p1')(theme).text,
    },
  })),
  'text:p2': createButtonStyle((theme) => ({
    text: {
      ...assignTextStyle('p2')(theme).text,
    },
  })),
  'backgroundColor:neutral10': createButtonStyle((theme) => ({
    wrapper: {
      background: theme.colors.neutral10,
      '&:hover': {
        background: theme.colors.neutral10,
      },
    },
    'wrapper:disabled': {
      background: theme.colors.neutral10,
      '&:hover': {
        background: theme.colors.neutral10,
      },
    },
  })),
  'backgroundColor:neutral1': createButtonStyle((theme) => ({
    wrapper: {
      background: theme.colors.neutral1,
      '&:hover': {
        background: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      background: theme.colors.neutral1,
      '&:hover': {
        background: theme.colors.neutral1,
      },
    },
  })),
  'color:neutral10': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral10,
    },
    'text:disabled': {
      color: theme.colors.neutral10,
    },
  })),
  'color:neutral1': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral1,
    },
    'text:disabled': {
      color: theme.colors.neutral1,
    },
  })),
  'iconSize:2': createButtonStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  addJournalDisclaimerButton: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.neutral2,
    },
    text: {
      color: theme.colors.primary5,
      fontWeight: '600',
      ...theme.spacing.marginLeft(1),
    },
    icon: {
      color: theme.colors.primary5,
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  roleSelector: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.fullWidth,
      ...theme.spacing.marginBottom(1),
      ...theme.presets.justifyStart,
      height: theme.values.itemHeight.default,
      borderRadius: theme.borderRadius.tiny,
      ...theme.spacing.padding(2),

      '&:last-of-type': {
        ...theme.spacing.margin(0),
      },

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.primary5,

      '&:hover': {
        backgroundColor: theme.colors.primary3,
      },
    },

    text: {
      color: theme.colors.neutral8,
    },
    'text:selected': {
      color: theme.colors.neutral1,
    },

    icon: {
      ...theme.sized(2.5),
    },
  })),
  'height:default': createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
    },
  })),
  loadingLeft: createButtonStyle((theme) => ({
    loaderWrapper: {
      order: 1,
    },
    text: {
      order: 2,
    },
  })),
  manageEditors: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.tiny,
    },
    text: {
      fontWeight: '600',
    },
    'text:disabled': {
      color: theme.colors.neutral7,
    },
    'icon:disabled': {
      color: theme.colors.neutral7,
    },
  })),
  disabled: createButtonStyle((theme) => ({
    wrapper: {
      cursor: 'not-allowed',
    },
  })),
  'disabled:bare': createButtonStyle((theme) => ({
    wrapper: {
      background: 'none',
      '&:hover': {
        background: 'none',
      },
    },
    'wrapper:disabled': {
      background: 'none',
      '&:hover': {
        background: 'none',
      },
    },
    text: {
      color: 'none',
      '&:hover': {
        color: 'none',
      },
    },
    'text:disabled': {
      color: 'none',
      '&:hover': {
        color: 'none',
      },
    },
  })),
  selectEditors: createButtonStyle((theme) => {

    const height = 72

    return {
      wrapper: {
        backgroundColor: theme.colors.neutral1,
        borderRadius: theme.borderRadius.tiny,
        height,

        justifyContent: 'space-between',
        '&:hover': {
          backgroundColor: theme.colors.neutral2,
        },
      },
      text: {
        color: theme.colors.neutral8,
        fontWeight: '400',
      },
    }
  }),
  sendNewPublicationVersionButton: createButtonStyle((theme) => ({
    wrapper: {
      maxWidth: 300,
      ...theme.spacing.padding(1.8),
      borderRadius: theme.borderRadius.tiny,
    },
    text: {
      fontWeight: '600',
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  menu: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.tiny,
    },
    text: {
      fontWeight: '600',
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  withdrawButton: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral2,
      '&:hover > p': {
        color: theme.colors.neutral1,
      },
      '&:hover > svg > *': {
        stroke: `${theme.colors.neutral1} !important`,
      },
    },
    text: {
      color: theme.colors.neutral10,
    },
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  'fontWeight:700': createButtonStyle(theme => ({
    text: {
      fontWeight: '700',
    },
  })),
  'fontWeight:500': createButtonStyle(theme => ({
    text: {
      fontWeight: '500',
    },
  })),
  'fontWeight:400': createButtonStyle(theme => ({
    text: {
      fontWeight: '400',
    },
  })),
  'fontWeight:600': createButtonStyle(theme => ({
    text: {
      fontWeight: '600',
    },
  })),
  'text:h3': createButtonStyle(theme => ({
    text: {
      ...assignTextStyle('h3')(theme).text,
    },
  })),
  'blindfoldedToaster': createButtonStyle(theme => ({
    wrapper: {
      width: 180,
    },
  })),
  'blindfoldedUploadFileButton': createButtonStyle(theme => ({
    wrapper: {
      height: theme.values.itemHeight.default,
    },
    text: {
      fontWeight: '600',
    },
  })),
  'blindfoldedSubmitButton': createButtonStyle(theme => ({
    wrapper: {
      height: theme.values.itemHeight.default,
      ':hover': {
        backgroundColor: theme.colors.primary5,
      },
      ':hover > p': {
        color: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      ':hover': {
        backgroundColor: theme.colors.neutral2,
      },
      ':hover > p': {
        color: theme.colors.neutral5,
      },
    },
    text: {
      // color: theme.colors.neutral7,
      fontWeight: '600',
    },
  })),
  'sortList': createButtonStyle(theme => ({
    wrapper: {
      ...theme.spacing.marginBottom(1),
      ...theme.spacing.padding(1.5),
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral1,
      minWidth: '100%',
      maxWidth: '100%',
      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:hover > svg:last-of-type > *': {
        stroke: `${theme.colors.neutral8} !important`,
      },
    },
    icon: {
      color: theme.colors.neutral8,
      width: 20,
      height: 20,
    },
    rightIcon: {
      color: theme.colors.neutral1,
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.primary5,
      '&:hover': {
        backgroundColor: theme.colors.primary5,
      },
      'p': {
        color: theme.colors.neutral1,
        fontWeight: '400',
      },
      'svg > *': {
        stroke: `${theme.colors.neutral1} !important`,
      },
      '&:hover > svg:last-of-type > *': {
        stroke: `${theme.colors.neutral1} !important`,
      },
    },
    text: {
      color: theme.colors.neutral8,
    },
  })),
  'sorter': createButtonStyle(theme => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.tiny,
      minWidth: 100,
      height: theme.values.itemHeight.default,
      ...theme.spacing.paddingHorizontal(2),
      [theme.media.down('large')]: {
        maxWidth: 350,
      },
      '&:hover, &:hover > svg > *': {
        backgroundColor: theme.colors.neutral4,
        stroke: `${theme.colors.neutral10} !important`,
      },
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.neutral10,
    },
    text: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.primary5,
      fontWeight: 600,
      ...theme.spacing.paddingLeft(1),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    'text:selected': {
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.primary5,
    },
    'icon:selected': {
      color: theme.colors.neutral1,
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),

}
