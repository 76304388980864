import { forwardRef, ReactNode } from 'react'
import { AppImages } from '@/app'
import { Image, View } from '@/components'

type ExportWrapperProps = {
  children: ReactNode
  header?: boolean
}

export const ExportWrapper = forwardRef<typeof View, ExportWrapperProps>(({ children, header = false }, ref) => {
  return (
    <View style={{ height: 900, width: 1600 }} variants={['column']} ref={ref}>
      {
        header ? <View style={{ backgroundColor: 'black' }}>
          <Image source={AppImages.LogoWhite} style={{ height: 80, aspectRatio: 2255 / 542 }} />

        </View> : null
      }
      {children}
    </View>
  )
})
