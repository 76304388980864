import { EmailCode } from '@/types'

const codes: EmailCode[] = [
  'reviewer_invitation',
  'revision_decision',
  'rejected_decision',
  'editor_assigment',
  'manuscript_transferred',
  'reviews_complete',
  'review_invite_accepted',
  'completed_review',
  'manuscript_submission',
  'rejected_resubmission_decision',
  'accepted_decision',
]

const segmentedControlOptions = [
  { label: 'Portal preferences', value: 'preferences', icon: 'palette' },
  { label: 'Settings', value: 'settings', icon: 'settings' },
  { label: 'Email templates', value: 'email', icon: 'clipboard-type' },
]

const baseAnchors = {
  general: 'general-anchor',
  issn: 'issn-anchor',
  emailTemplates: 'email-templates-anchor',
}

const journalsAnchors = {
  ...baseAnchors,
  ...codes.reduce((acc, code) => {
    acc[code] = `${code}-anchor`
    return acc
  }, {}),
}

const getInvalidKey = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    // @ts-ignore
    if (value?.length > 0) {
      return key
    }
  }
  return null // Return null if no matches are found
}

export const JournalsUtils = {
  segmentedControlOptions,
  journalsAnchors,
  codes,
  getInvalidKey,
}
