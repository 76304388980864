import { List, Text, Touchable, View } from '..'
import { variantProvider } from '@/app'
import { Badge, EmptyPlaceholder } from '@codeleap/web'
import { modal, Navigation, parseHTMLText, useFlatlistProps } from '@/utils'
import { APIClient } from '@/services'
import { Announcement } from '@/types'
import { onUpdate, useState } from '@codeleap/common'

const _Announcement = ({ item, onPress }: {item: Announcement; onPress: (i: Announcement) => void}) => {
  const [previewDescription, setPreviewDescription] = useState(null)
  const parsedDescription = item?.description && parseHTMLText(item?.description, true)

  onUpdate(() => { // NOTE - Why are we not using css for that
    if (!previewDescription?.length && parsedDescription?.length) {
      let preview = ''
      parsedDescription?.slice(0, 2)?.map((item) => {
        preview = preview + ` ${item?.text}`
      })
      setPreviewDescription(preview)
    }
  }, [parsedDescription, previewDescription])

  return (
    <Touchable
      debugName={'Announcements item'}
      variants={['padding:2', 'border-radius:tiny', 'marginBottom:0.25', 'column', 'fullWidth', !item?.read ? 'bg:neutral2' : '']}
      onPress={onPress}
      style={styles.itemWrapper}
    >

      <View variants={['flex', 'fullWidth', 'justifyStart', 'gap:0.5']}>
        {!item?.read ? <Badge style={styles.badge} badge variants={['position:static', 'marginTop:0.8']} /> : null}
        <Text variants={['p2', 'color:neutral8']} text={item?.title}/>
      </View>

      <View variants={['fullWidth']}>
        <Text
          variants={['p3', 'color:neutral8']}
          text={previewDescription}
          ellipsizeMode='clip'
          style={styles.text}
        />
      </View>

    </Touchable>
  )
}

export const AnnouncementsList = modal({ id: 'announcementsList' }).content((props) => {
  const { toggle } = props

  const { data: profile } = APIClient.Session.useProfile()
  const announcements = APIClient.Announcements.announcementsManager.use({
    listOptions: {
      queryOptions: {
        enabled: !!profile,
      },
    },
  })

  const listProps = useFlatlistProps(announcements)

  const onPressItem = async (item: Announcement) => {
    toggle()
    if (!item.read) {
      await APIClient.Announcements.announcementsManager.actions.markRead(item?.id)
    }
    Navigation.navigate('Announcements.Details', { routeParams: { id: item?.id }, state: { previousPath: window.location.href }})
  }

  return (
    <List
      {...listProps}
      renderItem={({ data }) => <_Announcement item={data as Announcement} onPress={() => onPressItem(data as Announcement)}/>}
      variants={['announcements-list']}
      showFooter={false}
      masonryProps={{
        overscanBy: 8,
      }}
      ListEmptyComponent={() => <EmptyPlaceholder icon='bell' variants={['table']} title='No announcement found.' />}

    />
  )
}).props({
  variants: ['announcementsListModal'],
  showClose: true,
  title: 'Announcements',
})

const NOTIFICATION_BADGE_SIZE = 9

const styles = variantProvider.createComponentStyle((theme) => ({
  itemWrapper: {
    '&:hover': {
      backgroundColor: theme.colors.neutral2,
    },
  },
  text: {
    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    ...theme.spacing.margin('0 auto'),
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  badge: {
    width: NOTIFICATION_BADGE_SIZE,
    height: NOTIFICATION_BADGE_SIZE,
  },
}), true)
