import { React } from '@/app'
import { ActivityIndicator, View } from '@/components'
import { ActivityIndicatorProps, EmptyPlaceholder, EmptyPlaceholderProps } from '@codeleap/web'
import { ReactNode } from 'react'

type SectionFallbackProps = {
  children: ReactNode
  isLoading?: boolean
  isEmpty?: boolean
  emptyPlaceholderProps?: Partial<EmptyPlaceholderProps>
  activityIndicatorProps?: Partial<ActivityIndicatorProps>
}

export const SectionFallback = (props: SectionFallbackProps) => {
  const { isLoading, isEmpty, children, emptyPlaceholderProps, activityIndicatorProps } = props

  if (isLoading) {
    return <View variants={['center']}>
      <ActivityIndicator debugName='SectionFallback' {...activityIndicatorProps} />
    </View>
  } else if (isEmpty) {
    return <View variants={['center']}>
      <EmptyPlaceholder icon={'clipboard-list'} variants={['table']} {...emptyPlaceholderProps} />
    </View>
  } else {
    return children
  }
}
