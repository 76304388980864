import { Button } from '..'

type UploadFooterButtonProps = { title: string; disabled?: boolean; onPress: () => void }
export const UploadFooterButton = ({ title, onPress, disabled = false }: UploadFooterButtonProps) => {
  return (
    <Button
      variants={['large', 'marginTop:3', 'marginHorizontal:auto']}
      text={title}
      debugName={`UploadFilesModal:${title}`}
      id={`UploadFilesModal:${title?.toLowerCase()?.replaceAll(' ', '_')}`}
      onPress={onPress}
      debounce={1500}
      disabled={disabled}
    />
  )
}
