import React from 'react'
import { useI18N } from '@codeleap/common'
import { View, Link, Button } from '@/components'
import { I18NDictionary } from '@/app/i18n'
import { modal } from '@/utils'

const languageOptions = Object.entries(I18NDictionary).map(([key, value]) => ({
  label: value.__LABEL,
  value: key,
}))

type LanguageSelectorProps = {
  path?: string
}

export const LanguageSelectorModal = modal<LanguageSelectorProps>({
  id: 'languageSelectorModal',
  independent: true,
}).content((props) => {
  const { path } = props
  const { locale } = useI18N()

  return (
    <View variants={['gap:1', 'column']} style={{ minWidth: 300 }}>
      {languageOptions.map((item) => {
        const selected = item.value === locale
        return (
          <Button
            component={Link}
            debugName='language selector'
            to={path}
            language={item.value}
            key={item.value}
            type='i18n'
            variants={['link', selected && 'link:outline']}
          >
            {item.label}
          </Button>
        )
      })}
    </View>
  )
}).props({
  title: 'Language',
  description: 'Select a language',
  withScrollContainer: true,
})

export const LanguageSelector = ({ path = '/' }: LanguageSelectorProps) => {
  const { locale, t } = useI18N()

  return <>
    <View variants={['column', 'gap:2']}>
      <Button
        text={t('languageSelector', { language: locale })}
        onPress={LanguageSelectorModal.open}
        debugName='open language selector modal'
        variants={['select']}
        rightIcon='languages'
      />
    </View>

    <LanguageSelectorModal.Component path={path} variants={['centered']}/>
  </>
}
