import { FilterDataItem, SortModalProps } from '@/components'
import { SectionFilters, SectionFiltersProps } from '@/components/SectionFilters'
import { TypeGuards } from '@codeleap/common'
import { useCallback } from 'react'

type UseSortModalProps = SortModalProps & {
  request: {
    resolve: (result: any) => void
    reject: (reason: any) => void
  }
  options: FilterDataItem[]
  initialState?: string[]
  limit?: number

  otherArgs?: Record<string, any>
  onClear?: () => void
  sectionFilterProps?: Omit<SectionFiltersProps<FilterDataItem>, 'items' | 'areItemsEqual' | 'initialSelectedItems' | 'selectionLimit'>
}

export const useSortModal = (props: UseSortModalProps) => {
  const { options, initialState = [], request, limit, otherArgs, sectionFilterProps = {} } = props

  const initialSelectedItems = initialState ? options.filter((item) => initialState?.includes?.(item.value)) : undefined

  const areItemsEqual = useCallback((a: FilterDataItem, b: FilterDataItem) => {
    return a.value === b.value
  }, [])

  const handle = SectionFilters.use<FilterDataItem>({
    items: TypeGuards.isNil(options) ? [] : options,
    areItemsEqual,
    initialSelectedItems,
    selectionLimit: limit,
    ...sectionFilterProps,
  })

  const onClear = () => {
    TypeGuards.isFunction(props?.onClear) ? props.onClear() : request.resolve({ selectedOptions: [] })
  }

  const onSort = () => {
    const items = handle.selectedItems[0]

    request.resolve({ selectedOptions: items, ...otherArgs })
  }

  return {
    handle,
    onClear,
    onSort,
  }
}
