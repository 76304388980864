import { Theme, React } from '@/app'
import { downloadFileFromURL, formatFileName } from '@/utils'
import { fileSizeValidator, validateFileExtension } from '@/app/forms'
import { FileCategory } from '@/types'
import { View, Text, ActionIcon, Button, Icon } from '@/components'

type FileItemProps = {
  initialFile: { id: number | 'main'; file: string; file_category: number }
  updatedFile?: File
  index: number
  disableUpload?: boolean
  fileCategory: FileCategory
  onRemove: () => void
  onUpload: () => void
}

const fileExtByCategoryValidator = (file: File, category: FileCategory) => {
  if (category?.refers_to_main_document || category?.response_to_reviewer_or_editor || category?.clean_version_of_revised_article_with_changes_accepted) {
    const isValidExt = validateFileExtension(file.name)
    return { message: isValidExt ? '' : `File must be a .pdf, .doc or .docx` }
  }
}

export const FileItem = ({ updatedFile, initialFile, index, fileCategory, disableUpload, onRemove, onUpload }: FileItemProps) => {
  const checkFileSize = updatedFile ? fileSizeValidator(updatedFile) : null
  const checkExt = updatedFile ? fileExtByCategoryValidator(updatedFile, fileCategory) : null
  const name = formatFileName(updatedFile ? updatedFile?.name : initialFile?.file)

  const iconColorVariant = updatedFile ? 'positive2' : 'neutral5'
  const itemWrapperColorVariant = updatedFile ? 'bg:neutral2' : 'bg:neutral1'

  return (
    <>
      <View variants={['fullWidth', 'column']}>
        <View variants={['gap:1']} responsiveVariants={{ mid: ['row'] }}>
          <Icon
            name='file'
            variants={['small', 'alignSelfCenter', 'noShrink', iconColorVariant as any]}
            debugName={'Blindfolded Journal Flow modal item - File Icon'}
          />

          <View variants={['justifySpaceBetween', 'blindfoldedModalItemWrapper', itemWrapperColorVariant as any]}>
            <View variants={['column', 'justifyCenter']}>
              <Text variants={[`p1`]} style={styles.fileItemName} text={name} />
              {fileCategory?.title ? <Text variants={[`p3`, 'color:neutral7']} text={fileCategory.title} /> : null}
              {checkFileSize?.message ? <Text variants={[`p3`, 'color:destructive2']} text={checkFileSize.message} /> : null}
              {checkExt?.message ? <Text variants={[`p3`, 'color:destructive2']} text={checkExt.message} /> : null}
            </View>
            {updatedFile ? (
              <ActionIcon
                debugName={`remove blindfolded item ${updatedFile}`}
                name='x'
                variants={['minimal']}
                onPress={onRemove}
              />
            ) : null}
          </View>

          <Button
            variants={[`blindfoldedUploadFileButton`]}
            text={`Upload`}
            icon={'upload'}
            disabled={disableUpload}
            debugName={`open blindfolded file picker`}
            onPress={onUpload}
          />

        </View>

        <Text
          variants={[`p2`, 'marginBottom:2', 'link', 'ellipsis']}
          text={`Download file`}
          onPress={() => downloadFileFromURL({ file: initialFile.file, avoidPopUpBlocker: true })}
          style={styles.link}
        />

      </View>
    </>
  )
}

const styles = {
  link: {
    cursor: 'pointer',
  },
  fileItemName: {
    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'unset',
    minHeight: 23,
    wordBreak: 'break-all',

    [Theme.media.down('small')]: {
      display: 'block', // Fix for not working -webkit-box
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      whiteSpace: 'unset',
    },
  },
}
