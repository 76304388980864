import { React } from '@/app'
import { useCallback, useMemo } from '@codeleap/common'
import { View, Button, usePublicationForm } from '@/components'
import { ButtonProps, ViewProps } from '@codeleap/web'

export type FormComponentProps<T extends object> = {
  isFirst: boolean
  index: number
  deleteForm: (idx: number, item: Partial<T>) => void
  value: BaseFormProps<T>['forms'][number]
  onChangeValue: (field: BaseFormProps<Partial<T>>['forms'][number]) => void
}

export type BaseFormProps<T extends object> = {
  forms: T[]
  setForms: React.Dispatch<React.SetStateAction<T[]>>
  onDelete?: (param: T) => void
  initialState: T
  formComponent: (props: FormComponentProps<T>) => JSX.Element
  max?: number
  addMoreButtonProps?: Omit<ButtonProps, 'debugName'>
  wrapperProps?: ViewProps<'div'>
  showAddMoreButton?: boolean
  showInitialState?: boolean
}

export const BaseForm = <T extends object = object>(props: BaseFormProps<T>) => {

  const canAuthorEdit = usePublicationForm(v => v.canAuthorEdit)

  const {
    forms,
    setForms,
    initialState,
    formComponent: Form,
    max,
    onDelete,
    addMoreButtonProps,
    wrapperProps,
    showAddMoreButton = ((!max || forms.length < max) && canAuthorEdit),
    showInitialState = true,
  } = props

  const addForm = useCallback(() => {
    setForms((prevForms) => [...prevForms, initialState])
  }, [])

  const deleteForm = useCallback((index: number, item) => {
    setForms((prevForms) => {
      const rest = prevForms.filter((_, i) => i !== index)
      if (rest?.length) return rest
      return showInitialState ? [initialState] : []
    })
    onDelete?.(item)
  }, [])

  const handleChange = useCallback((field: Record<string, any>, index: number) => {
    setForms((prevForms) => {
      const updatedForms = [...prevForms]
      updatedForms[index] = { ...updatedForms[index], ...field }
      return updatedForms
    })
  }, [])

  const mappedForms = useMemo(() => {
    return forms?.map((f, key) => (
      <Form
        key={key}
        index={key}
        isFirst={key === 0}
        deleteForm={() => deleteForm(key, f)}
        value={f}
        onChangeValue={(field) => handleChange(field, key)}
      />
    ))
  }, [forms, deleteForm, handleChange])

  return (
    <View variants={['gap:4', 'column']}>
      <View variants={['gap:4', 'column']} >
        {forms?.length ? (
          <View variants={['fullWidth', 'gap:4', 'column']} {...wrapperProps}>
            {mappedForms}
          </View>
        ) : null}
        {showAddMoreButton ? (
          <Button
            debugName='Add more form'
            variants={['flat', 'large', 'marginRight:auto', 'gap:0.5', 'color:primary5']}
            text='Add more'
            icon='user-plus'
            onPress={addForm}
            {...addMoreButtonProps}
          />
        ) : null}
      </View>
    </View>
  )
}
