import { APIClient } from '@/services'
import { Disclaimer, Profile, Publication } from '@/types'
import { ReactQuery } from '@codeleap/common'
import { useMemo, useState } from 'react'

export function useDisclaimers(
  journal: Profile['current_journal'],
  initialState?: Publication['disclaimers_accepted'],
) {
  const [disclaimerForm, setDisclaimerForm] = useState<Record<Disclaimer['id'], boolean>>(() => {
    const entries = initialState?.map((disclaimer) => [disclaimer.disclaimer__id ?? disclaimer?.title, true]) ?? []

    return Object.fromEntries(entries)
  })

  const plagiarismDisclaimer = ReactQuery.useQuery({
    queryKey: ['plagiarismDisclaimer'],
    queryFn: APIClient.Articles.getSubmissionTerms,
    initialData: { url: '' },
    refetchOnWindowFocus: false,
  })

  const journalDisclaimers = ReactQuery.useQuery({
    queryKey: ['disclaimers', journal],
    queryFn: () => APIClient.Journals.listDisclaimer({ journal }),
    initialData: [],
    refetchOnWindowFocus: false,
  })

  const items = useMemo(() => {
    return [
      ...journalDisclaimers?.data,
      {
        id: 'plagiarism-disclaimer',
        title: ' Plagiarism check',
        url: plagiarismDisclaimer?.data?.url,
      },
    ]
  }, [journalDisclaimers.data, plagiarismDisclaimer])

  const allAccepted = useMemo(() => items.every((disclaimer) => !!disclaimerForm[disclaimer.id]), [disclaimerForm])
  const acceptedDisclaimers = useMemo(() => Object.keys(disclaimerForm).filter((key) => disclaimerForm[key]), [disclaimerForm])

  const handleAcceptance = (id: Disclaimer['id'], vl: boolean) => {
    setDisclaimerForm((prev) => ({ ...prev, [id]: vl }))
  }

  return {
    items,
    allAccepted,
    acceptedDisclaimers,
    handleAcceptance,
    isLoading: journalDisclaimers.isLoading || plagiarismDisclaimer.isLoading,
    disclaimerForm,
    setDisclaimerForm,
  }
}
