import React, { createContext, ReactNode, useCallback } from 'react'
import { Button, View } from '@codeleap/web'
import { deepEqual, PropsOf, TypeGuards } from '@codeleap/common'

export type TSectionFilterItem = {
  value?: string | number
  label?: string
}

type SectionComponentProps<T = TSectionFilterItem> = {
  section: Section<T>
  index: number
  selectedItems: T[]
}

type SectionComponent<T = TSectionFilterItem> = (props: SectionComponentProps<T>) => JSX.Element
type Section<T = TSectionFilterItem> = {
  header?: SectionComponent<T> | JSX.Element
  items: T[]
  footer?: SectionComponent<T> | JSX.Element
  selectionLimit?: number
  disableItemsOnLimitReached?: boolean
}

export type SectionFilterItemProps<T = TSectionFilterItem> = {
  item: T

  index: number
  section?: Section<T>

} & PropsOf<typeof Button>

function SectionFilterItem(props: SectionFilterItemProps) {
  const { item, ...buttonProps } = props

  return <Button
    text={item.label}
    {...buttonProps}
  />
}

type SectionFilterSectionProps<T = TSectionFilterItem> = React.PropsWithChildren<{
  section: Section<T>
  index: number
}>

function SectionFilterSection(props: SectionFilterSectionProps) {
  const { section, index, children } = props

  const handle = useSectionFiltersContext()

  const Footer = TypeGuards.isFunction(section.footer) ? section.footer : () => (section.footer as JSX.Element ?? null)
  const Header = TypeGuards.isFunction(section.header) ? section.header : () => (section.header as JSX.Element ?? null)

  const componentProps = {
    selectedItems: handle.selectedItems[index] ?? [],
    section,
    index,
  }

  return <View variants={['column']}>
    <Header {...componentProps} />
    {children}
    <Footer {...componentProps} />
  </View>
}

type SectionFilterListProps<T = TSectionFilterItem> = React.PropsWithChildren<{}>

function SectionFilterList(props: SectionFilterListProps) {
  const { children } = props

  return <View variants={['column']}>
    {children}
  </View>
}

export type SectionFiltersProps<T = TSectionFilterItem> = React.PropsWithChildren<{
  items?: T[]
  sections?: Section<T>[]
  areItemsEqual?: (a: T, b: T) => boolean
  selectionLimit?: number
  sectionSelectionLimit?: number
  disableItemsOnLimitReached?: boolean
  initialSelectedItems?: T[] | SelectedItemsPerSection<T>
}>

type SelectedItemsPerSection<T = TSectionFilterItem> = { [X: number]: T[] }

export function useSectionFilters<T = TSectionFilterItem>(props: SectionFiltersProps<T>) {
  const {
    items,
    sections,
    areItemsEqual = deepEqual,
    selectionLimit = 1,
    sectionSelectionLimit = null,
    disableItemsOnLimitReached = selectionLimit > 1 && !sectionSelectionLimit,
    initialSelectedItems = [],
  } = props

  const [selectedItems, setSelectedItems] = React.useState<SelectedItemsPerSection<T>>(() => {
    if (TypeGuards.isArray(initialSelectedItems)) {
      return {
        0: initialSelectedItems,
      }
    }

    return initialSelectedItems ?? {}
  })

  const findItemSection = (item: T) => {
    if (!sections) {
      return {
        sectionIndex: 0,
        section: null,
      }
    }

    const sectionIndex = sections?.findIndex((section, index) => {
      return section.items.some((i) => areItemsEqual(item, i))
    })

    if (sectionIndex === -1) {
      return {
        sectionIndex: null,
        section: null,
      }
    }

    const section = sections[sectionIndex]

    return {
      sectionIndex,
      section,
    }
  }

  const isSelected = (item: T) => {
    if (sections) {
      const { sectionIndex } = findItemSection(item)

      return selectedItems[sectionIndex]?.some((i) => areItemsEqual(i, item))
    }

    return selectedItems[0]?.some((i) => areItemsEqual(i, item))
  }

  const toggleItem = (item: T) => {
    let sectionIndex = -1
    let limit = selectionLimit

    if (sections) {
      const { sectionIndex: si, section } = findItemSection(item)
      if (si === null) {
        return
      }

      sectionIndex = si
      limit = section.selectionLimit ?? selectionLimit
    } else {
      sectionIndex = 0
    }

    const currentItems = selectedItems[sectionIndex] ?? []

    const isItemSelected = currentItems.some((i) => areItemsEqual(i, item))

    const newItems = [...currentItems]

    if (isItemSelected) {
      const index = newItems.findIndex((i) => areItemsEqual(i, item))

      newItems.splice(index, 1)
    } else {
      if (newItems.length >= limit) {
        newItems.shift()
      }
      newItems.push(item)

    }

    setSelectedItems({
      ...selectedItems,
      [sectionIndex]: newItems,
    })
  }

  function sectionLimitReached(sectionIndex: number) {
    const section = sections[sectionIndex]

    if (!section) {
      return false
    }

    const limit = section.selectionLimit ?? sectionSelectionLimit ?? selectionLimit

    if (!limit) {
      return false
    }

    const nItems = selectedItems[sectionIndex]?.length

    return nItems >= limit
  }

  function limitReached() {
    const nItems = Object.values(selectedItems).flatMap((i) => i).length

    return nItems >= selectionLimit
  }

  return {
    isSelected,
    toggleItem,
    findItemSection,
    selectedItems,
    sectionLimitReached,
    limitReached,
    props: {
      selectionLimit,
      areItemsEqual,
      disableItemsOnLimitReached,
      ...props,
    },
  }

}

export type SectionFiltersContextProps<T = TSectionFilterItem> = React.PropsWithChildren<SectionFiltersProps<T> & {
  handle?: ReturnType<typeof useSectionFilters<T>>
}>

type TSectionFiltersContext<T = TSectionFilterItem> = ReturnType<typeof useSectionFilters<T>>

const SectionsFilterContext = createContext({} as TSectionFiltersContext)

function useSectionFiltersContext<T = TSectionFilterItem>() {
  return React.useContext(SectionsFilterContext) as TSectionFiltersContext<T>
}

export type SectionsComponentProps<T = TSectionFilterItem> = {
  components?: {
    List?: (props: SectionFilterListProps<T>) => JSX.Element
    Section?: (props: SectionFilterSectionProps<T>) => JSX.Element
    Item?: (props: SectionFilterItemProps<T>) => JSX.Element
  }
}

export function SectionFilters<T = TSectionFilterItem>(props: SectionFiltersContextProps<T> & SectionsComponentProps<T>) {
  const { children, components } = props

  const handle = props.handle ?? useSectionFilters(props)

  const _props = handle.props

  const ListComponent = components?.List ?? SectionFilterList
  const SectionComponent = components?.Section ?? SectionFilterSection
  const ItemComponent = components?.Item ?? SectionFilterItem

  const RenderItems = useCallback(({ items, section, sectionIndex }) => {
    const hasSection = !TypeGuards.isNil(sectionIndex) && !TypeGuards.isNil(section)
    const sectionLimitReached = hasSection && handle.sectionLimitReached(sectionIndex)
    const limitReached = handle.limitReached()
    const disableOnReachLimit = _props.disableItemsOnLimitReached

    const disableNonSelectedItems = (limitReached || sectionLimitReached) && disableOnReachLimit

    return <>
      {
        (section?.items || items)?.map((item, index) => {
          const isSelected = handle.isSelected(item)

          return <ItemComponent
            debugName='Item option'
            key={index}
            item={item}
            onPress={() => handle.toggleItem(item)}
            selected={isSelected}
            index={index}
            section={section}
            disabled={disableNonSelectedItems && !isSelected}
          />
        })
      }
    </>
  }, [handle.selectedItems, _props.selectionLimit, _props.disableItemsOnLimitReached])

  const RenderSections = useCallback(() => {
    return <>
      {
        _props.sections.map((section, index) => {

          return <SectionComponent key={index} section={section} index={index} >
            <RenderItems items={section.items} section={section} sectionIndex={index} />
          </SectionComponent>
        })
      }
    </>
  }, [handle.selectedItems, _props.selectionLimit, _props.disableItemsOnLimitReached])

  let content = null

  if (children) {
    content = children
  } else if (_props.sections) {
    content = <ListComponent>
      <RenderSections />
    </ListComponent>
  } else {
    content = <ListComponent>
      <RenderItems items={_props.items} section={null} sectionIndex={null} />
    </ListComponent>
  }

  // @ts-expect-error React element type is not working for some reason
  return <SectionsFilterContext.Provider value={handle}>
    {content}
  </SectionsFilterContext.Provider>
}

SectionFilters.List = SectionFilterList
SectionFilters.Section = SectionFilterSection
SectionFilters.Item = SectionFilterItem
SectionFilters.Context = SectionsFilterContext
SectionFilters.useContext = useSectionFiltersContext
SectionFilters.use = useSectionFilters
