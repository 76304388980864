import { fileSizeValidator } from '@/app/forms'
import { AttachmentsDropzone, Publication, usePublicationForm } from '@/components'
import { APIClient } from '@/services'
import { Attachment, PublicationMedia } from '@/types'
import { useFileCategoryOptions } from '@/utils'
import { useCallback, useImperativeHandle, useMemo, useState } from '@codeleap/common'
import { EmptyPlaceholder } from '@codeleap/web'

export const AttachmentsForm = () => {
  const publication = usePublicationForm(v => v.publication)
  const canAuthorEdit = usePublicationForm(v => v.canAuthorEdit)
  const isPublicationEditable = usePublicationForm(v => v.isPublicationEditable)
  const inDrafts = usePublicationForm(v => v.isSaved_in_drafts)
  const mediaList = usePublicationForm(v => v.mediaList)
  const ref = usePublicationForm(v => v.modulesRefs.attachments)

  const mediaUpdate = APIClient.Publications.mediaManager.useUpdate()
  const mediaCreation = APIClient.Publications.mediaManager.useCreate()

  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [attachmentsMedia, setAttachmentsMedia] = useState<PublicationMedia[]>(() => mediaList?.items || [])
  const [mediaDeleteArray, setMediaDeleteArray] = useState<PublicationMedia['id'][]>([])

  const file_categories = useFileCategoryOptions({ version: publication?.version, status: publication?.status })

  const categoriesWithoutMainDocument = useMemo(() => file_categories?.filter(c => !c.refers_to_main_document), [file_categories])

  const onClear = () => {
    setAttachments([])
    setAttachmentsMedia([])
    setMediaDeleteArray([])
  }

  const onSave = useCallback(async () => {
    if (!!attachments.length) {
      const createdMedias = await mediaCreation.create({ publication: publication.id, files: attachments })
      setAttachments([])
      setAttachmentsMedia((p) => [...p, ...createdMedias])
    }

    if (!!attachmentsMedia?.length) {
      await mediaUpdate.update(attachmentsMedia)
    }

    if (!!mediaDeleteArray?.length) {
      await Promise.all(mediaDeleteArray.map((id) => APIClient.Publications.removeMedia(id)))
      setMediaDeleteArray([])
    }
  }, [attachments, attachmentsMedia, mediaDeleteArray])

  useImperativeHandle(ref, () => ({ onValidate: () => ({ isValid: true }), onSave, onClear }))

  const onRemoveAttachment = (media) => {
    if (media?.id) setMediaDeleteArray((prev) => [...prev, media.id])
  }

  const FilePreviewComponent = useCallback(
    (props) => {
      return (
        <Publication.FilePreview
          {...props}
          file={props?.file}
          setAttachments={setAttachments}
          fileCategories={categoriesWithoutMainDocument}
          disabled={!inDrafts}
          variants={['ellipsis', 'media']}
        />
      )
    },
    [setAttachments, categoriesWithoutMainDocument],
  )

  if (!inDrafts && !attachmentsMedia.length) {
    return <EmptyPlaceholder icon='file' variants={['table']} title='No Attachments found.' />
  }

  return (
    <AttachmentsDropzone
      showTable={!!attachmentsMedia?.length}
      showDropzone={!!canAuthorEdit}
      showDownload={!canAuthorEdit}
      canChangeCategory={canAuthorEdit}
      showFileCategory
      publication={publication?.id}
      currentAttachments={attachments}
      setCurrentAttachments={setAttachments}
      tableAttachments={attachmentsMedia}
      setTableAttachments={setAttachmentsMedia}
      onDelete={onRemoveAttachment}
      disabled={!inDrafts}
      showDelete={inDrafts}
      dropzoneProps={{
        FilePreviewComponent,
        validator: fileSizeValidator,
        disabled: !inDrafts,
      }}
    />
  )
}
