import { useDebouncer } from '@/utils'
import { TextInput, TextInputProps } from '@codeleap/web'
import { forwardRef } from 'react'

export const DebouncedInput = forwardRef((props: TextInputProps, ref) => {
  const { onChangeText, value, ...rest } = props

  const [_value, handleChange] = useDebouncer(value, onChangeText)

  return <TextInput {...rest} onChangeText={handleChange} value={_value} ref={ref}/>
})

