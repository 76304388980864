import { AppForms, variantProvider } from '@/app'
import { useCallback } from '@codeleap/common'
import { View, Text, TextInput, ActionIcon, Link, SelectForm } from '@/components'
import { FormComponentProps } from '../../BaseForm'
import { PublicationAuthor, ScientificTag } from '@/types'
import { useMemoizedForm } from '@/utils'
import { forwardRef, useImperativeHandle } from 'react'
import { FormRef } from '..'

type FieldOptions = 'full_name' | 'organisation' | 'email' | 'contribution' | 'tags' | 'region' | 'order' | 'address'

type AuthorBaseFormProps = FormComponentProps<Partial<PublicationAuthor>> & {
  scientificTags: ScientificTag[]
  disabled?: boolean
  regions: any
}

export const AuthorBaseForm = forwardRef<FormRef, AuthorBaseFormProps>((props, ref) => {
  const { isFirst, value, deleteForm, scientificTags, disabled, regions, index } = props
  const form = useMemoizedForm(AppForms.authorForm, { validateOn: 'change', initialState: value })
  const showDeleteIcon = !(isFirst || disabled)

  const handleForm = useCallback((field: FieldOptions) => {
    const fieldFormError = form.fieldErrors[field]
    const fieldValidation = form.validateField(field)

    return {
      ...form.register(field),
      id: `author_${field}_input_${index}`,
      _error: fieldFormError && !fieldValidation.valid ? fieldValidation.message ?? fieldFormError : '',
      disabled,
    }
  }, [form.values, form.fieldErrors])

  useImperativeHandle(ref, () => ({
    getFormValue: () => form.values,
    validateForm: () => form.validateAll(true),
  }), [form])

  return (
    <View style={!isFirst ? styles.newForms : {}} id={`authorForm_${index}`} variants={['column', 'gap:3']}>
      <View variants={['row', 'alignCenter', 'justifySpaceBetween']}>
        <Text text={isFirst ? 'Main Author' : 'Author'} variants={['h5', 'color:neutral8']} />
        {showDeleteIcon && (
          <ActionIcon
            icon='trash'
            variants={['destructive:minimal', 'normalize']}
            debugName='Remove author form'
            onPress={() => deleteForm(index, value)}
            debounce={1000}
          />
        )}
      </View>
      <View style={styles.grid}>
        <TextInput {...handleForm('full_name')} disabled={isFirst || disabled} />
        <TextInput {...handleForm('order')} />
        <TextInput {...handleForm('organisation')} />
        <TextInput {...handleForm('email')} />
        <SelectForm {...handleForm('region')} options={regions} />
        <TextInput {...handleForm('address')} />
        <SelectForm
          {...handleForm('tags')}
          options={scientificTags}
          leftIcon={{ name: 'flask-conical' }}
          styles={{ leftIconTouchableWrapper: styles.leftIconTouchableWrapper }}
        />
      </View>
      <TextInput
        {...handleForm('contribution')}
        variants={['fullWidth']}
        style={{ whiteSpace: 'pre-line' }}
        description={
          <Text variants={['p1', 'color:neutral7', 'marginBottom:1', 'regular']}>
              CRediT (Contributor Roles Taxonomy) is a high-level taxonomy, including 14 roles, that can be used to
              represent the roles typically played by contributors to research outputs. Choose one or more from this
              list:
            <br />
            <br />
              Conceptualization, Data curation, Formal Analysis, Funding acquisition, Investigation, Methodology,
              Project administration, Resources, Software, Supervision, Validation, Visualization, Writing – original
              draft, Writing – review & editing.
            <br />
            <br />
              Please visit{' '}
            <Link
              variants={['p1', 'neutral7', 'thin']}
              openNewTab
              to='https://credit.niso.org/'
              text='https://credit.niso.org/'
            />{' '}
              for more information.
          </Text>
        }
      />
    </View>
  )
})

const styles = variantProvider.createComponentStyle(
  theme => ({
    grid: {
      display: 'grid',
      // prevent grid from overflowing horizontally when large texts are typed on the text input
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      columnGap: theme.spacing.value(5),
      rowGap: theme.spacing.value(3),
      [theme.media.down('small')]: {
        // prevent grid from overflowing horizontally when large texts are typed on the text input
        gridTemplateColumns: 'minmax(0, 1fr)',
      },
    },
    newForms: {
      ...theme.spacing.padding(3),
      ...theme.border.neutral5({ width: 1 }),
      // @ts-ignore
      borderRadius: theme.borderRadius.large,
    },
    leftIconTouchableWrapper: {
      ...theme.spacing.marginRight(1),
    },
  }),
  true,
)
