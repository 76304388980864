import { SectionsComponentProps } from '@/components/SectionFilters'
import { FilterDataItem } from '../Sort'
import { Button } from '@/components'

export const Item: SectionsComponentProps<FilterDataItem>['components']['Item'] = (props) => {
  const { item, ...buttonProps } = props

  return <Button
    debugName={`SortModal debug name ${item.label}`}
    text={item.label}
    // @ts-ignore
    {...(item.buttonProps ?? {})}
    {...buttonProps}
    variants={['sortList']}
    rightIcon='check'
  />
}
