import React from 'react'
import { variantProvider } from '@/app'
import { modal, PublicationUtils, scrollToAnchor } from '@/utils'
import { Button, Text, View } from '@codeleap/web'
import { useJournalCrudContext } from '../Journals'
import { t } from 'i18next'

const defaultTitle = 'Something went wrong'
const defaultMessage = 'Please fill in all the necessary fields before proceeding.'

type SaveJournalModalProps = {
  errorTitle?: string
  errorMessage?: string
  anchor?: string
  tab?: string
}

export const SaveJournalAlertModal = modal<SaveJournalModalProps>({ id: 'saveJournalAlertModal', independent: true }).content((props) => {
  const { toggle, errorTitle = defaultTitle, errorMessage = defaultMessage, anchor, tab } = props

  const setTab = useJournalCrudContext((v) => v.setTab)
  const handleFocusErrors = useJournalCrudContext((v) => v.handleFocusErrors)

  function handleConfirm() {
    setTab(tab)
    toggle()

    setTimeout(() => {
      handleFocusErrors()
      scrollToAnchor(anchor)

    }, 500)
  }

  return (
    <>
      <Text text={errorTitle} variants={['justifyCenter', 'textCenter', 'h3']} />
      <Text text={errorMessage} variants={['justifyCenter', 'textCenter', 'p1', 'marginTop:1']} styles={{ text: { whiteSpace: 'pre-line' } }} />
      <View style={styles.container}>
        <Button
          text={'OK'}
          variants={['fullWidth', 'marginTop:2']}
          debugName={'OK button from alert modal'}
          onPress={handleConfirm}
        />
      </View>
    </>
  )
})

const styles = variantProvider.createComponentStyle((theme) => ({
  container: {
    ...theme.spacing.marginTop(1),
    ...theme.presets.justifyCenter,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
}), true)

SaveJournalAlertModal.props({
  variants: ['centered'],
  showClose: false,
  closable: false,
  closeOnEscape: false,
  styles: {
    box: {
      width: 500,
    },
  },
})
