import React, { useState } from 'react'
import { memoize, yup } from '@codeleap/common'
import { ActionIcon, Text, TextEditor, View, Button, TextInput, useJournalCrudContext } from '@/components'
import { JournalQuestionChoice } from '@/types'

const AnswerInput = ({ initialValue, onDelete, choice, onEdit, canDelete }) => {
  const [value, setValue] = useState(initialValue)

  return (
    <View variants={['row']}>
      <TextInput
        placeholder='Enter here...'
        debugName='answer input'
        value={value}
        variants={[canDelete ? 'noError' : '']}
        onChangeText={(v) => {
          setValue(v)
          onEdit(choice, v)
        }}
        validate={!canDelete ? yup.string().required() : false}
      />
      {canDelete ? (
        <ActionIcon
          debugName='answer input delete'
          icon='trash'
          onPress={() => onDelete(choice)}
          variants={['backgroundColor:neutral1', 'destructive2', 'iconSize:2']}
        />
      ) : null}
    </View>
  )
}

const JournalRecommendationQuestionFormCP = () => {
  const {
    recommendationQuestion: question,
    setRecommendationQuestion: setQuestion,
  } = useJournalCrudContext()

  const [choices, setChoices] = useState(() => {
    return question?.choices?.length
      ? question.choices.map((i, idx) => ({ ...i, id: String(idx) }))
      : []
  })

  const updateChoices = (data: JournalQuestionChoice[]) => {
    const choice = {
      ...question,
      choices: data,
    }
    setQuestion(choice)
    setChoices(data)
  }

  const onDeleteAnswer = (choice: JournalQuestionChoice) => {
    const copy = choices?.filter((c) => c?.id !== choice?.id)
    updateChoices(copy)
  }

  const onAddAnswer = () => {
    const newId = Date.now()
    updateChoices([
      ...choices,
      { id: String(newId), label: '', value: '' },
    ])
  }

  const onEditAnswer = (choice: JournalQuestionChoice, value: string) => {
    const updatedChoices = choices.map((c) => c.id === choice.id ? { ...c, label: value, value: value } : c,
    )
    updateChoices(updatedChoices)
  }

  return <View variants={['column', 'fullWidth', 'gap:2', 'marginTop:2']}>
    <View variants={['fullWidth', 'column', 'gap:3', 'padding:2', 'border', 'border-radius:small']}>
      <Text text='Recommendation' variants={['h5', 'color:neutral8']} />

      <TextEditor
        // @ts-ignore
        toolbarComponentProps={{
          excludeIds: ['highlight', 'textStyles', 'link', 'image', 'fileComponent'],
        }}
        value={question?.question}
        placeholder='Write the question about recommendations here'
        onChangeValue={({ editor }) => {
          setQuestion({
            ...question,
            question: editor.getHTML(),
          })
        }}
      />

      <View variants={['column', 'gap:2']} style={{ maxWidth: '50%' }}>
        <Text text='Answers' variants={['h5', 'color:neutral8']} />
        {choices?.map(c => (
          <AnswerInput
            key={c?.id}
            onEdit={onEditAnswer}
            choice={c}
            onDelete={onDeleteAnswer}
            initialValue={c?.value}
            canDelete={choices?.length > 1}
          />
        ))}
      </View>

      <View variants={['justifyStart', 'fullWidth']}>
        <Button
          variants={[`flat`]}
          text={`Add answer`}
          debugName={'Journals question'}
          icon={'plus'}
          onPress={onAddAnswer}
          debounce={500}
        />
      </View>
    </View>
  </View>
}

export const JournalRecommendationQuestionForm = memoize(JournalRecommendationQuestionFormCP)
