import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { modal } from '@/utils'
import { Button, cropImage, LoadingOverlay, readImage, Text, View } from '@codeleap/web'
import ReactCrop, { Crop, ReactCropProps } from 'react-image-crop'
import { ReactQuery, WebInputFile } from '@codeleap/common'
import { variantProvider } from '@/app'

type CropperRef = {
  getCrop: () => Crop
}

type CropperSettings = Partial<ReactCropProps> & {
  description?: string
}

type CropperProps = {
  loading?: boolean
  initialCrop?: Crop
  settings?: CropperSettings
  image?: HTMLImageElement
}

type CropPickerModalProps = {
  imageData: HTMLImageElement
  settings: CropperSettings
}

const Cropper = forwardRef<CropperRef, CropperProps>((props, ref) => {
  const {
    loading = false,
    initialCrop,
    settings = {
      minWidth: 100,
      minHeight: 100,
    },
    image,
  } = props

  const [crop, setCrop] = useState<Crop>(initialCrop)
  const [relativeCrop, setRelativeCrop] = useState<Crop>()

  const handleCropChange = useCallback(
    (newCrop: Crop) => {
      setCrop({
        ...newCrop,
        width: newCrop.width < settings.minWidth ? settings.minWidth : newCrop.width,
        height: newCrop.height < settings.minHeight ? settings.minHeight : newCrop.height,
      })
    },
    [crop],
  )

  useImperativeHandle(ref, () => {
    return {
      getCrop: () => relativeCrop || crop,
    }
  })

  return (
    <>
      {!!image?.src && (
        <ReactCrop
          {...settings}
          crop={crop}
          onChange={handleCropChange}
          onComplete={(_, relCrop) => setRelativeCrop(relCrop)}
          style={styles.previewSize}
        >
          <img
            src={image?.src}
            css={[styles.cropPreview, styles.previewSize]}
          />
        </ReactCrop>
      )}
      {
        loading ? (
          <LoadingOverlay
            debugName='CropPicker'
            visible
          />
        ) : null
      }

    </>
  )
})

export async function openCropPicker(file: File, settings?: CropperSettings) {
  const imageData = await readImage(file)

  const croppedImage = await CropPickerModal.request({
    imageData: imageData,
    settings,
  })

  return croppedImage
}

function getInitialCrop(imageData: HTMLImageElement, settings: CropperSettings) {
  const { naturalWidth, naturalHeight } = imageData

  const { aspect = 1 } = settings
  const imageAspect = naturalWidth / naturalHeight

  const v =
    imageAspect >= aspect
      ? {
        width: ((naturalHeight * aspect) / naturalWidth) * 100,
        height: 100,
      }
      : {
        width: 100,
        height: (naturalWidth / aspect / naturalHeight) * 100,
      }
  const initialCrop: Crop = {
    ...v,
    x: (100 - v.width) / 2,
    y: (100 - v.height) / 2,
    unit: '%',
  }

  return initialCrop
}

export const CropPickerModal = modal<CropPickerModalProps, WebInputFile>().content((props) => {

  const { imageData, settings } = props

  const initialCrop = useMemo(() => getInitialCrop(props?.imageData, settings), [])

  const cropFile = ReactQuery.useMutation({
    mutationFn: async (crop: Crop) => {
      const [preview, croppedFile] = await cropImage(props?.imageData, crop, 'jpeg')

      const result = {
        file: new File([croppedFile], 'cropped.jpg'),
        preview,
      }

      return result
    },
  })

  const cropperRef = React.useRef<CropperRef>(null)

  const onConfirmCrop = async () => {
    const crop = cropperRef.current?.getCrop()
    const result = await cropFile.mutateAsync(crop)

    props?.request?.resolve(result)

  }

  return (
    <View variants={['column', 'justifyCenter', 'alignCenter']}>
      <Text
        text={settings?.description}
        variants={['marginTop:0.25', 'marginBottom:1.5', 'alignSelfStart']}
      />
      <Cropper
        image={imageData}
        initialCrop={initialCrop}
        loading={cropFile.isLoading}
        ref={cropperRef}
      />
      <Button
        text={'Confirm Crop'}
        debugName={'CropPickerModal:confirm'}
        onPress={onConfirmCrop}
        loading={cropFile.isLoading}
        variants={['marginTop:2', 'fullWidth']}
      />
    </View>
  )
})

const styles = variantProvider.createComponentStyle((theme) => ({
  previewSize: {
    maxHeight: '70vh',
    width: 'fit-content',
    objectFit: 'contain',
    [theme.media.down('medium')]: {
      maxHeight: 'unset',
      maxWidth: '80vh',
    },
  },
  cropPreview: {
    borderRadius: theme.borderRadius?.medium,
  },
}), true)

CropPickerModal.props({
  title: 'Crop Image',
  styles: {
    header: {
      marginBottom: 0,
    },
  },
})
