import { Button, ReviewsOverview, SubmitNewPublicationVersion, View } from '@/components'
import { Publication } from '@/types'

export const NewPublicationVersion = ({ publication }: { publication: Publication }) => {

  function onUploadNewVersion() {
    SubmitNewPublicationVersion.open()
  }

  return (
    <View variants={['center']}>
      <Button
        text={`Send new version to editor`}
        debugName={`Send new publication version to editor button press!`}
        icon={'send'}
        disabled={publication?.status !== 'revision_requested'}
        variants={['sendNewPublicationVersionButton']}
        onPress={() => {
          ReviewsOverview.close()
          onUploadNewVersion()
        }}
      />
    </View>
  )
}
