import { Select, useJournalCrudContext } from '@/components'

export const PublisherSelect = () => {
  const {
    form,
    publishers,
    isCreate,
  } = useJournalCrudContext()

  if (!publishers?.hasPublishers || (!isCreate && !form.values.publisher)) {
    return null
  }

  return (
    <Select
      {...form.register('publisher')}
      loadInitialValue
      options={publishers?.options}
      variants={['fullWidth']}
    />
  )
}
