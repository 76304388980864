import { APIClient } from '@/services'
import { TypeGuards } from '@codeleap/common'

export function useLoadPermissions(id) {
  const { data: member, query } = APIClient.Members.membersManagers.useRetrieve({
    id,
    queryOptions: { refetchOnWindowFocus: false },
  })

  return {
    member,
    query,
    isLoaded: !TypeGuards.isNil(member),
  }
}
