import { Button } from '..'
import { Toaster } from '.'

type BlindfoldedToasterProps = {
    onPress?: () => void
}

const RightContent = ({ onPress }: { onPress: () => void }) => {

  return (
    <Button
      variants={[`bg:info2`, 'blindfoldedToaster', 'noWrap', 'large']}
      responsiveVariants={{ 'largeish': ['fullWidth'] }}
      text={`Blinded review files`}
      debugName={`Open blindfolded journal flow`}
      onPress={onPress}
    />
  )
}

export const BlindfoldedToaster = (props: BlindfoldedToasterProps) => {

  const {
    onPress,
  } = props

  return (
    <Toaster.Component
      icon='eye-off'
      variants={['bg:info1', 'blindfolded']}
      title={`Blinded review process.`}
      description={`This journal requires an edited version of the manuscript excluding author's names to progress with the review process.`}
      isVisible={true}
      RightContent={() => onPress ? <RightContent onPress={onPress} /> : null}
    />
  )
}
