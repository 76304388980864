import { modal, useSorter, useSortModal } from '@/utils'
import React, { useMemo } from 'react'
import { Content, Item } from './components'
import { FilterDataItem, SortModalProps } from './Sort'
import { TypeGuards } from '@codeleap/common'
import { APIClient } from '@/services'

export type JournalsModalProps = SortModalProps & React.PropsWithChildren<{}>

const useJournal = () => {
  const listQuery = APIClient.Journals.journalsManager.useList({
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  })

  const journals = useMemo(() => listQuery?.items?.map((journal) => {
    return {
      label: journal?.title,
      value: journal?.id,
    }
  }), [listQuery?.items?.length])

  return journals
}

export const JournalsModal = modal<JournalsModalProps, FilterDataItem[]>().content((props) => {
  const journals = useJournal()

  const _journals = TypeGuards.isNil(journals) ? [] : journals
  const sortParams = useSortModal({ options: _journals, initialState: props.initialState, request: props.request, limit: props.limit })
  const hasSelectedItems = sortParams?.handle?.selectedItems[0]?.length > 0

  return <>
    <Content
      title='Region region test'
      RenderItem={Item}
      confirmDisabled={!hasSelectedItems}
      {...sortParams}
      {...props}
    />
  </>
})

export const useJournalSorter = () => {
  const journals = useJournal()

  const sorter = useSorter({
    key: 'DASHBOARD_SORT_JOURNAL',
    options: journals,
    modal: JournalsModal,
    limit: 1,
    hasScroll: true,
    enableLocalStorage: false,
  })

  return {
    sorter,
  }
}

JournalsModal.props({
  variants: ['sectionFiltersModal', 'centered'],
  title: 'Choose a journal',
})

