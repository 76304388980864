import { variantProvider, React, Settings } from '@/app'
import { modal, scrollToAnchor } from '@/utils'
import { Button, Text, View } from '@/components'
import { usePublicationForm } from '../Publication'

const defaultTitle = 'Something went wrong'
const defaultMessage = `Please complete all required fields before proceeding.\n\nIf the issue persists, save your draft, refresh the page, and try again. Contact ${Settings.ContactINFO.SupportEMAIL} if the problem continues.`

type SubmitManuscriptAlertModalProps = {
  errorTitle?: string
  errorMessage?: string
  anchor?: string
}

export const SubmitManuscriptAlertModal = modal<SubmitManuscriptAlertModalProps>({ id: 'submitManuscriptAlertModal', independent: true }).content((props) => {
  const { toggle, errorTitle = defaultTitle, errorMessage = defaultMessage, anchor } = props

  const modulesRefs = usePublicationForm(v => v.modulesRefs)

  function handleConfirm() {
    scrollToAnchor(anchor)

    Object.values(modulesRefs).forEach(ref => ref?.current?.showError?.())

    SubmitManuscriptAlertModal.setParams({ errorMessage: defaultMessage })
    toggle()
  }

  return (
    <>
      <Text text={errorTitle} variants={['justifyCenter', 'textCenter', 'h3']} />
      <Text text={errorMessage} variants={['justifyCenter', 'textCenter', 'p1', 'marginTop:1']} styles={{ text: { whiteSpace: 'pre-line' }}}/>
      <View style={styles.container}>
        <Button
          text={'OK'}
          variants={['fullWidth', 'marginTop:2']}
          debugName={'OK button from alert modal'}
          onPress={handleConfirm}
        />
      </View>
    </>
  )
})

const styles = variantProvider.createComponentStyle((theme) => ({
  container: {
    ...theme.spacing.marginTop(1),
    ...theme.presets.justifyCenter,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
}), true)

SubmitManuscriptAlertModal.props({
  variants: ['centered'],
  showClose: false,
  closable: false,
  closeOnEscape: false,
  styles: {
    box: {
      width: 500,
    },
  },
})
