import { DashboardSections, Text, useDashboard, View } from '..'
import { APIClient } from '@/services'
import { useExportDashboard } from '@/utils'

export const Header = () => {
  const { isDetailsLoading, isRefetching, filter, refs } = useDashboard()
  const { chartRef, coverRef, tableRef } = refs
  const { onExport } = useExportDashboard({
    chartRef,
    coverRef,
    filter,
    tableRef,
  })

  const { profile } = APIClient.Session.useSession()

  return (
    <View variants={['fullWidth', 'row', 'justifySpaceBetween', 'alignCenter']}>
      <Text
        text={'Dashboard'}
        variants={['h1', 'color:neutral10']}
      />

      <DashboardSections.Export onExport={(ext) => onExport(ext)} isLoading={isDetailsLoading || isRefetching} profile={profile} />
    </View>
  )
}
