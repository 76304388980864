import { React, I18N, variantProvider, getOsAlert } from '@/app'
import { Text, View, Button, TextInput } from '@/components'
import { APIClient } from '@/services'
import { modal, ReauthenticationStore } from '@/utils'
import { useI18N, useState } from '@codeleap/common'

export const ReauthenticationModal = modal().content((props) => {
  const { request } = props

  const [password, setPassword] = useState('')

  const { reauthenticate } = APIClient.Session.useSession()
  const { t } = useI18N()

  const handleContinue = async () => {
    try {
      await reauthenticate(password)
      request.resolve(password)
      ReauthenticationStore.set(true)
    } catch (e) {
      logger.error(e)
      request.reject(e)
      getOsAlert('reauthenticationError')
    }
  }

  const handleCancel = () => {
    request.reject(new Error('User cancelled reauthentication'))

  }

  return (
    <>
      <Text
        variants={['p1', 'textCenter']}
        text={t('Reauthetication.body')}
      />

      <TextInput
        value={password}
        onChangeText={setPassword}
        debugName={'Profile edit password input'}
        leftIcon={{ icon: 'key', debugName: 're-auth password icon' }}
        password
        visibilityToggle
        placeholder={t('Reauthetication.inputPlaceholder')}
        variants={['noError']}
      />

      <View variants={['row', 'gap:2', 'marginTop:0.5']}>
        <Button
          text={t('Reauthetication.buttonCancel')}
          debugName={'Cancel reauthentication'}
          onPress={handleCancel}
          variants={['flex', 'flat']}
        />
        <Button
          text={t('Reauthetication.buttonSubmit')}
          debugName={'Reauthenticate user to update profile'}
          onPress={handleContinue}
          variants={['flex']}
          disabled={!password.trim()}
        />
      </View>
    </>

  )
})

ReauthenticationModal.props({
  title: I18N.t('Reauthetication.title'),
  closable: false,
  closeOnEscape: false,
  showClose: false,
  variants: ['centered', 'boxPadding:4'],
  styles: variantProvider.createComponentStyle((theme) => ({
    body: {
      ...theme.spacing.gap(2),
    },
  }), true),
})
