import { Settings, api } from '@/app'
import { getFirebase } from '../../firebaseApp'
import { Profile } from '@/types'
import { queryClient } from '../queryClient'
import { QueryKeys } from '..'

type LinkedInAccessTokenResponse = {
  access_token: string
}

export const ERRORS = {
  emailInUse: new Error('auth/email-already-in-use'),
  notRegistered: new Error('auth/not-registered'),
  reautenticationRequired: new Error('auth/requires-recent-login'),
}

const BASE_URL = 'profiles/'

const serializeFullName = (first_name: Profile['first_name'], last_name: Profile['last_name']) => {
  return `${first_name} ${last_name}`.trim()
}

export async function create(data: Partial<Profile>) {
  const { avatar, ...profileData } = data

  const response = await api.post<Profile>(
    `${BASE_URL}create/`,
    {
      data: profileData,
      files: avatar,
    },
    { multipart: true, debug: true },
  )

  return response.data
}

export async function isEmailAvailable(email: string) {
  const res = await api.get(`${BASE_URL}check_email/`, {
    params: {
      email,
    },
    validateStatus: () => true,
  })

  return res.status.toString().startsWith('2')
}

export async function update(profile: Partial<Profile>) {
  const { avatar, ...data } = profile

  const response = await api.patch<Profile>(
    `${BASE_URL}${profile.id}/`,
    {
      data,
      files: !!avatar ? avatar?.file : null,
    },
    {
      multipart: true,
      debug: true,
    },
  )

  return response.data
}

export async function retrieve() {
  try {
    const response = await api.get<Profile>(`${BASE_URL}i/`)
    // console.log('RESPONSE', response)
    const { first_name, last_name } = response.data

    const full_name = serializeFullName(first_name, last_name)

    return {
      ...response.data,
      full_name,
    }
  } catch (err) {
    // console.log('PPPPPPPPPPPPPPPPPPPP', err)
    QueryKeys.me.setData(null)
    queryClient.client.invalidateQueries(QueryKeys.me.key)
  }
}

export async function requestPasswordReset(email: string) {
  try {
    const firebase = await getFirebase()
    await firebase.auth().sendPasswordResetEmail(email?.trim())
  } catch (e) {
    logger.error(e)
  }
}

export async function deleteProfile(id: Profile['id']) {
  const response = await api.delete(`${BASE_URL}${id}/`)
  return response.data
}

export async function registerDevice(token: string) {
  const data = {
    registration_id: token,
    cloud_message_type: 'FCM',
    active: !!token,
  }

  logger.info('Register device', { data }, 'notifications')
  await api.post('notifications/device/', data)
}

const LinkedInCredentials = Settings.ApiCredentials.LinkedIn

type LinkedinAuthResponse = {
  profile: {
    id: string
    localizedFirstName: string
    localizedLastName: string
    email: string
  }
  token: string
}

export async function linkedInAuth(accessToken: string) {
  const response = await api.get<LinkedinAuthResponse>(`${BASE_URL}linkedin/`, {
    params: {
      access_token: accessToken,
    },
    noAuth: true,
  })

  return response.data
}

let previousCode = null

export async function getLinkedInAccessToken(code: string) {
  if (previousCode === code) return

  const data = {
    grant_type: 'authorization_code',
    code: code,
    client_id: LinkedInCredentials.ClientID,
    client_secret: LinkedInCredentials.ClientSecret,
    redirect_uri: LinkedInCredentials.RedirectUri,
  }

  previousCode = code

  try {
    const response = await api.post<LinkedInAccessTokenResponse>(`${BASE_URL}linkedin/access_token/`, data)

    return response.data
  } catch (e) {
    logger.error(e)
    return null
  }
}
