import { React } from '@/app'
import { Button, View, Text, TextEditor, TextEditorExtensions, ActionIcon } from '@/components'
import Placeholder from '@tiptap/extension-placeholder'
import { modal } from '@/utils'
import { useEditor } from '@tiptap/react'

type ReviewCommentProps = {
  comment: string
}

export const ReviewComment = modal<ReviewCommentProps>({ id: 'reviewComment' }).content((props) => {

  const { request, comment } = props

  const editor = useEditor({
    content: comment,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
  })

  const handleClose = () => {
    request.resolve(comment)
  }

  const handleSave = () => {
    request.resolve(editor.getHTML())
  }

  return (
    <>
      <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween', 'marginBottom:3']}>
        <Text variants={[`h3`]} text={'Comment'} />
        <ActionIcon
          icon='x'
          debugName='Submit manuscript Version Modal - close modal icon on press'
          variants={['filterIcon', 'primary5', 'iconSize:3']}
          onPress={handleClose}
        />
      </View>

      <View variants={['gap:3', 'column', 'fullWidth']}>
        <TextEditor
          toolbarComponentProps={{
            editor,
            excludeIds: ['image', 'link', 'fileComponent'],
          }}
          textEditorProps={{
            editor,
            variants: ['reviewCommentModal', 'multiline'],
            styles: { editor: { minHeight: 280 }},
          }}

        />

        <View variants={['fullWidth', 'row', 'justifyCenter', 'gap:2']}>
          <Button
            variants={['large']}
            icon='save'
            text='Save'
            debugName='Save editor comment'
            onPress={handleSave}
            disabled={!editor?.getText?.().length}
          />
          <Button
            variants={['large', 'flat']}
            text='Cancel'
            debugName='Cancel editor comment'
            onPress={handleClose}
          />
        </View>
      </View>
    </>
  )
}).props({
  showClose: false,
  variants: ['centered', 'reviewComment'],
})
