import { FileScanResponse, Publisher, ScanId } from '@/types'
import {
  AnyFunction,
  ReactQuery,
  UsePaginationTypes,
  useRef,
  useState,
  useUnmount,
} from '@codeleap/common'
import { api } from '@/app'
import { FileUtils } from '@/utils'

const BASE_URL = 'article/'

export async function scanFiles(files: File[], signal?: AbortSignal) {
  const response = await api.post<{ scan_id: string }>(`${BASE_URL}scan_files/`, files, {
    multipart: true,
    signal,
  })
  return response.data
}

export async function scanFilesByChunk(files: File[], signal?: AbortSignal) {

  const sendChunk = async (chunk: File[]) => {
    const response = await scanFiles(chunk, signal)

    return response.scan_id
  }

  const fileChunks = FileUtils.splitFilesIntoChunks(files)

  const scanIds = await Promise.all(fileChunks.map(sendChunk))

  return scanIds
}

export async function checkScanStatus(scanIds: string[]) {

  try {
    const checkStatus = async (id:ScanId) => {
      const response = await api.get<FileScanResponse>(`${BASE_URL}scan_status/`, { params: { scan_id: id }})
      return response.data
    }

    const responses = await Promise.all(scanIds.map(checkStatus))

    return responses
  } catch (error) { }
}

export type UseCheckScanStatusProps = {
  scanIds?: ScanId[]
  enable?: boolean
  checkInterval?: number
  onComplete?: AnyFunction
}

export const useCheckFilesScan = (params: UseCheckScanStatusProps) => {
  const { scanIds: initialScanIds, enable = true, checkInterval = 5000, onComplete } = params

  const [completed, setCompleted] = useState(false)

  const [scanIds, setScanIds] = useState<ScanId[]>(initialScanIds)

  const enablePolling = !!scanIds?.length && enable

  const query = ReactQuery.useQuery({
    queryKey: ['checkFilesScan', scanIds],
    enabled: enablePolling && !completed,
    queryFn: () => checkScanStatus(scanIds),
    refetchInterval: checkInterval,
    onSuccess: (data) => {
      const completed = !data.find(file => file?.status !== 'completed')
      if (completed) {
        setCompleted(true)
        onComplete?.(data.flatMap(({ results }) => results))
      }
    },
  })

  const abortControllerRef = useRef<AbortController>()

  const newScan = ReactQuery.useMutation({
    mutationFn: (files: File[]) => scanFilesByChunk(files, abortControllerRef?.current?.signal),
    onSuccess: (data) => {
      setScanIds(data)
    },
  })

  const cancelScan = () => {
    newScan.reset()
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
  }

  useUnmount(() => {
    cancelScan()
  })

  const startScan = (files: File[]) => {
    return newScan.mutateAsync(files)
  }

  const check = query.data ?? []

  const isCompleted = check.length && check.every(c => c?.status === 'completed')
  const isError = check.length && check.some(c => c?.status === 'error')
  const isPending = check.length && check.some(c => c?.status === 'pending')

  let status:FileScanResponse['status'] = 'pending'

  if (isCompleted) {
    status = 'completed'
  } else if (isError) {
    status = 'error'
  }

  const allResults = check.flatMap(({ results }) => results ?? [])

  const hasInfectedFiles = allResults.some(result => result.result === 'infected')

  return {
    hasInfectedFiles,
    status,
    results: allResults,
    isCompleted,
    isError,
    isPending,
    startScan,
    isLoading: query.isLoading || newScan.isLoading || isPending,
  }
}

