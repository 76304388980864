import { PossibleReviewer, Publication } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { api } from '@/app'

const BASE_URL = 'article/'

async function listPossibleReviewers(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<PossibleReviewer>>(`${BASE_URL}possible_reviewer/`, { params })

  return response.data
}

async function retrievePossibleReviewer(id: PossibleReviewer['id']) {
  const response = await api.get<PossibleReviewer>(`${BASE_URL}possible_reviewer/${id}`)

  return response.data
}

export async function createPossibleReviewers(publicationId: Publication['id'], possibleReviewers: Partial<PossibleReviewer>[]) {
  const response = await api.post<PossibleReviewer[]>(
    `${BASE_URL}possible_reviewer/`,
    { article: publicationId, possible_reviewers: possibleReviewers },
  )

  return response.data
}

async function removePossibleReviewer(possibleReviewer: PossibleReviewer) {
  await api.delete(`${BASE_URL}possible_reviewer/${possibleReviewer.id}/`)

  return possibleReviewer
}

export const possibleReviewersManager = new QueryManager<PossibleReviewer>({
  itemType: {} as PossibleReviewer,
  name: 'possibleReviewers',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: {article: Publication['id']}) => {
    const response = await listPossibleReviewers({ limit, offset, ...filter })
    return response
  },
  /*@ts-ignore*/
  createItem: async (data: {publicationId: number; possibleReviewers: PossibleReviewer[]}) => {
    const response = await createPossibleReviewers(data.publicationId, data.possibleReviewers)
    return response
  },

  retrieveItem: async (id: PossibleReviewer['id']) => {
    const response = await retrievePossibleReviewer(id)
    return response || null
  },

  deleteItem: async (data: PossibleReviewer) => {
    const response = await removePossibleReviewer(data)
    return response
  },
})
