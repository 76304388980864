/* eslint-disable max-lines */
/* eslint-disable max-len */
import { AppStatus } from '@/redux'
import { Button, ExpandableView, View, Text, EvaluatePublicationButtons, ActionIcon, usePublicationForm } from '@/components'
import { React, Theme } from '@/app'
import { APIClient } from '@/services'
import { useMemo, useState } from 'react'
import { Decision, DecisionAnswer, DecisionComment, DecisionMedia } from '@/types'
import { modal } from '@/utils'
import { NewPublicationVersion } from './components/NewPublicationVersion'
import { ReviewHeader } from './components/ReviewHeader'
import { SubmissionForm } from './components/SubmissionForm'
import { AttachmentsForm } from './components/AttachmentsForm'
import { CommentsForm } from './components/CommentsForm'
import { SectionFallback } from './components/SectionFallback'
import { RecommendationForm } from './components/RecommendationForm'

type ReviewsOverviewProps = {
  data?: {
    version?: number
    historyVersion?: boolean
  }
}

export type UpdateAnswersParams = { question: DecisionAnswer['id']; newValue: number | string; review: DecisionAnswer['review']; type: 'recommendations' | 'form_answers' }
export type UpdateCommentParams = { id?: DecisionComment['id']; newValue: string }
type CommentsType<T = 'editorComment' | 'reviewerComments'> = T extends 'editorComment' ? { comment: string } : { [id: number]: string}
type FormType = { [review: number]:({ question: number; value: number})[]}
type SavedAttachmentsType = { delete: {id: number; isEditor: boolean}[]; files:DecisionMedia[] }

export const ReviewsOverview = modal<ReviewsOverviewProps>({ id: 'reviewsOverview', independent: true }).content((props) => {
  const { toggle, data } = props

  const publication = usePublicationForm(v => v.publication)
  const publicationQuery = usePublicationForm(v => v.publicationQuery)
  const publicationId = usePublicationForm(v => v.article)

  const [version, setVersion] = useState(data?.version || publication?.version)

  const [formAnswers, setFormAnswers] = useState<FormType>({})
  const [recommendations, setRecommendations] = useState<FormType>({})
  const [reviewerComments, setReviewerComments] = useState<CommentsType<'reviewerComments'>>({})
  const [editorComment, setEditorComment] = useState<CommentsType<'editorComment'>>({ comment: '' })

  const [newAttachments, setNewAttachments] = useState<File[]>([])
  const [savedAttachments, setSavedAttachments] = useState<SavedAttachmentsType>({ delete: [], files: [] })

  const { isEditor, isPublisher, isAuthor } = APIClient.Session.usePermissions()

  const { currentPublicationVersion, isCurrentVersion, shouldDisableActions } = useMemo(() => {
    const currentPublicationVersion = publication?.versions[version - 1]
    const isCurrentVersion = data?.version ? data?.version === publication?.version : true
    const shouldDisableActions = isAuthor || !isCurrentVersion

    return { version, currentPublicationVersion, isCurrentVersion, shouldDisableActions }
  }, [version, isAuthor])

  const { data: decision, query: { isLoading, refetch, isRefetching }} = APIClient.Publications.decisionManager.useRetrieve({
    id: publicationId,
    filter: { version: version },
    queryOptions: {
      refetchOnWindowFocus: false,
      onSuccess: (data: Decision) => setSavedAttachments({ ...savedAttachments, files: data.media }),
    },
  })

  const updateReviewerComments = APIClient.Review.reviewManager.useUpdate()
  const deleteEditorAttachements = APIClient.Publications.mediaManager.useDelete()

  const hasUpdatedForm =
    Object.keys({ ...formAnswers, ...recommendations, ...reviewerComments }).length
    || editorComment.comment
    || newAttachments.length
    || savedAttachments.delete.length
    || savedAttachments.files.length

  const updateAnswers = (answer: UpdateAnswersParams) => {
    // recommendation and formAnswers follows the same structure
    const { type } = answer
    const updatedFormAnswers = { ...(type === 'recommendations' ? recommendations : formAnswers) }
    const currentAnswers = updatedFormAnswers[answer.review] || []
    const existingAnswerIndex = currentAnswers.findIndex(({ question }) => question == answer.question)

    if (existingAnswerIndex !== -1) {
      currentAnswers[existingAnswerIndex] = { question: Number(answer.question), value: answer.newValue }
    } else {
      currentAnswers.push({ question: Number(answer.question), value: answer.newValue })
    }

    updatedFormAnswers[answer.review] = currentAnswers

    if (type === 'recommendations') {
      setRecommendations(updatedFormAnswers)
    } else {
      setFormAnswers(updatedFormAnswers)
    }
  }

  const updateFormComment = (comment: UpdateCommentParams) => {
    if (comment?.id) {
      setReviewerComments({ ...reviewerComments, [comment.id]: comment.newValue })
    } else {
      setEditorComment({ comment: comment.newValue })
    }
  }

  const addAttachment = (files) => {
    setNewAttachments(prev => ([...prev, ...files.map(({ file }) => file)]))
  }

  const deleteAttachment = ({ id, index }: { id?: number; index: number }) => {
    if (id) {
      const att = savedAttachments.files
      att.splice(index, 1)
      setSavedAttachments(prev => ({ files: att, delete: [
        ...prev.delete,
        { id, isEditor: savedAttachments.files[index]?.owner_role !== 'reviewer' },
      ] }))
    } else {
      const att = structuredClone(newAttachments)
      att.splice(index, 1)
      setNewAttachments(att)
    }
  }

  const onSave = async () => {
    AppStatus.set('loading')

    try {
      const promises = []

      // update Form answers and recommendation
      if (Object.keys(formAnswers).length || Object.keys(recommendations).length) {
        const answers = { ...formAnswers, ...recommendations }
        Object.keys(answers).forEach((reviewId) => {
          promises.push(APIClient.Review.JournalAnswers({ review: Number(reviewId), answers: answers[reviewId] }))
        })
      }

      // update editor comment if it has changes
      if (editorComment.comment) {
        promises.push(APIClient.Replica.updateReplica({ id: currentPublicationVersion?.id, article: publicationId, editor_comment: editorComment.comment, status: 'submitted' }))
      }

      // update reviewer comment if it has changes
      if (Object.keys(reviewerComments).length > 0) {
        Object.keys(reviewerComments).forEach((id) => {
          promises.push(updateReviewerComments.update({ id, article: publicationId, comment: reviewerComments[id], status: 'submitted' }))
        })
      }

      // Add new attachments created by the editor - they are saved within te replica media flagged with editor_media: true
      if (newAttachments.length) {
        promises.push(APIClient.Replica.PostReplicaMedia({ files: newAttachments, reply: currentPublicationVersion?.id }))
      }

      // Delete attachments by ID - Since review media and editor media are saved in different places, we need to check witch endpoint to use
      if (savedAttachments.delete.length) {
        const { editorMedia, reviewerMedia } = savedAttachments.delete.reduce((acc, vl) => {
          if (vl.isEditor) return { ...acc, editorMedia: [...acc.editorMedia, vl.id] }
          else return { ...acc, reviewerMedia: [...acc.reviewerMedia, vl.id] }
        }, { editorMedia: [], reviewerMedia: [] })

        if (reviewerMedia.length) {
          promises.push(APIClient.Review.onDeleteMediaAttachment({ media: reviewerMedia }))
        }

        if (editorMedia.length) {
          promises.push(deleteEditorAttachements.delete(editorMedia))
        }
      }

      await Promise.all(promises)
    } catch (e) {

    } finally {
      toggle()
      AppStatus.set('done')
      await APIClient.Publications.publicationHistoryManager.refresh({ filter: { article: publicationId }})
      await publicationQuery.refetch()
      await refetch()
    }
  }

  return (
    <>
      <View variants={['gap:4', 'column', 'fullWidth']}>

        <View variants={['fullWidth', 'justifySpaceBetween']}>
          <Text variants={[`h2`]} text={isAuthor ? 'View decision' : 'Submit decision'} />

          <View variants={['gap:2']}>
            {!shouldDisableActions && (isEditor || isPublisher) ? (
              <Button
                variants={['small', 'flat', 'backgroundColor:primary5']}
                text='Save'
                onPress={onSave}
                disabled={!hasUpdatedForm || shouldDisableActions}
                styles={{ text: { color: Theme.colors.light.neutral1 }}}
                debugName={'Review Overview Modal - Save Final Review'}
              />
            ) : null}
            <ActionIcon
              variants={['medium', 'minimal']}
              icon='x'
              onPress={toggle}
              debugName={'Review Overview Modal - Close Review Modal'}
            />

          </View>
        </View>

        <ReviewHeader publication={publication} version={version} setVersion={setVersion} toggle={toggle} showVersionControl={data?.historyVersion} />

        <ExpandableView initialState title='Recommendations'>
          <SectionFallback isLoading={isLoading || isRefetching} isEmpty={!Object.keys(decision?.recommendations || {}).length}>
            <RecommendationForm
              recommendations={decision?.recommendations}
              publicationId={publicationId}
              updateAnswer={updateAnswers}
              disabled={shouldDisableActions}
            />
          </SectionFallback>
        </ExpandableView>

        <ExpandableView initialState title='Submission form'>
          <SectionFallback isLoading={isLoading || isRefetching} isEmpty={!Object.keys(decision?.form_answers || {}).length}>
            <SubmissionForm
              formAnswers={decision?.form_answers}
              publicationId={publicationId}
              updateAnswer={updateAnswers}
              disabled={shouldDisableActions}
            />
          </SectionFallback>
        </ExpandableView>

        {(!savedAttachments.files.length && (isAuthor || shouldDisableActions)) ? null : (
          <ExpandableView styles={{ contentInnerWrapper: { paddingTop: 0 }}} initialState title='Attachments'>
            <AttachmentsForm
              savedAttachments={savedAttachments.files}
              newAttachments={newAttachments}
              publicationId={publicationId}
              addAttachment={addAttachment}
              deleteAttachment={deleteAttachment}
              disabled={shouldDisableActions}
            />
          </ExpandableView>
        )}

        <ExpandableView initialState title={'Comments'}>
          <SectionFallback isLoading={isLoading || isRefetching} isEmpty={(isAuthor || shouldDisableActions) && !decision?.comments.length} emptyPlaceholderProps={{
            icon: 'file-text',
            title: 'No Comments provided.',
          }}>
            <CommentsForm
              comments={decision?.comments}
              publicationId={publicationId}
              updateComment={updateFormComment}
              disabled={shouldDisableActions}
            />
          </SectionFallback>
        </ExpandableView>

        {isCurrentVersion ? (
          (isEditor || isPublisher) ? (
            <EvaluatePublicationButtons
              publication={publicationId}
              onAcceptPublication={onSave}
              onRejectPublication={onSave}
              onRevisePublication={onSave}
              onWithdrawPublication={onSave}
            />
          ) : (
            <NewPublicationVersion publication={publication}/>
          )
        ) : null}

      </View>
    </>
  )
}).props({ showClose: false, dismissOnBackdrop: false, variants: ['reviewOverview'], onClose: () => null })
