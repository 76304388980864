import './services/firebaseApp'

import { StyleProvider, ReactQuery, I18NProvider, onUpdate } from '@codeleap/common'
import { Settings, variantProvider, React, I18N } from './app'
import {
  AppStatusOverlay,
  DebugModal,
  GlobalStyle,
  GlobalAlert,
  variants,
  UploadFiles,
  UploadFilesProvider,

} from './components'
import { Provider } from 'react-redux'
import { store, useAppSelector } from './redux'
import { APIClient } from './services'
import { Location, useLocation } from '@reach/router'
import { Navigation, ModalOutlet } from './utils'

const fieldsToCheck = [
  'region',
  'phone',
  'gender',
  'degree',
  'position',
  'department',
  'organisation',
  'tags',
  'address',
  'postcode',
]

export const Overlays = () => (
  <Location>
    {() => <>
      <DebugModal/>
      <AppStatusOverlay />
      <UploadFiles />

      <ModalOutlet />
    </>}
  </Location>
)

const App = ({ children }) => {
  const { profile, isLoggedIn } = APIClient.Session.useSession(true)
  const { badges } = APIClient.Badges.useBadges(profile)
  const location = useLocation()
  const { visible: signupVisible } = useAppSelector(store => store.AppStatus.modals.signup)

  onUpdate(() => {
    if (isLoggedIn) {
      APIClient.Session.checkSignupCompletion(profile).then(({ isComplete }) => {
        const missingInformation = fieldsToCheck.some((field) => {
          const value = profile[field]
          return value === null || value === '' || !value
        })

        // if (isComplete && missingInformation && !signupVisible) {
        //   AppStatus.setModal('aditionalProfileInfo')
        //   return
        // }

        if (profile?.current_role === 'reviewer') { // refactor
          const search = new URLSearchParams(location.search)
          const inviteId = search.get('invite')
          if (inviteId || badges?.invites) {
            Navigation.navigate('Invites.List')
          }
        }
      }).catch()
    }
  }, [profile, isLoggedIn, signupVisible])

  return (
    <>

      <GlobalStyle />
      {children}
      <Overlays />
      <GlobalAlert />
    </>
  )
}

export const Root = ({ children }) => {
  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <Provider store={store}>
        <StyleProvider
          settings={Settings}
          variantProvider={variantProvider}
          variants={variants}
          logger={logger}
        >
          <I18NProvider i18n={I18N}>
            <Location>
              {() => <>
                <UploadFilesProvider>
                  <App>
                    {children}
                  </App>
                </UploadFilesProvider>
              </>}
            </Location>
          </I18NProvider>
        </StyleProvider>
      </Provider>
    </ReactQuery.QueryClientProvider>
  )
}
