import { React, AppForms, variantProvider } from '@/app'
import { Button, TextEditor, TextEditorExtensions, TextInput, View, Text, TemplateVariables } from '@/components'
import { useForm } from '@codeleap/common'
import { useEditor } from '@tiptap/react'
import { InvitationReviewer, Publication } from '@/types'
import Placeholder from '@tiptap/extension-placeholder'
import { modal, ModalFlowProps } from '@/utils'
import { APIClient } from '@/services'
type InviteReviewerProps = {
  publication: Publication['id']
  reviewers?: Partial<InvitationReviewer>[]
  callback?: () => void
  full_name?: string
  email?: string
  message?: string
  subject?: string
}

export const InviteReviewerModal = modal<InviteReviewerProps>().content((props) => {
  const { publication: publicationID, toggle, visible, nextOrToggle, flow, request, ...data } = props as ModalFlowProps<'inviteReviewers', InviteReviewerProps>

  const publication = APIClient.Publications.useRetrieve({ id: publicationID }).data

  const emailTemplate = data?.message || publication?.journal?.reviewer_custom_email

  const editor = useEditor({
    content: emailTemplate,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: ({ editor }) => form.setFieldValue('message', editor.getHTML()),

  })

  const inviteForm = useForm(AppForms.createInvite, { initialState: { message: emailTemplate, subject: data?.subject }})

  const manualInviteForm = useForm(AppForms.createManualInvite, {
    initialState: {
      email: data?.email,
      full_name: data?.full_name,
      message: emailTemplate,
      subject: data?.subject,
    },
  })

  const isManual = data?.reviewers?.length === 0
  const form = isManual ? manualInviteForm : inviteForm

  const handleSubmit = async () => {
    const { full_name, email } = manualInviteForm.values
    const emailData = {
      ...data,
      publication: publication?.id,
      full_name,
      email,
      isManual,
      message: form.values?.message,
      subject: form.values.subject,
      canGoBack: true,
    }

    nextOrToggle(emailData)

  }

  const isMaxValid = editor?.getHTML().length <= 10000
  const isMinValid = editor?.getHTML().length >= 22

  return (

    <View variants={['column', 'fullWidth', 'gap:1']}>
      {isManual ? (
        <>
          <TextInput {...manualInviteForm.register('full_name')} />
          <TextInput {...manualInviteForm.register('email')} />
        </>
      ) : (
        <View variants={['column', 'gap:1', 'fullWidth', 'marginBottom:3']}>
          <Text variants={['p2', 'color:caption']} text='Recipients' />
          {data?.reviewers?.map(({ email }) => (
            <Text text={email} variants={['color:neutral8']} />
          ))}
        </View>
      )}
      <TextInput {...form.register('subject')} />
      <View variants={['column']}>
        <TemplateVariables variants={['marginBottom:3']}/>
        <TextEditor
          value={form?.values?.message}
          toolbarComponentProps={{ excludeIds: ['image', 'fileComponent'] }}
          textEditorProps={{
            editor,
            variants: ['reviewerQuestionsForm', 'multiline'],
            _error: (!isMaxValid || !isMinValid),
            validate: (isMaxValid || isMinValid) ? false : form.register('message')?.validate,
            ...form.register('message'),
            styles: { editor: { maxHeight: 400 }},
          }}
        />
        {!isMinValid && <Text variants={['p3', 'color:destructive2']} text={'Minimum of 15 characters'} />}
        {!isMaxValid && <Text variants={['p3', 'color:destructive2']} text={'Maximum of 10000 characters'} />}
      </View>
      <Button
        debugName='Send invite modal'
        text='Send invitation'
        icon='send'
        disabled={!form.isValid || editor?.getText()?.length < 15 || editor?.getHTML()?.length > 10000}
        variants={['marginHorizontal:auto', 'marginTop:1', 'large', 'border-radius:tiny']}
        onPress={handleSubmit}
      />
    </View>

  )
})

const styles = variantProvider.createComponentStyle(
  () => ({
    box: {
      overflow: 'auto',
    },
  }),
  true,
)

InviteReviewerModal.props({
  styles: {
    box: styles.box,
  },
  title: 'Invite a reviewer',
})
