import { React } from '@/app'
import { ExpandableView, usePublicationForm, View } from '@/components'
import {
  ArticleForm,
  AttachmentsForm,
  AuthorForm,
  CoverLetterForm,
  DetailsForm,
  DisclaimersForm,
  SuggestReviewerForm,
} from './modules'
import { PlagiarismChecker } from '../Plagiarism'

export const PublicationForm = () => {
  const publication = usePublicationForm((v) => v.publication)
  const notDraft = publication.status !== 'saved_in_drafts'

  return (
    <>
      <ExpandableView initialState title='Cover Letter' id='cover-letter-anchor'>
        <CoverLetterForm />
      </ExpandableView>

      {notDraft && (
        <ExpandableView initialState title='Plagiarism check'>
          <PlagiarismChecker id={publication?.id} />
        </ExpandableView>
      )}

      <ExpandableView initialState title='Article' id='article-anchor'>
        <ArticleForm />
      </ExpandableView>

      <ExpandableView initialState title='Attachments'>
        <AttachmentsForm />
      </ExpandableView>

      <ExpandableView initialState title='Authors' id='authors-anchor'>
        <AuthorForm />
      </ExpandableView>

      <ExpandableView
        title='Suggest Reviewers'
        description={`Max. of ${publication?.reviewers_quantity} reviewers`}
        initialState
        id='reviewers-anchor'
      >
        <SuggestReviewerForm />
      </ExpandableView>

      <ExpandableView initialState title='Details' id='details-anchor'>
        <DetailsForm />
      </ExpandableView>

      <View id='disclaimers-anchor'>
        <DisclaimersForm />
      </View>
    </>
  )
}
