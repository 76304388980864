import { Publication, PublicationAuthor } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { api } from '@/app'

const BASE_URL = 'article/'

async function listAuthors(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<PublicationAuthor>>(`${BASE_URL}author/`, { params })

  return response.data
}

async function retrieveAuthor(id: Publication['id']) {
  const response = await api.get<PublicationAuthor>(`${BASE_URL}author/${id}`)

  return response.data
}

export async function createAuthors(publicationId: Publication['id'], authors: Partial<PublicationAuthor>[]) {
  const response = await api.post<PublicationAuthor[]>(
    `${BASE_URL}author/`,
    { article: publicationId, authors },
  )

  return response.data
}

async function removeAuthor(author: PublicationAuthor) {
  await api.delete(`${BASE_URL}author/${author.id}/`)

  return author
}

export const authorsManager = new QueryManager<PublicationAuthor>({
  itemType: {} as PublicationAuthor,
  name: 'authors',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: {article: Publication['id']}) => {
    const response = await listAuthors({ limit, offset, ...filter })
    return response
  },
  /*@ts-ignore*/
  createItem: async (data: {publicationId: number; authors: PublicationAuthor[]}) => {
    const response = await createAuthors(data.publicationId, data.authors)
    return response
  },

  retrieveItem: async (id: PublicationAuthor['id']) => {
    const response = await retrieveAuthor(id)
    return response || null
  },

  deleteItem: async (data: PublicationAuthor) => {
    const response = await removeAuthor(data)
    return response
  },
})
