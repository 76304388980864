import { Button } from '@/components'
import { useSorter } from '@/utils'
import { PropsOf } from '@codeleap/common'

type SortButtonProps = Partial<PropsOf<typeof Button>> & {
  sorter: ReturnType<typeof useSorter>
  selectedText?: string
}

export const SortButton = (props: SortButtonProps) => {
  const { sorter, icon = 'arrow-up-down', text = 'Sorting', selectedText, ...buttonProps } = props

  let buttonText = text

  const hasOption = !!sorter?.currentOption?.label

  if (hasOption) {
    buttonText = selectedText ? selectedText : `${text} by: ${sorter?.currentOption.label}`
  }

  return <Button
    text={buttonText}
    icon={icon}
    debugName={`Sorting Modal Press`}
    onPress={sorter?.openSorter}
    variants={['sorter']}
    selected={hasOption}
    {...buttonProps}

  />
}
