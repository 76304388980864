import { modalFlow, useFileCategoryOptions } from '@/utils'
import { UploadFilesModal } from './UploadFilesModal'
import { ScanFilesModal } from './ScanFilesModal'
import { FormTypes } from '@codeleap/common'
import { Attachment, AttachmentWithCategory } from '@/types'

export const UploadFlow = modalFlow([
  UploadFilesModal,
  ScanFilesModal,
])

export type PrepareFilesForUploadParams = {
  files?: File[]
  attachments?: (Attachment | AttachmentWithCategory)[]

  showConfirmation?: boolean
  showPicker?: boolean
  scanFiles?: boolean
  showErrors?: boolean

  pickerCategoriesFilter?: Parameters<typeof useFileCategoryOptions>[0]

  validator?: FormTypes.ValidatorFunctionWithoutForm<AttachmentWithCategory[]>
  selectionLimit?: number
  withCategory?: boolean
  pickerDescription?: string
}

export function prepareFilesForUpload(params: PrepareFilesForUploadParams) {
  const {
    scanFiles = true,
    files = [],
    attachments = [],
    showConfirmation = false,
    showPicker = !files?.length && !attachments?.length,
    pickerCategoriesFilter,
    validator,
    withCategory = true,
    showErrors = false,
    selectionLimit = Infinity,
    pickerDescription = null,
  } = params

  if (!scanFiles && !showPicker) {
    throw new Error('At least one of the following options must be enabled: scanFiles, showPicker')
  }

  if (!!attachments?.length && !!files?.length) {
    throw new Error('You must provide either attachments or files, not both')
  }

  if (scanFiles && showPicker) {
    return UploadFlow.request({
      initialAttachments: attachments,
      initialFiles: files,
      categoryFilters: pickerCategoriesFilter,
      validate: validator,
      showErrors,
      withConfirmation: showConfirmation,
      withCategory,
      selectionLimit,
      description: pickerDescription,
    })
  }

  if (scanFiles) {
    return ScanFilesModal.request({
      attachments: attachments as AttachmentWithCategory[],
      files,
      confirmOnComplete: false,
    })
  }

  if (showPicker) {
    return UploadFilesModal.request({
      validate: validator,
      categoryFilters: pickerCategoriesFilter,
      initialAttachments: attachments,
      initialFiles: files,
      withCategory,
      showErrors,
      selectionLimit,
      description: pickerDescription,
    })
  }

}
