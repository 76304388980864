import { View, Button, TextInput } from '@/components'
import { React } from '@/app'
import { useToolBarContext } from '../ToolbarContext'
import { modal } from '@/utils'
import { createForm, useForm } from '@codeleap/common'

export const LinkForm = createForm('hyperlink', {
  text: {
    type: 'text',
  },
  link: {
    type: 'text',
  },
})

export const LinkModal = modal<{ text?: string; link?: string }>({ id: 'linkModal' }).content((props) => {
  const { text = '', link = '', request, toggle } = props
  const form = useForm(LinkForm, { initialState: { text, link }})

  const handleSubmit = () => {
    request.resolve(form.values)
  }

  return (
    <>
      <View variants={['column', 'alignStart', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'flex', 'gap:2', 'fullWidth']}>
          <TextInput
            {...form.register('text')}
            variants={['noError']}
          />
          <TextInput
            {...form.register('link')}
            variants={['noError']}
          />
        </View>

        <View variants={['fullWidth', 'alignCenter', 'justifyEnd', 'gap:1']}>
          <Button debugName='LinkModal:cancel' text={'Cancel'} onPress={toggle}/>
          <Button debugName='LinkModal:save' text={'Save'} onPress={handleSubmit}/>
        </View>
      </View>
    </>
  )
}).props({
  showClose: true,
  closable: true,
  closeOnEscape: true,
  variants: [],
  title: 'Add Link',
  styles: { box: { width: 550 }},
})
