import { TextEditorExtensions, View, usePublicationForm, TextEditor } from '@/components'
import { deepEqual, useImperativeHandle, useMemo, useRef, useState } from '@codeleap/common'
import { EmptyPlaceholder, FileInputRef } from '@codeleap/web'
import Placeholder from '@tiptap/extension-placeholder'
import { PublicationUtils, useDebouncedEditor, useMemoizedForm } from '@/utils'
import { AppForms, Settings } from '@/app'

export const CoverLetterForm = () => {
  const publication = usePublicationForm(v => v.publication)
  const isPublicationEditable = usePublicationForm(v => v.isPublicationEditable)
  const ref = usePublicationForm(v => v.modulesRefs.coverLetter)

  const initialState = useMemo(() => ({ cover_letter: publication?.cover_letter || '' }), [publication])

  const form = useMemoizedForm(AppForms.editCoverLetter, { validateOn: 'change', initialState })
  const [validate, setValidate] = useState(false)

  const editor = useDebouncedEditor({
    content: form?.values.cover_letter,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Start writing your cover letter here', emptyEditorClass: 'placeholder' })],
    onUpdate: ({ editor }) => form.setFieldValue('cover_letter', editor.getHTML()),
    onBlur: () => setValidate(true),
    editable: isPublicationEditable,
  })

  const textEditorRef = useRef<FileInputRef>(null)

  const onClear = () => form.setFormValues({ cover_letter: '' })
  const onSave = () => form.values
  const onValidate = () => ({ isValid: form.isValid, anchor: PublicationUtils.fieldAnchors.cover_letter })

  const error = useMemo(() => {
    const { min, max } = Settings.CoverLetterConfigs

    let errorMessage: string
    if (editor?.getText().length <= min) errorMessage = `Minimum of ${min} characters`
    if (editor?.getText().length >= max) errorMessage = `Maximum of ${max} characters`

    return validate ? errorMessage : ''
  }, [form.values, validate, Settings.CoverLetterConfigs])

  useImperativeHandle(ref, () => ({
    onValidate,
    onSave,
    onClear,
    saveOnPublication: true,
    hasChanges: !deepEqual(form.values, initialState),
    showError: () => setValidate(true),
  }))

  if (!isPublicationEditable && !form.values.cover_letter) {
    return <EmptyPlaceholder icon='license' variants={['table']} title='No Cover Letter found.' />
  }

  return (
    <View variants={['fullWidth', 'column', 'marginBottom:3']}>
      <TextEditor
        toolbarComponentProps={{ editor, excludeIds: ['fileComponent'], hide: !isPublicationEditable }}
        textEditorProps={{
          editor,
          variants: ['reviewerQuestionsForm', 'disabledPlain', !isPublicationEditable ? 'flat' : ''],
          fileInputRef: textEditorRef,
          ...form.register('cover_letter'),
          validate: false,
          _error: error,
        }}
      />
    </View>
  )
}
