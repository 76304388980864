import React from 'react'
import { AppImages, I18N, Theme, variantProvider } from '@/app'
import { Text, View, ActionIcon, Image } from '@/components'
import { modal } from '@/utils'

export const Reviewer = modal({ id: 'reviewer' }).content((props) => {
  const { toggle } = props

  return (
    <>
      <View variants={['relative']}>
        <Image
          source={AppImages.Reviewer}
          objectFit='cover'
          style={styles.image}
        />
        <ActionIcon
          name={'x'}
          variants={['minimal', 'neutral1']}
          iconProps={{
            size: Theme.values.iconSize[3],
          }}
          style={styles.icon}
          onPress={toggle}
        />
      </View>
      <View variants={['column', 'gap:2', 'padding:4']}>
        <Text variants={['h1', 'alignCenter', 'textCenter', 'marginTop:2']} text={I18N.t('modals.reviewer.title')} />
        <Text variants={['marginTop:2']} text={I18N.t('modals.reviewer.description')} />
        <Text variants={['textLeft']}>
          &#8226; {I18N.t('modals.reviewer.list')}
        </Text>
      </View>
    </>
  )
})

const imageSize = 480
const MODAL_WIDTH = 480
const styles = variantProvider.createComponentStyle((theme) => ({
  box: {
    width: MODAL_WIDTH,
  },
  image: {
    width: imageSize,
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}), true)

Reviewer.props({
  showClose: false,
  variants: ['centered', 'center', 'boxPadding:0'],
  styles: {
    box: styles.box,
  },
})
