import { modal, useSorter, useSortModal } from '@/utils'
import React from 'react'
import { Content, Item } from './components'
import { FilterDataItem, SortModalProps } from './Sort'
import { journalTypeOptions } from '../Dashboard'

export type JournalTypeModalProps = SortModalProps & React.PropsWithChildren<{}>

export const JournalTypeModal = modal<JournalTypeModalProps, FilterDataItem[]>().content((props) => {
  const sortParams = useSortModal({ options: journalTypeOptions, initialState: props.initialState, request: props.request, limit: props.limit })
  const hasSelectedItems = sortParams?.handle?.selectedItems[0]?.length > 0

  return <>
    <Content
      RenderItem={Item}
      confirmDisabled={!hasSelectedItems}
      {...sortParams}
      {...props}
    />
  </>
})

export const useJournalTypeSorter = () => {
  const sorter = useSorter({
    key: 'DASHBOARD_SORT_JOURNAL_TYPE',
    options: journalTypeOptions,
    modal: JournalTypeModal,
    limit: 1,
    hasScroll: true,
    enableLocalStorage: false,
  })

  return {
    sorter,
  }
}

JournalTypeModal.props({
  variants: ['sectionFiltersModal', 'centered'],
  title: 'Journals filter',
})

