import { IconComposition, IconPresets } from '@codeleap/web'
import { variantProvider } from '..'

const createIconStyle = variantProvider.createVariantFactory<IconComposition>()

export const AppIconStyles = {
  ...IconPresets,
  default: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral7,
    },
  })),
  neutral1: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
  })),

  primary: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary3,
      fill: theme.colors.primary3,
    },
  })),
  'primary5:outline': createIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary5,
    },
  })),
  negative: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
      fill: theme.colors.neutral1,
    },
  })),
  positive: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
      fill: theme.colors.neutral10,
    },
  })),
  positive2: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.positive2,
    },
  })),
  neutral5: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral5,
    },
  })),
  neutral8: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral8,
    },
  })),
  neutral10: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  destructive2: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
  })),

  huge: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(8),
    },
  })),
  large: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(6),
    },
  })),
  largeish: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(4),
    },
  })),
  medium: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(3),
    },
  })),
  small: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(2.5),
    },
  })),
  smaller: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(2),
    },
  })),
  smallish: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(1.75),
    },
  })),
  'size:1': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
  })),
  'size:2': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  'size:3': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  'size:4': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[4],
      height: theme.values.iconSize[4],
    },
  })),
  'noFill': createIconStyle((theme) => ({
    icon: {
      fill: 'unset',
    },
  })),
  'noShrink': createIconStyle((theme) => ({
    icon: {
      flexShrink: 0,
    },
  })),
}
