import { api } from '@/app'
import { Publication, Replica, ReplicaMedia } from '@/types'
import { PaginationResponse, QueryManager, ReactQuery, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from './queryClient'

const REPLICA_BASE_URL = 'article/reply/'
const REPLICA_MEDIA_BASE_URL = 'article/reply_media/'

type PostReplicaProps = {
  comment: string
  article: Publication['id']
}

type ListReplicaMediaProps = {
  reply?: Replica['id']
}

async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Replica>>(`${REPLICA_BASE_URL}/`, {
    params,
  })

  return response.data
}

async function listMedia(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<ReplicaMedia>>(`${REPLICA_MEDIA_BASE_URL}`, {
    params,
  })

  return response.data
}

type RetrieveVersionArgs = {
  version: number
  article: Publication['id']
}

export async function retrieveVersion({ version, article }:RetrieveVersionArgs) {
  const response = await api.get<Replica>(REPLICA_BASE_URL, { params: { version, article }})
  return response.data
}

export async function retrieveReplica({ reply, review, version, article }) {
  const response = await api.get<Replica>(`${REPLICA_BASE_URL}${reply}/`, { params: { review, version, article }})
  return response.data
}

// export async function retrieveReplicaMedia({ reply }) {
//   const response = await api.get<ReplicaMedia>(REPLICA_MEDIA_BASE_URL, { params: { reply }})
//   return response.data
// }

export async function createMedia(params: Partial<ReplicaMedia>) {
  const response = await api.post<ReplicaMedia>(REPLICA_MEDIA_BASE_URL, params, { multipart: true })
  return response.data
}

export const PostReplica = async (props: PostReplicaProps) => {
  const response = await api.post<Replica>(REPLICA_BASE_URL, { ...props })
  return response.data
}

export const updateReplica = async (props) => {
  const { id, ...data } = props
  const response = await api.patch<Replica>(`${REPLICA_BASE_URL}${id}/`, { ...data })
  return response.data
}

export const ResendReplica = async (params) => {
  const response = await api.post('article/resend_to_reviewers/', { ...params })
  return response
}

export const PostReplicaMedia = async ({
  reply,
  files = [],
}: Pick<ReplicaMedia, 'reply'> & { files?: Partial<ReplicaMedia>[] }) => {
  const sendFile = async (file: Partial<ReplicaMedia>) => {
    const fileData = file || {}

    const media = {
      data: {
        reply,
        file_size: fileData?.file?.size || fileData?.size,
        category: fileData?.category || file?.file_category,
      },
      file: fileData?.file || fileData,
    }

    return createMedia(media)
  }

  return Promise.all(files.map(sendFile))
}

export const useReviewReplica = (params) => {
  return ReactQuery.useQuery({
    queryKey: [`review-replica-${params?.reply}`],
    queryFn: async () => await retrieveReplica(params),
    refetchOnWindowFocus: false,
    refetchOnMount(q) {
      return false
    },
    retry: false,
    enabled: true,
  })
}

// export const useReviewReplicaMedia = (params, queryOptions: ReactQuery.UseQueryOptions = {}) => {
//   return ReactQuery.useQuery({
//     queryKey: [`review-replica-media-${params?.reply}`],
//     queryFn: async () => await retrieveReplicaMedia(params),
//     refetchOnWindowFocus: false,
//     refetchOnMount: false,
//     retry: false,
//     enabled: true,
//     ...queryOptions,
//   })
// }

type ReplicaFilters = {
  version?: number
}

export const replicaManager = new QueryManager({
  itemType: {} as Replica,
  name: 'replica',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ReplicaFilters) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  retrieveItem: async (id, params) => {
    return retrieveVersion({
      article: id,
      version: params.version,
    })
  },

  createItem: async (data: PostReplicaProps) => {
    const response = await PostReplica(data)
    return response
  },

  updateItem: async (data) => {
    const response = await updateReplica(data)
    return response
  },

})

export const replicaMediaManager = new QueryManager({
  itemType: {} as ReplicaMedia,
  name: 'replicaMedia',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListReplicaMediaProps) => {
    const response = await listMedia({ limit, offset, ...filter })
    return response
  },

  createItem: createMedia,
})
