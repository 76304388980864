import { React, variantProvider } from '@/app'
import { View, Text, ActionIcon, Button, ExpandableView, useJournalCrudContext } from '@/components'
import { useCallback, useState } from 'react'
import { DisclaimerModal } from './DisclaimerModal'
import { TypeGuards } from '@codeleap/common'
import { DisclaimerItemProps, SaveDisclaimerProps } from '@/types'

export const JournalDisclaimers = () => {
  const {
    disclaimers: _items,
    setDisclaimers,
    previousDisclaimers: disclaimers,
  } = useJournalCrudContext()

  const [items, setItems] = !TypeGuards.isNil(_items) && !!setDisclaimers ? [_items, setDisclaimers] : useState<DisclaimerItemProps[]>([])
  const [currentItem, setCurrentItem] = useState<DisclaimerItemProps>(null)

  const onSaveDisclaimer = (props: SaveDisclaimerProps) => {
    const { type, data } = props

    if (type === 'create') {
      const initialDisclaimers = {
        ...data,
        id: Math.floor(Date.now() / 1000),
      }

      setItems([...items, initialDisclaimers])
    } else {
      const newItems = items?.filter(d => d?.id !== data?.id)
      setItems([...newItems, data])
    }

    setCurrentItem(null)
  }

  const onAddDisclaimer = () => {
    setCurrentItem(null)
    DisclaimerModal.request().then(onSaveDisclaimer).catch(() => null)
  }

  const renderItem = useCallback(({ item, index }: { item: DisclaimerItemProps; index: number }) => {

    const isLastItem = index === items?.length - 1

    const onRemoveDisclaimer = () => {
      setItems((state) => {
        const newItems = state?.filter((disclaimer: DisclaimerItemProps) => disclaimer?.id !== item?.id)
        return newItems
      })
    }

    const onEditDisclaimer = () => {
      setCurrentItem(item)
      DisclaimerModal.request().then(onSaveDisclaimer).catch(() => null)
    }

    return <>
      <View variants={['flex', 'row', 'fullWidth', 'paddingHorizontal:2', 'gap:2', 'alignCenter', 'justifySpaceBetween']}>
        <Text variants={[`p1`, 'color:neutral8', 'marginLeft:1']} text={item?.title} />

        <View variants={['row', 'gap:2']}>
          <ActionIcon
            name={'edit-2'}
            onPress={onEditDisclaimer}
            debugName={'Journal disclaimers - On Edit Disclaimer'}
            variants={['backgroundColor:neutral1', 'primary5', 'iconSize:2', 'normalize']}
          />

          <ActionIcon
            name={'x'}
            onPress={onRemoveDisclaimer}
            debugName={'Journal disclaimers - On Remove Disclaimer'}
            variants={['backgroundColor:neutral1', 'destructive2', 'iconSize:2', 'normalize']}
          />
        </View>
      </View>

      {isLastItem ? null : <View variants={['borderBottom']} />}
    </>
  }, [disclaimers, items])

  const renderItems = useCallback(() => {

    const isListEmpty = !items?.length

    if (isListEmpty) {
      return (
        <View variants={['padding:2', 'column']}>
          <View style={styles.listEmptyPlaceholderInnerWrapper} variants={['marginVertical:2', 'center', 'column']}>
            <ActionIcon
              icon='clipboard'
              variants={['backgroundColor:neutral2', 'border-radius:rounded', 'neutral9', 'size:3']}
              debugName={'Journal Disclaimers - List empty placeholder icon'}
              onPress={null}
            />
            <Text
              variants={[`p1`, 'marginTop:2', 'color:neutral7']}
              responsiveVariants={{ mid: ['textCenter'] }}
              text={`You don't have any author disclaimers for now.`}
            />
          </View>
        </View>
      )
    }

    return (
      <View variants={['flex', 'column', 'border', 'border-radius:small', 'paddingVertical:4', 'paddingHorizontal:2', 'gap:2']}>
        <Text variants={[`p4`, 'marginLeft:3', 'color:neutral7', 'marginBottom:1']} text={`DISCLAIMER TITLE`} />
        {items?.map((item, index) => renderItem({ item, index }))}
      </View>
    )
  }, [disclaimers, items])

  return (
    <ExpandableView initialState title='Author disclaimers'>
      <View variants={['flex', 'column']}>

        {renderItems()}

        <View variants={['justifyEnd', 'marginTop:3']} >
          <Button
            variants={[`flat`]}
            text={`Add disclaimer`}
            debugName={`Journals Disclaimer Modal - Add disclaimer`}
            icon={'plus'}
            onPress={onAddDisclaimer}
          />
        </View>

        <DisclaimerModal.Component
          item={currentItem}
          order={disclaimers?.data?.length}
        />

      </View>
    </ExpandableView>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  listEmptyPlaceholderInnerWrapper: {
    minHeight: 250,
  },
}), true)
