import { api } from '@/app'
import { queryClient } from '../queryClient'
import { Decision } from '@/types'
import { QueryManager } from '@codeleap/common'

const BASE_URL = 'article/decisions/'

type DecisionParams = {
  article: number
  version: number
}

const retrieveDecision = async (params: DecisionParams) => {
  const response = await api.get<{ reviews: Decision}>(BASE_URL, { params })

  return response.data
}

export const decisionManager = new QueryManager<Decision>({
  itemType: {} as Decision,
  name: 'decision',
  queryClient: queryClient.client,
  retrieveItem: async (article, args) => {
    const response = await retrieveDecision({ article, ...args })
    return response
  },
})
