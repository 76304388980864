import { React } from '@/app'
import { AuthModalProps, SignupForm } from '@/components'
import { useState } from '@codeleap/common'
import { modal } from '@/utils'
import { AuthModalWrapper } from './AuthModalWrapper'

export const SignupModal = modal<AuthModalProps>({ id: 'signup' }).content((props) => {
  const { data, formValues, provider } = props

  const [step, setStep] = useState(0)

  return (
    <AuthModalWrapper step={step + 1} type='signup'>
      <SignupForm
        isModal
        step={step}
        setStep={setStep}
        formValues={formValues}
        provider={provider}
        journalData={data}
      />
    </AuthModalWrapper>
  )
}).props({ renderHeader: () => null })
