import { JournalSubmissionsDetails } from '@/types'

export const journalTypeOptions = [
  {
    value: 'publications',
    label: 'Total submissions',
  },
  {
    value: 'submission_first_decision',
    label: 'Average time to first decision',
  },
  {
    value: 'submission_last_decision',
    label: 'Average time to last decision',
  },
]

export const manuscriptOptionsLabels = {
  status: 'Status',
  status_datetime: 'Last modified date',
  submission_datetime: 'Submission date',
  type: 'Manuscript type',
}

export const manuscriptFilters = ['status', 'status_datetime', 'submission_datetime', 'type']

export const predefinedDatesOptions = [
  {
    value: 7,
    label: 'Last 7 days',
  },
  {
    value: 30,
    label: 'Last 30 days',
  },
  {
    value: 90,
    label: 'Last 90 days',
  },
  {
    value: 365,
    label: 'Last 1 year',
  },
]

export const getOverviewData = (details: JournalSubmissionsDetails) => {
  return (
    [
      {
        value: details?.others?.total_submissions?.value,
        percentage: details?.others?.total_submissions?.percentage,
        description: 'Total submissions',
      },
      {
        value: details?.others?.submission_first_decision?.value,
        percentage: details?.others?.submission_first_decision?.percentage,
        description: 'Between the submission and the first decision',
        isDays: true,
      },
      {
        value: details?.others?.submission_last_decision?.value,
        percentage: details?.others?.submission_last_decision?.percentage,
        description: 'Between the submission and the final decision',
        isDays: true,
      },
    ]
  )
}
