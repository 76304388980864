import React from 'react'
import { DefaultTextInput } from '@/components'
import { useJournalCrudContext } from './CrudContext'
import { JOURNAL_URL_DOMAIN } from '@/app'
import { useDebouncer } from '@/utils'

export const JournalURLDomainInput = () => {
  const { form, isCreate } = useJournalCrudContext()

  const [debouncedValue, handleDebouncedChange] = useDebouncer(
    isCreate ? 'your_domain' : form.values.url_domain,
    (value) => {
      form.setFieldValue('url_domain', value)
    },
  )

  const handleChange = (text: string) => {
    let value = null

    value = text?.split('/')[text?.includes('your_domain') ? 3 : 2]

    handleDebouncedChange(value?.toLowerCase() ?? '')
  }

  const displayValue = isCreate
    ? JOURNAL_URL_DOMAIN + (debouncedValue)
    : JOURNAL_URL_DOMAIN + form.values.url_domain

  return (
    <DefaultTextInput
      {...form.register('url_domain')}
      value={displayValue}
      onChangeText={handleChange}
      disabled={!isCreate}
    />
  )
}
