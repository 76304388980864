import { Text, View, Toaster, Icon, List } from '@/components'
import { FileScanResponse, ScanResult } from '@/types'

const FilePreview = ({ item }) => {
  const result = item as ScanResult

  const hasError = result.result === 'infected'
  const icon = hasError ? 'x-circle' : 'check-circle-2'
  const disclaimerText = hasError ? 'Problems encountered' : 'Successfully verified'

  return (
    <View variants={['gap:2', 'padding:2']}>
      <Icon debugName='FilePreview:Icon' name='file' variants={['neutral8', 'small']} />

      <View variants={['column', 'gap:0.5', 'alignStart']}>
        <Text text={result.name} variants={['p1', 'neutral-8']} />

        <View variants={['center', 'gap:0.5']}>
          <Icon debugName='FilePreview:Icon Disclaimer' name={icon} variants={[hasError ? 'destructive2' : 'positive2', 'smallish']} />
          <Text text={disclaimerText} variants={['p4', hasError ? 'color:destructive2' : 'color:positive2']} />
        </View>
      </View>
    </View>
  )
}

type FilesOverviewProps = {
  results: FileScanResponse['results']
}

export const FilesOverview = (props: FilesOverviewProps) => {
  const { results } = props

  const hasErrors = results?.some(result => result.result === 'infected')
  return (
    <View variants={['column', 'gap:3']}>
      <Toaster.FilesOverview hasErrors={hasErrors}/>
      <View variants={['column', 'gap:2']}>
        <Text text='Analyzed files' variants={['p2', 'semiBold', 'color:neutral10']} />
        <List
          data={results}
          debugName='FilesMalwareCheck:List'
          renderItem={FilePreview}
          style={{ minHeight: 'auto', overflowY: 'auto', maxHeight: '60vh' }}
          masonryProps={{
            overscanBy: 8,
          }}
        />
      </View>
    </View>
  )
}
