import { Permission, PermissionLevel } from '@/types'
import { View, Text, Checkbox, Button, SelectJornal } from '@/components'
import { AppForms, getOsAlert, variantProvider } from '@/app'
import { FormComponentProps } from '../Publication/Forms/BaseForm'
import { useMemo } from 'react'
import { useMemoizedForm } from '@/utils'
import { deepEqual, onUpdate } from '@codeleap/common'

export type PermissionFormCardProps = FormComponentProps<Partial<Permission>> & {
  title: string
}

const levels: PermissionLevel[] = ['role_publisher', 'role_managing_editor', 'role_editor_chiefe', 'role_editor']

export const PermissionFormCard = (props: PermissionFormCardProps) => {
  const { title, value, onChangeValue, deleteForm } = props

  const form = useMemoizedForm(AppForms.permissionForm, { validateOn: 'change', initialState: value })

  const valid = useMemo(() => levels.some(l => form.values[l]), [form.values])

  onUpdate(() => {
    if (!deepEqual(value, form.values)) onChangeValue(form.values as any as Permission)
  }, [form.values])

  return (
    <View style={styles?.permissionCard}>
      <Text text={title} variants={['h5', 'color:neutral8']} />
      <SelectJornal {...form.register('journal')} />
      <View css={styles.checkpointWrapper}>
        {levels.map(l => <Checkbox {...form.register(l)} key={l} variants={['left', 'flex']} />)}
      </View>
      {valid ? null : (
        <Text
          variants={['p3', 'color:destructive2']}
          text='Please delete the permission if the user no longer has any permissions.'
        />
      )}
      <Button
        text='Delete'
        icon='trash'
        onPress={() => getOsAlert('handleDeletePermission', { onRemove: deleteForm })}
        variants={['destructive:flat', 'marginRight:auto']}
        debugName={`PermissionDelete:${value.id}`}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  permissionCard: {
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    ...theme.spacing.padding(3),
    borderRadius: theme.borderRadius.medium,
    border: `1px solid ${theme.colors.neutral5}`,
    ...theme.spacing.gap(3),
  },
  checkpointWrapper: {
    ...theme.presets.fullWidth,
    ...theme.presets.wrap,
    ...theme.spacing.gap(2),
  },
}), true)
