import { AppStatus } from '@/redux'
import { Disclaimer, Journal, JournalQuestion, Placeholder, Selector, UseContextType } from '@/types'
import { createContext, useContextSelector } from 'use-context-selector'
import { api, AppForms, React, getOsAlert, Settings } from '@/app'
import {
  FileUtils,
  getJournalDomain,
  JournalsUtils,
  Navigation,
  PublicationUtils,
  serializeObject,
  useFileState,
  useMemoizedForm,
} from '@/utils'
import { onUpdate, TypeGuards, useCallback, useRef, waitFor } from '@codeleap/common'
import { useFileInput } from '@codeleap/web'
import { ImageField, JournalCrudProviderData, JournalCrudProviderProps, JournalModuleRef } from './types'
import { journalsDisclaimerManager, journalsManager } from '../../../services/api/journals'
import {
  getRecommendationQuestion,
  hasDisclaimersChanged,
  hasJournalDataChanged,
  hasQuestionsChanged,
  hasRecommendationQuestionChanged,
  usePublishers,
} from './utils'
import { APIClient } from '@/services'
import {
  defaultAuthorDisclaimer,
  defaultQuestions,
  defaultRecommendationQuestion,
  emailFieldProps,
  journalImageResolutionText,
  portalImageResolutionText,
} from './defaultSettings'
import { SaveJournalAlertModal } from '@/components/Modals'
import { openCropPicker } from '@/components/CropPickerModal'
import { FileInput } from '@/components'

const JournalCrudContext = createContext<JournalCrudProviderData>({} as JournalCrudProviderData)

const DEBUG = false

const getLazyRef = () => useRef<JournalModuleRef>(null)

export const JournalCrudProvider = (props: JournalCrudProviderProps) => {
  const { children, journalId, journal, isCreate, previousDisclaimers, ...rest } = props

  const publishers = usePublishers()
  const { profile, emailTemplates: emails } = APIClient.Session.useSession()
  const createJournal = journalsManager.useCreate()
  const updateJournal = journalsManager.useUpdate()
  const deleteJournal = journalsManager.useDelete()

  const updateDisclaimers = journalsDisclaimerManager.useUpdate()
  const createDisclaimers = journalsDisclaimerManager.useCreate()

  const modulesRefs = {
    preferences: getLazyRef(),
    emailTemplates: getLazyRef(),
  }

  const [tab, setTab] = React.useState(JournalsUtils.segmentedControlOptions[0].value)

  const urlDomain = journal?.data?.url_domain

  const data = serializeObject(journal?.data, (key, value) => {
    if (key == 'publisher') return Number(value?.id)
    return value
  })

  const templates = emails?.reduce((acc, e) => ({ ...acc, [emailFieldProps[e.code]?.field]: e.body }), {})

  const form = useMemoizedForm(AppForms.journalForm, {
    validateOn: 'change',
    initialState: {
      ...templates,
      ...data,
    },
  })

  // ensure that the email templates are filled when changing roles
  onUpdate(() => {
    if (isCreate) {
      if (!!emails?.length) {
        form.setFormValues({ ...form.values, ...templates })
      }
    }
  }, [emails])

  const _questions = journal?.data?.questions?.filter((q) => q.type !== 'multiple-choice')
  const _recommendationQuestion = getRecommendationQuestion(journal?.data?.questions)

  const cover = useFileState()
  const image = useFileState()
  const publisherLogo = useFileState()

  defaultAuthorDisclaimer

  const [disclaimers, setDisclaimers] = React.useState<Disclaimer[]>(
    TypeGuards.isNil(previousDisclaimers?.data) ? (defaultAuthorDisclaimer as Disclaimer[]) : previousDisclaimers?.data,
  )

  const [questions, setQuestions] = React.useState<Journal['questions']>(
    TypeGuards.isNil(_questions) ? (defaultQuestions as JournalQuestion[]) : _questions,
  )

  const [recommendationQuestion, setRecommendationQuestion] = React.useState<JournalQuestion>(
    TypeGuards.isNil(_recommendationQuestion) ? (defaultRecommendationQuestion as JournalQuestion) : _recommendationQuestion,
  )

  const clearForm = () => {
    form.reset()
    cover.delete()
    image.delete()
    publisherLogo.delete()

    setDisclaimers([])
    setQuestions([])
    setRecommendationQuestion({} as JournalQuestion)
  }

  const fileInput = useFileInput()

  const onPickImage = async (field: ImageField) => {
    const files = await fileInput.openFilePicker()

    if (!files.length) return

    const [f] = files

    const { aspect } = Settings.CropPickerConfigs[field === 'cover' ? 'cover' : 'default']

    const croppedFile = await openCropPicker(f.file, {
      aspect,
      ruleOfThirds: true,
      description: field === 'cover' ? portalImageResolutionText : journalImageResolutionText,
    })

    if (!croppedFile) return

    const { compressedBase64Image, newFile } = await FileUtils.compressAndValidateImage(croppedFile)

    if (!compressedBase64Image && !newFile) return

    form.setFieldValue(field as any, compressedBase64Image)
    if (field === 'cover') cover.set(newFile)
    else if (field === 'image') image.set(newFile)
    else if (field === 'publisher_logo') publisherLogo.set(newFile)
  }

  const onDeleteImage = async (field: ImageField) => {
    form.setFieldValue(field as any, null)
    if (field === 'cover') cover.delete()
    else if (field === 'image') image.delete()
    else if (field === 'publisher_logo') publisherLogo.delete()
  }

  const handleSubmit = async ({ disclaimers, ...values }, files) => {
    const moduleError = Object.values(modulesRefs).find((m) => {
      return !m.current?.isValid
    })?.current

    if (!!moduleError && !moduleError?.isValid) {
      setTimeout(() => SaveJournalAlertModal.open(moduleError), 300)
      return
    }

    window?.scrollTo(0, 0)
    AppStatus.set('loading')

    if (!!values?.url_domain && values?.url_domain !== urlDomain && isCreate) {
      const validDomain = await journalsManager.actions.checkURLDomain(getJournalDomain(values?.url_domain))

      if (!validDomain) {
        AppStatus.set('idle')
        getOsAlert('invalidURLError')
        return
      }
    }

    const data: Journal = serializeObject(values as Journal, (key, value) => {
      if (key == 'cover' || key == 'image' || key == 'publisher_logo') {
        if (!value && !files[key]) return null
        if (!!value && !!files[key] && value !== files[key]) return files[key]
        return value
      }

      if (key == 'url_domain' && isCreate) return getJournalDomain(value)

      return value
    })

    delete data.questions

    if (DEBUG) logger.log('Journal Submit', { data, isCreate })

    try {
      let currentJournalId = journalId

      if (isCreate) {
        const response = await createJournal.create(data)
        currentJournalId = response?.id
        await APIClient.Session.update({
          id: profile.id,
          current_publisher: response.publisher.id,
          current_journal: currentJournalId,
        })
        await waitFor(2000)
      } else {
        await updateJournal.update(data)
      }

      const _hasQuestionsChanged = hasQuestionsChanged(values?.questions || [], [...questions, recommendationQuestion])

      const _hasRecommendationQuestionChanged = hasRecommendationQuestionChanged(
        getRecommendationQuestion(values?.questions),
        recommendationQuestion,
      )

      if (_hasQuestionsChanged || _hasRecommendationQuestionChanged) {
        const _questions = questions.map((question) => ({
          ...(typeof question?.id == 'string' ? {} : { id: question?.id }),
          question: question?.question,
          type: 'number',
        }))

        const isRecommendationQuestionValid =
          !!recommendationQuestion?.question && !!recommendationQuestion?.choices?.length

        if (isRecommendationQuestionValid) {
          _questions.push({ question: recommendationQuestion?.question, type: 'multiple-choice' })
        }

        const response = await api.post('publisher/questions/update_many/', _questions, {
          params: {
            journal: currentJournalId,
          },
        })

        const recommendationQuestionData = getRecommendationQuestion(response?.data) ?? ({} as JournalQuestion)

        if (isRecommendationQuestionValid) {
          const choicesData = recommendationQuestion?.choices?.map((c) => ({
            ...(typeof c.id == 'string' ? {} : { id: c.id }),
            question: c.question,
            label: c.label,
            value: c.value,
          }))

          await api.post('publisher/choices/update_many/', choicesData, {
            params: {
              question: recommendationQuestionData?.id,
            },
          })
        }
      }

      if (hasDisclaimersChanged(disclaimers, previousDisclaimers?.data || [])) {
        const disclaimersWithoutId = disclaimers.map(disclaimer => ({
          ...disclaimer,
          id: undefined,
        }))

        await updateDisclaimers.update({
          journal: currentJournalId,
          data: disclaimersWithoutId,
        })
        //We may not night that piece, but keep it here in case it breaks again
        // const newDisclaimers = disclaimers?.filter((d) => TypeGuards.isUndefined(d?.id))
        // if (newDisclaimers?.length) {
        //   createDisclaimers.create({ journal: currentJournalId, data: newDisclaimers })
        // }
      }

      await journalsManager.refresh()
      await previousDisclaimers?.refresh?.()

      Navigation.navigate('Journals.List', { route: 'view/' + currentJournalId })
      await waitFor(500)
      clearForm()

      AppStatus.set('done')
    } catch (err) {
      AppStatus.set('idle')
      logger.error('CRUD journal error', err)
    }
  }

  const onDelete = React.useCallback(async (data: Journal) => {
    try {
      AppStatus.set('loading')

      await deleteJournal.delete(data)

      journalsManager.refresh()

      Navigation.navigate('Journals.List')

      await waitFor(500)

      clearForm()

      AppStatus.set('done')
    } catch (err) {
      AppStatus.set('idle')
      logger.error('Delete Journal', err)
    }
  }, [])

  const handleFocusErrors = useCallback(() => {
    form.validateAll(true)
    PublicationUtils.handleFocusBlur(JournalsUtils.journalsAnchors)
  }, [form.validateAll])

  const submitDisabled = React.useMemo(() => {
    const isValid = form?.isValid || false
    if (!isValid || !recommendationQuestion?.choices?.[0]?.value) return true

    const hasDataChanged = hasJournalDataChanged(journal?.data, form?.values as any)
    const journalRecommendationQuestion = getRecommendationQuestion(journal?.data?.questions)
    const _hasRecommendationQuestionChanged = hasRecommendationQuestionChanged(
      journalRecommendationQuestion,
      recommendationQuestion,
    )
    const _hasQuestionsChanged = hasQuestionsChanged(journal?.data?.questions || [], [
      ...questions,
      recommendationQuestion,
    ])
    const _hasDisclaimersChanged = hasDisclaimersChanged(disclaimers, previousDisclaimers?.data)

    return !(
      hasDataChanged ||
      _hasQuestionsChanged ||
      _hasRecommendationQuestionChanged ||
      recommendationQuestion?.choices?.[0]?.value ||
      _hasDisclaimersChanged
    )
  }, [
    journal?.data,
    form?.values,
    form?.isValid,
    questions,
    disclaimers,
    previousDisclaimers?.data,
    recommendationQuestion,
    isCreate,
  ])

  const providerData: JournalCrudProviderData = {
    handleDelete: () => getOsAlert('handleDelete', { onDelete: () => onDelete(journal?.data) }),
    handleSubmit,
    onPickImage,
    onDeleteImage,
    isCreate,
    form,
    journal,
    journalId,
    publishers,
    submitDisabled,
    files: {
      cover,
      image,
      publisherLogo,
    },
    manager: {
      create: createJournal,
      del: deleteJournal,
      update: updateJournal,
    },
    questions,
    setQuestions,
    recommendationQuestion,
    setRecommendationQuestion,
    setDisclaimers,
    disclaimers,
    previousDisclaimers,

    emails,
    tab,
    setTab,
    handleFocusErrors,
    modulesRefs,
  }

  return <JournalCrudContext.Provider value={providerData}>
    {children}
    <FileInput ref={fileInput.ref} />
  </JournalCrudContext.Provider>
}

export function useJournalCrudContext<SL extends Selector<JournalCrudProviderData, any> = Selector<JournalCrudProviderData, Placeholder>>(
  selector?: SL,
): UseContextType<JournalCrudProviderData, SL> {
  const value = useContextSelector(JournalCrudContext, selector ?? ((value) => value))
  return value
}
