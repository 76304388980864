import { Toaster } from '@/components'
import { ActivityIndicator } from '@codeleap/web'
import { plagiarismErrors, statusData } from '../Publication/Plagiarism/constants'

export function Plagiarism({ status, error_code }) {
  const isReady = status === 'ready'
  const isError = status === 'error'
  const hasError = !!error_code && isError
  const textObject = hasError && plagiarismErrors[error_code] ? plagiarismErrors[error_code] : statusData[status] ?? statusData.generating

  const { title, description, icon } = textObject

  return (
    <Toaster.Component
      LeftContent={() => <ActivityIndicator debugName='PlagiarismChecker:Loader' variants={['small']} />}
      title={title}
      description={description}
      icon={icon}
      variants={[!isReady && 'neutral2', isError && 'error']}
      isVisible
    />
  )
}
