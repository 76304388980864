import { variantProvider } from '@/app'
import { Text, View } from '@/components'
import { PermissionLevels } from '@/types'
import { PermissionUtils } from '@/utils'

export function LevelPrivileges() {
  return (
    <View style={styles.wrapper}>
      {Object.entries(PermissionUtils.levelPrivileges).map(([key, privileges]) => (
        <View key={key} variants={['column', 'fullWidth', 'gap:1']}>
          <Text text={`${PermissionLevels[key]} privileges`} variants={['h5', 'color:neutral8']} />
          {privileges.map((p, index) => <Text key={index} text={p} variants={['p2', 'regular', 'color:neutral8']} />)}
        </View>
      ))}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    ...theme.spacing.gap(2),
  },
}), true)
