/* eslint-disable max-len */
import { TextEditor, View, Text, UpdateCommentParams } from '@/components'
import { React } from '@/app'
import { DecisionComment } from '@/types'
import { FormItemWrapper } from './FormItemWrapper'
import { useMemo } from '@codeleap/common'

export type CommentsFormProps = {
  comments: DecisionComment[]
  publicationId: number
  disabled?: boolean
  updateComment: (comment: UpdateCommentParams) => void
}

export type CommentProps = DecisionComment & Pick<CommentsFormProps, 'publicationId' | 'disabled'> & { position: number; onChange: CommentsFormProps['updateComment'] }
export type EditorCommentProps = { comment: DecisionComment['editor_comment']; disabled?: boolean; onChange: (comment: { newValue: string }) => void }

const Comment = ({ id, comment, editor_comment, reviewer_email, reviewer_name, reviewer_id, disabled, publicationId, position, onChange }: CommentProps) => {
  return (
    <FormItemWrapper
      reviewer={{ reviewer_email, reviewer_name, reviewer_id }}
      publicationId={publicationId}
      position={position}
      isEditor={!!editor_comment}
    >
      <View variants={['row', 'gap:1']}>
        <TextEditor
          textEditorProps={{ toolbarComponent: null, variants: ['reviewOverviewForm', 'multiline', 'flat:disabled'] }}
          disabled={disabled}
          value={comment || editor_comment}
          onChangeValue={({ editor }) => onChange({ id, newValue: editor?.getHTML?.() })}
          placeholder='Text your message here'
        />
      </View>
    </FormItemWrapper>

  )
}

const EditorComment = ({ comment, onChange, disabled }: EditorCommentProps) => {
  return (
    <View variants={['column', 'gap:1']}>
      <Text variants={['p2', 'neutral-8']} text='Editor comment' />
      <View variants={['bg:neutral1', 'border-radius:tiny']}>
        <TextEditor
          placeholder='Text your message here'
          disabled={disabled}
          value={comment || ''}
          onChangeValue={({ editor }) => onChange({ newValue: editor?.getHTML?.() })}
          toolbarComponentProps={{
            excludeIds: ['image', 'link', 'fileComponent'],
            variants: ['reviewCommentModal'],
            hide: disabled,
          }}
          textEditorProps={{
            variants: ['reviewCommentModal', 'multiline', 'flat:disabled', disabled && 'flat'],
          }}
        />
      </View>
    </View>
  )
}

export const CommentsForm = (props: CommentsFormProps) => {
  const {
    comments,
    publicationId,
    disabled,
    updateComment,
  } = props
  const filteredComments = useMemo(() => {
    return comments?.reduce((acc, comment) => {
      if (comment.type === 'reply') {
        acc.currentEditorComment = comment.editor_comment
      } else {
        acc.otherComments.push(comment)
      }
      return acc
    },
    { currentEditorComment: '', otherComments: [] })
  }, [comments?.length])

  return (
    <View variants={['column']}>
      <View variants={['backgroundColor:neutral2', 'column', 'marginBottom:2', 'border-radius:small', 'gap:3', 'padding:2']}>

        {filteredComments?.otherComments?.map((comment, key) => <Comment
          key={key}
          position={key}
          publicationId={publicationId}
          onChange={updateComment}
          disabled={disabled}
          {...comment}
        />)}

        {(disabled && !filteredComments?.currentEditorComment) ? null : (
          <EditorComment
            comment={filteredComments?.currentEditorComment}
            onChange={updateComment}
            disabled={disabled}
          />
        )}
      </View>
    </View>

  )
}
