import { React } from '@/app'
import { Text, View, Button, TextInput } from '..'
import { createForm, useForm } from '@codeleap/common'
import { modal } from '@/utils'

type DeleteAccountModalProps = {
  onDelete: () => void
}

const deleteAccountForm = createForm('deleteAccount', {
  confirmAction: {
    type: 'text',
    label: 'To confirm this action, type "DELETE"',
    placeholder: 'Type here',
    validate: (value: string) => ({ valid: value === 'DELETE', message: 'Please type "DELETE" to confirm' }),
  },
})

export const DeleteAccountModal = modal<DeleteAccountModalProps>({ id: 'deleteAccount', independent: true }).content((props) => {
  const { toggle, request } = props

  const form = useForm(deleteAccountForm, { initialState: {}})

  return (
    <>
      <View variants={['fullWidth', 'alignCenter', 'column', 'gap:2']}>
        <Text text={"We're really sorry to see you go. By deleting your account:"} variants={['p1', 'color:neutral8', 'textCenter']} />
        <View variants={['fullWidth', 'justifyCenter', 'column']}>
          <Text text={'•This will permanently delete all your data'} variants={['p1', 'color:neutral8', 'textCenter']} />
          <Text text={'•This action cannot be undone'} variants={['p1', 'color:neutral8', 'textCenter']} />
        </View>
        <TextInput {...form.register('confirmAction')} />
      </View>
      <View variants={['fullWidth', 'alignCenter', 'marginTop:1', 'gap:2']}>
        <Button
          text={'Cancel'}
          variants={['flat', 'fullWidth', 'large']}
          debugName={'Cancel delete account'}
          onPress={toggle}
        />
        <Button
          text={'Delete'}
          variants={['destructive', 'fullWidth', 'large']}
          debugName={'Delete account'}
          disabled={!form.isValid}
          onPress={request.resolve}
        />
      </View>
    </>
  )
}).props({
  showClose: false,
  dismissOnBackdrop: false,
  closeOnEscape: false,
  title: 'Deleting account',
  variants: ['centered'],
})
