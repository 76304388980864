/* eslint-disable max-len */
import { React, variantProvider } from '@/app'
import { View, Text, Button, UpdateAnswersParams } from '@/components'
import { DecisionAnswer, DecisionFormAnswer } from '@/types'
import { FormItemWrapper } from './FormItemWrapper'
import { useState } from 'react'

export type SubmissionFormProps = {
  formAnswers: DecisionFormAnswer
  publicationId: number
  disabled?: boolean
  updateAnswer: (params: UpdateAnswersParams) => void
}

export type AnswerProps = DecisionAnswer & Omit<SubmissionFormProps, 'updateAnswer' | 'formAnswers'> & { questionId: string; position: number; onChange: SubmissionFormProps['updateAnswer'] }

const answersArray = Array.from({ length: 10 }, (_, i) => i + 1)
answersArray.push(null) // N/A answer - since the value must be null

const Answer = ({ value, id, questionId, review, reviewer_email, reviewer_name, reviewer_id, disabled, publicationId, position, onChange }: AnswerProps) => {
  const [currAnswer, setCurrAnswer] = useState(value)

  const handleUpdate = (vl: number) => {
    onChange({ question: questionId as unknown as number, newValue: vl, review, type: 'form_answers' })
    setCurrAnswer(vl)
  }

  return (
    <FormItemWrapper
      reviewer={{ reviewer_email, reviewer_name, reviewer_id }}
      publicationId={publicationId}
      position={position}
    >
      <View variants={['row', 'gap:1']}>
        {answersArray.map((vl) => {
          const isSelected = vl === currAnswer
          const bgColor = isSelected ? 'backgroundColor:neutral10' : `backgroundColor:neutral1`
          const textColor = isSelected ? 'color:neutral1' : `color:neutral10`
          return <Button
            key={vl}
            debugName={`answer button ${id}-${vl}`}
            variants={['padding:2', 'border-radius:tiny', 'marginBottom:1', 'disabled:bare', bgColor, textColor]}
            text={vl?.toString() || 'N/A'}
            onPress={() => handleUpdate(vl)}
            style={styles.answerButton}
            disabled={disabled}
          />
        })}
      </View>
    </FormItemWrapper>
  )
}

export const SubmissionForm = (props: SubmissionFormProps) => {
  const {
    formAnswers,
    publicationId,
    updateAnswer,
    disabled,
  } = props

  return (
    <View variants={['column']}>
      {Object.entries(formAnswers)?.map?.(([questionId, question]) => (
        <View key={questionId} variants={['backgroundColor:neutral2', 'column', 'marginBottom:2', 'border-radius:small', 'padding:2']}>

          <Text
            dangerouslySetInnerHTML={{ __html: question.question }}
            variants={['h5', 'semiBold', 'marginBottom:3']}
          />

          <View variants={['gap:3', 'fullWidth', 'column']}>
            {question.answers.map((answer, key) => <Answer
              key={key}
              position={key}
              publicationId={publicationId}
              questionId={questionId}
              onChange={updateAnswer}
              disabled={disabled}
              {...answer}
            />)}
          </View>

        </View>
      ),
      )}
    </View>
  )
}

const ITEM_SIZE = 47

const styles = variantProvider.createComponentStyle((theme) => ({
  answerButton: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
  },
}), true)
