import { Editor, EditorEvents, EditorOptions } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import { TextEditor as _TextEditor, TextEditorProps as _TextEditorProps } from '@codeleap/web'
import { TextEditorExtensions, ToolBar } from './Toolbar'
import { ToolBarProps } from './Toolbar/types'
import { forwardRef, useImperativeHandle } from 'react'
import { onUpdate } from '@codeleap/common'
import { useDebouncedEditor } from '@/utils'

type TextEditorProps = {
  textEditorProps?: Partial<_TextEditorProps>
  toolbarComponentProps?: ToolBarProps & { hide?: boolean }
  editorProps?: Partial<EditorOptions>
  value?: string
  forceInitialValue?: boolean
  onChangeValue?: (params: EditorEvents['update']) => void
  placeholder?: string
  disabled?: boolean
}

export type TextEditorRef = Partial<{
  HTML: string
  text:string
  editor: InstanceType<typeof Editor>
}>

export const TextEditor = forwardRef<TextEditorRef, TextEditorProps>((props, ref) => {
  const {
    value = '',
    placeholder = '',
    disabled = false,
    onChangeValue,
    textEditorProps = {},
    toolbarComponentProps = {},
    editorProps = {},
  } = props

  const editor = useDebouncedEditor({
    content: value || '',
    editable: !disabled,
    extensions: [
      ...TextEditorExtensions,
      placeholder ? Placeholder.configure({ placeholder: placeholder, emptyEditorClass: 'placeholder' }) : null,
    ],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: (props) => {
      onChangeValue?.(props)
    },
    ...editorProps,
  })

  onUpdate(() => editor?.setEditable(!disabled), [disabled])

  useImperativeHandle(ref, () => {
    return {
      get HTML() {
        return editor?.getHTML?.()
      },
      get text() {
        return editor?.getText?.()
      },
      get editor() {
        return editor
      },
    }
  }, [Boolean(editor)])

  return (
    <_TextEditor
      // @ts-ignore
      editor={editor}
      toolbarComponent={<ToolBar editor={textEditorProps?.editor || editor} hide={disabled} {...toolbarComponentProps} />}
      variants={disabled ? ['disabledPlain', 'flat', 'bare'] : []}
      {...textEditorProps}
    />
  )
})
