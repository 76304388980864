import { PublicationStatus } from '@/types'
import { I18N, OSAlert, Theme } from '.'

const alerts = {
  resubmitPublicationError: () => OSAlert.error({
    title: 'Error',
    body: 'Error while performing reject and resubmit operation. Please try again later',
  }),
  rejectPublicationError: () => OSAlert.error({
    title: 'Error',
    body: 'Error while performing reject operation. Please try again later',
  }),
  acceptPublicationError: () => OSAlert.error({
    title: 'Error',
    body: 'Error while performing accept operation. Please try again later',
  }),
  withdrawPublicationError: () => OSAlert.error({
    title: 'Error',
    body: 'Error while performing withdraw operation. Please try again later',
  }),
  tooLargeFileError: () => OSAlert.error({
    title: 'File is Too large',
    body: 'File is too large. Maximum size is 256MB.',
  }),
  invalidURLError: () => OSAlert.error({
    title: 'Invalid Domain',
    body: 'URL Domain is already in use',
  }),
  invalidFile: ({ body }) => OSAlert.error({
    title: 'Invalid file',
    body,
  }),
  blindedReviewFilesError: () => OSAlert.error({
    title: 'Error',
    body: 'Error while performing blinded review files operation. Please try again later',
  }),
  reauthenticationError: () => OSAlert.error({
    title: 'There was an error',
    body: 'Could not authenticate. Please check your credentials.',
  }),
  genericError: () => OSAlert.error({
    title: 'Something went wrong',
    body: 'Looks like we’ve hit a problem - sorry about that! We’ll investigate and try to stop it from happening again.',
  }),
  scanFilesError: () => OSAlert.error({
    title: 'Error sending files to scan',
    body: 'Try again later.',
  }),
  emailInUseError: () => OSAlert.error({
    title: 'Email already in use',
    body: 'An account with this email already exists. Please log in using your email and password, or, if you signed up with a social account (e.g., Google, LinkedIn, Apple), click the corresponding logo.',
  }),
  passwordResetError: () => OSAlert.error({
    title: 'Something went wrong',
    body: 'There was an error during password reset',
  }),
  userNotFoundError: () => OSAlert.error({
    title: 'User not found',
    body: 'Could not find an account matching the specified email address',
  }),
  signupError: () => OSAlert.error({
    title: 'Unable to Sign Up',
    body: 'To create an account, please access a journal first.',
  }),
  downloadAvailableError: () => OSAlert.error({
    title: 'Download not available',
    body: 'The file is not available for download yet. Please try again later.',
  }),
  plagiarismViewError: () => OSAlert.error({
    title: 'Viewer not currently available',
    body: 'Please try again later',
  }),
  selectJournalInfo: () => OSAlert.info({
    title: 'No Journal Selected',
    body: 'Please select a journal on the left menu before uploading a publication',
  }),
  newVersionPublicationError: () => OSAlert.error({
    title: 'Error',
    body: 'Error while submitting new manuscript version. Please try again later',
  }),
  onConfirmSubmit: ({ onSubmit }) => OSAlert.ask({
    title: 'Are you sure you want to submit?',
    body: `After submitting the review can't be edited afterwards.`,
    options: [
      { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
      { text: 'Submit', onPress: onSubmit, variants: ['large'] },
    ],
  }),
  handleDelete: ({ onDelete }) => OSAlert.ask({
    title: 'Are you sure you want to delete?',
    body: 'Deleting this journal will result in the loss of all your current work.',
    options: [
      { text: 'Cancel', onPress: () => null, variants: ['flat'] },
      { text: 'Delete', onPress: onDelete, variants: ['destructive'] },
    ],
  }),
  onOpenRejectReplicaModal: ({ onRejectReview }) => OSAlert.ask({
    title: 'Reject manuscript?',
    body: 'By rejecting the manuscript it will not published in the journal and the reviews cycle will end.',
    options: [
      { text: 'Cancel', onPress: () => {}, variants: ['flat'], styles: { text: { color: Theme.colors.neutral10 }}},
      { text: 'Reject', onPress: onRejectReview },
    ],
  }),
  onOpenAcceptReplicaModal: ({ onAcceptReview }) => OSAlert.ask({
    title: 'Accept manuscript?',
    body: 'By accepting the manuscript it will be published in the journal.',
    options: [
      { text: 'Cancel', onPress: () => {}, variants: ['flat'], styles: { text: { color: Theme.colors.neutral10 }}},
      { text: 'Accept', onPress: onAcceptReview },
    ],
  }),
  onConfirmEditorReview: ({ onSubmitEditorReview }) => OSAlert.ask({
    title: 'Send to author revision?',
    body: `The author will receive your feedback and submit a new version of the manuscript for your revision.`,
    options: [
      { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
      { text: 'Send', onPress: onSubmitEditorReview, variants: ['large'] },
    ],
  }),
  onOpenWithdrawModal: ({ onWithdrawPublication }) => OSAlert.ask({
    title: 'Withdraw manuscript?',
    body: 'The submission process will be interrupted and the manuscript will be moved to the archive. You can rescind this decision later.',
    options: [
      { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
      { text: 'Continue', onPress: onWithdrawPublication, variants: ['large'] },
    ],
  }),
  deleteInvite: (props) => OSAlert.warn({
    title: 'Remove Reviewer Invitation',
    body: "Are you sure you want to remove this invitation?\n\n\nThis action will prevent the reviewer from participating in the current version's review process.",
    ...props,
  }),

  onConfirmTransferModal: async ({ onCancel, onAccept }) => OSAlert.ask({
    title: 'Reject and transfer manuscript?',
    body: `Only editors with access to the destination journal will be able to see this manuscript. This action is permanent and can't be undone.`,
    options: [
      { text: 'Cancel', onPress: onCancel, variants: ['flat', 'large'] },
      { text: 'Reject and transfer', onPress: onAccept, variants: ['large', 'bg:alert4'] },
    ],
  }),

  onOpenRescindDecision: ({ status, onPress }) => OSAlert.ask({
    title: 'Rescind decision?',
    body: `The current decision for this manuscript is "${PublicationStatus[status]}". By rescinding it, you'll need to submit a new decision.`,
    options: [
      { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
      { text: 'Yes, rescind', onPress, variants: ['large'] },
    ],
  }),
  handleLogout: ({ onPress }) => OSAlert.ask({
    title: I18N.t('logout'),
    body: I18N.t('logoutModal.subtitle'),
    options: [
      { text: I18N.t('logoutModal.cancel'), onPress: () => null, variants: ['fullWidth', 'flat', 'large'] },
      { text: I18N.t('logout'), onPress, variants: ['fullWidth', 'destructive', 'large'] },
    ],
  }),
  handleDeleteConfirmation: ({ onPress }) => OSAlert.ask({
    title: I18N.t('deleteAccountModal.title2'),
    body: I18N.t('deleteAccountModal.body2'),
    options: [
      { text: I18N.t('deleteAccountModal.cancel'), onPress: () => null, variants: ['fullWidth', 'flat', 'large'] },
      { text: I18N.t('deleteAccountModal.delete'), onPress, variants: ['fullWidth', 'destructive', 'large'] },
    ],
  }),
  onDeleteComment: ({ onCancel, onDelete }) => OSAlert.ask({
    title: 'Are you sure you want to delete this comment?',
    body: `This action can't be undone.`,
    options: [
      { text: 'Cancel', onPress: onCancel, variants: ['flat', 'large'] },
      { text: 'Delete', onPress: onDelete, variants: ['large'] },
    ],
  }),
  toggleReviewsOverview: ({ onCancel, onClose }) => OSAlert.ask({
    title: 'Close and discard changes?',
    body: `By closing this modal you will lose all changes. This action can't be undone.`,
    options: [
      { text: 'Cancel', onPress: onCancel, variants: ['flat', 'large'] },
      { text: 'Close', onPress: onClose, variants: ['large'] },
    ],
  }),
  handleDeletePermission: ({ onRemove }) => OSAlert.ask({
    title: 'Are you sure you want to remove this permission?',
    body: 'Removing this user will result in the loss of all its privileges as part of the Journals.',
    options: [
      { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
      { text: 'Remove', onPress: onRemove, variants: ['large'] },
    ],
  }),
  handleDeletion: (props) => OSAlert.custom({
    type: 'destructive',
    title: 'Are you sure you want to delete?',
    body: 'Deleting this manuscript will result in the loss of all your current work.',
    ...props,
  }),
  confirmPasswordChangeAlert: ({ handlePasswordChange }) => OSAlert.custom({
    type: 'destructive',
    title: I18N.t('changePasswordModal.confirmRequest.title'),
    body: I18N.t('changePasswordModal.confirmRequest.body'),
    options: [
      { text: I18N.t('changePasswordModal.cancel'), variants: ['flat'], onPress: () => null },
      { text: I18N.t('changePasswordModal.confirmRequest.proceed'), variants: ['destructive'], onPress: handlePasswordChange },
    ],
  }),
  checkPasswordChangeEmailAlert: () => OSAlert.custom({
    type: 'info',
    title: I18N.t('changePasswordModal.checkEmail.title'),
    body: I18N.t('changePasswordModal.checkEmail.subtitle'),
  }),
  changePermissionAlert: ({ onAction }) => OSAlert.custom({
    type: 'destructive',
    title: 'Are you sure you want to change your permission?',
    body: 'Doing so will result in losing access to certain areas of the site',
    options: [
      { text: 'Cancel', variants: ['fullWidth', 'flat', 'large'], onPress: () => null },
      { text: 'Yes', variants: ['fullWidth', 'destructive', 'large'], onPress: onAction },
    ],
  }),
  deleteJournalReview: ({ onDelete }) => OSAlert.custom({
    type: 'destructive',
    title: 'Are you sure you want to delete?',
    body: 'Deleting this review will result in the loss of all your current work. ',
    onAction: onDelete,
  }),
}

export function getOsAlert<T extends keyof typeof alerts>(key: T = 'genericError' as T, options?: Parameters<typeof alerts[T]>[0]) {
  return alerts[key](options)
}
