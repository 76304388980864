import { AppStatus } from '@/redux'
import { getFirebase } from '../../firebaseApp'
import { QueryKeys } from '../queryKeys'
import { ERRORS, isEmailAvailable } from './views'
import { getOsAlert, OSAlert } from '@/app'
import { Profile } from '@/types'
import { APIClient } from '@/services'

export const codeLoginErrors = {
  ['auth/wrong-password']: {
    title: 'User not found',
    body: 'Email or password is incorrect',
  },
}

export function useLogin() {
  const profile = APIClient.Session.useEdit()
  const login = async (data:Partial<Profile & { password: string }>) => {
    AppStatus.set('loading')
    try {
      const { email, password, current_journal, current_publisher } = data

      const emailAvailable = await isEmailAvailable(email.toLowerCase())

      if (emailAvailable) {
        getOsAlert('userNotFoundError')

        throw ERRORS.notRegistered
      }
      const firebase = await getFirebase()
      const fbResponse = await firebase.auth().signInWithEmailAndPassword(email.toLowerCase().trim(), password.trim())
      if (current_journal && current_publisher) await profile.editProfile({ id: fbResponse?.user.uid, current_journal, current_publisher })
      QueryKeys.me.refresh()
      AppStatus.set('splash')

      setTimeout(() => {
        AppStatus.authFinished()
      }, 500)

      return true
    } catch (e) {
      AppStatus.set('idle')

      const errorMessage = codeLoginErrors?.[e?.code]

      if (!!errorMessage) {
        OSAlert.error({ title: errorMessage.title, body: errorMessage.body })
      }

      logger.error('Error on login', e, 'Auth')

      return false
    }
  }

  return login
}
