import { api } from '@/app'
import { ArticleCategory, Category, FileCategories, Profile, Publication, PublicationStatus, Region, ScientificTag, SubCategory } from '@/types'
import { PaginationResponse } from '@codeleap/common'
const BASE_URL = 'article/'

export async function retrieveCategory(): Promise<Category[]> {
  const response = await api.get<PaginationResponse<Category>>(`${BASE_URL}category/`)

  return response.data?.results
}
export async function retrieveSubcategory(): Promise<SubCategory[]> {
  const response = await api.get<PaginationResponse<SubCategory>>(`${BASE_URL}subcategory/`)

  return response.data?.results
}
export async function retrieveType(): Promise<FileCategories> {
  const response = await api.get<PaginationResponse<FileCategories>>(`${BASE_URL}file_category/`)
  return response.data
}
export async function retrieveArticleType(title?: string): Promise<ArticleCategory[]> {
  const response = await api.get<PaginationResponse<ArticleCategory>>(`${BASE_URL}type/`, { params: { title }})

  return response.data?.results
}

export async function retrieveRegion(title?: string): Promise<Region[]> {
  const response = await api.get<PaginationResponse<Region>>(`${BASE_URL}region/`, {
    params: { title },
  })

  return response.data?.results
}

export async function retrieveScientificTag(): Promise<ScientificTag[]> {
  const response = await api.get<PaginationResponse<ScientificTag>>(`${BASE_URL}scientifictag/`)

  return response.data?.results
}

export async function breakdownSections(id: Publication['id']) {
  const response = await api.post<Publication>(`${BASE_URL}section_breakdown/`, { article: id })

  return response.data
}

export async function searchReviewers(id: Publication['id']) {
  const response = await api.get<Publication>(`${BASE_URL}search_reviewers/`, { params: { article: id }})

  return response.data
}

export async function assignEditors(article: Publication['id'], editors: Profile['id'][]) {
  const response = await api.post(`${BASE_URL}assign_editor/`, {
    article,
    editors,
  })

  return response.data
}
