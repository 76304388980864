import { variantProvider } from '@/app'
import { ActionIcon, Button, Icon, Text, View } from '@/components'
import { Category } from '@/types'
import { downloadFileFromURL, modal } from '@/utils'

type BlindfoldedFilesModalProps = {
  version: number
  files?: {
    category?: Category['label']
    file: string
    filename: string
    id: number
  }[]
}

export const BlindfoldedFiles = modal<BlindfoldedFilesModalProps>({ id: 'blindfoldedFiles' }).content((props) => {
  const { files = [], version, toggle } = props

  return (
    <View variants={['column']}>
      <View variants={['fullWidth', 'justifySpaceBetween', 'alignCenter']}>
        <Text text={`Blinded review files version ${version}`} variants={['h2']} style={styles.modalTitle}/>
        <ActionIcon debugName={''} variants={['minimal']} name='x' onPress={toggle}/>
      </View>

      {files?.map((file, i) => (
        <View css={styles.fileWrapper} key={`${file?.filename}-${i}`}>
          <Icon name='file' style={styles.icon} debugName={`icon${file?.filename}`} />
          <View variants={['column', 'gap:0.5', 'flex']} style={styles.textWrapper}>
            <Text variants={['p1', 'breakWord']} text={file?.filename} />
            <Text variants={['p3', 'color:neutral7', 'wrap']} text={file?.category} />
          </View>
          <Button
            styles={{ wrapper: styles.buttonWrapper }}
            text='Download'
            icon='download'
            debugName={`download button ${file.filename}`}
            onPress={() => downloadFileFromURL({ file: file.file, name: file?.filename, avoidPopUpBlocker: true })}
          />
        </View>
      ))}
    </View>
  )
}).props({
  styles: { box: {
    maxWidth: 800,
    width: '100%',
  }},
  showClose: false,
})

const MODAL_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    modalTitle: {
      ...theme.spacing.margin(0),
    },
    textWrapper: {
      maxWidth: 500,
      overflow: 'hidden',
    },
    fileWrapper: {
      ...theme.spacing.padding(2),
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(2),
      ...theme.border.neutral3({ width: 1, directions: ['bottom'] }),

      '&:last-of-type': {
        border: 'none',
      },
    },
    icon: {
      ...theme.sized(2.5),
      flexShrink: 0,
    },

    buttonWrapper: {
      height: theme.values.itemHeight.default,
      marginLeft: 'auto',
    },
  }),
  true,
)
