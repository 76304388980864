import { useEffect } from 'react'
import { modal, ModalFlowProps } from '@/utils'
import { Button, Text, View } from '..'
import { APIClient } from '@/services'
import { UploadFilesOutput } from './types'
import { uploadFilesStyles } from './styles'
import { Scanning } from './Scanning'
import { FilesOverview } from './FilesOverview'
import { UploadFooterButton } from './FooterButton'
import { AttachmentWithCategory } from '@/types'

type ScanFilesParams = {
  scanIds?: string[]
  attachments: AttachmentWithCategory[]
  files?: File[]
  confirmOnComplete?: boolean
}

export const ScanFilesModal = modal<ScanFilesParams, UploadFilesOutput>().content((props) => {
  const {
    nextOrToggle,
    previousOrToggle,
    scanIds,
    attachments,
    files,
    request,
    confirmOnComplete = false,
  } = props as ModalFlowProps<'fileUpload', ScanFilesParams, UploadFilesOutput>

  const scan = APIClient.Articles.useCheckFilesScan({
    scanIds,
  })

  const filesToCheck = files ?? attachments?.map?.(att => att.file) ?? []

  useEffect(() => {
    if ((!!attachments?.length || !!files?.length) && !scanIds?.length) {
      scan.startScan(filesToCheck)
    }
  }, [])

  const onNext = () => {
    if (scan.hasInfectedFiles) {
      previousOrToggle()
      return
    }

    const attachmentsValue:AttachmentWithCategory[] = attachments || files.map(f => ({ file: f }))

    if (confirmOnComplete) {
      previousOrToggle({
        scan,
        initialAttachments: attachmentsValue,
      })
      return
    }

    if (request) {
      request.resolve({
        attachments: attachmentsValue,
      })
    } else {
      nextOrToggle({
        attachments: attachmentsValue,
      })
    }

  }

  if (scan.isCompleted) {
    return <View variants={['column', 'fullWidth']}>
      <FilesOverview results={scan.results}/>
      <UploadFooterButton
        onPress={onNext}
        title={'Continue'}
      />
    </View>

  }

  if (scan.isLoading) {
    return <Scanning />
  }

  if (scan.isError) {
    return <View variants={['column', 'fullWidth']}>
      <Text text='There was an error while attempting to check your files'></Text>
      {
        filesToCheck.length ? <Button debugName='Retry' onClick={() => scan.startScan(filesToCheck)} text='Retry'/> : null
      }

    </View>
  }

  return null
})

ScanFilesModal.props({
  title: 'Antivirus and malware verification',
  styles: {
    box: uploadFilesStyles.box,
    title: uploadFilesStyles.title,
  },
  variants: ['centered'],
})
