import { Select } from '@/components'
import { TypeGuards } from '@codeleap/common'
import { SelectProps } from '@codeleap/web'
import { useMemo } from 'react'

function getOption(options, value) {
  const _option = options.find(option => option.value === value)
  return { value: _option?.value, label: _option?.label }
}

type SelectFormProps = SelectProps & {
  fieldName: string
  errorMessage?: string
  useErrorControl?: boolean
}

export const SelectForm = (props: SelectFormProps) => {
  const { value, options, multiple } = props

  const _value = useMemo(() => {
    if (!multiple) return value
    if (TypeGuards.isString(value) || TypeGuards.isNil(value) || TypeGuards.isNil(options)) return []
    return value?.map(v => getOption(options, v))
  }, [value])

  return <Select {...props} value={_value} loadInitialValue />
}
