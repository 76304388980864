import { Dispatch, SetStateAction, useRef } from 'react'
import { onUpdate, useState, deepEqual } from '@codeleap/common'

type UsePreviousParams = {
  setValueOnFirstRender?: boolean
  debug?: boolean
}

export const usePrevious = <T>(value: T, params: UsePreviousParams = {}) => {
  const { setValueOnFirstRender = false, debug = false } = params
  const ref = useRef<T>(setValueOnFirstRender ? value : null)

  if (debug) console.log('usePrevious', { value, ref: ref.current })

  onUpdate(() => {
    ref.current = value
  })
  return ref.current
}

export function useSyncedState<T>(initialState: T, debug = false): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialState)
  const prev = usePrevious(initialState, { setValueOnFirstRender: true, debug })

  onUpdate(() => {
    if (debug) console.log('INSIDE UPDATE', { state, initialState, prev, hasChanges: !deepEqual(initialState, state) })

    if (!deepEqual(initialState, state)) {
      if (debug) console.log('State has been updated')
      setState(initialState)
    }
  }, [deepEqual(initialState, prev)])

  return [state, setState]
}
