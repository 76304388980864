import { createForm } from '@codeleap/common'
import { Validate } from './validate'

export const authorValidationRules = {
  full_name: {
    min: 3,
    max: 30,
  },
  organisation: {
    min: 3,
    max: 250,
  },
  contribution: {
    min: 3,
  },
  order: {
    validate(value) {
      const _value = Number(value)
      const isValid = Number.isInteger(_value) && _value > 0

      let message: string
      if (!isValid) message = 'Value must be an integer number greater than 0'

      return { valid: isValid, message }
    },
  },
}

export const authorForm = createForm('createAuthorManualInvite', {
  full_name: {
    type: 'text',
    label: 'Full name',
    placeholder: 'Enter a name',
    validate: v => Validate.String(v, { min: authorValidationRules.full_name.min, max: authorValidationRules.full_name.max }),
  },
  organisation: {
    type: 'text',
    label: 'Organisation',
    placeholder: 'Enter a organisation',
    validate: v => Validate.String(v || '', { min: authorValidationRules.organisation.min, max: authorValidationRules.organisation.max }),
  },
  email: {
    type: 'text',
    label: 'Email',
    placeholder: 'example@email.co.uk',
    validate: v => Validate.String(v, { isEmail: true }),
  },
  contribution: {
    type: 'text',
    label: 'Contribution',
    placeholder: 'Enter a contribution',
    validate: v => Validate.String(v, { min: authorValidationRules.contribution.min }),
  },
  region: {
    label: 'Country',
    placeholder: 'Select a country',
    type: 'select',
    searchable: true,
    validate: Validate.Select,
  },
  tags: {
    type: 'select',
    label: 'Scientific fields',
    placeholder: 'Search a field',
    multiple: true,
    searchable: true,
    validate: v => Validate.Select(v, { multiple: true }),
  },
  order: {
    type: 'text',
    label: 'Author position Nº',
    placeholder: 'Enter a position number',
    validate: v => authorValidationRules.order.validate(v),
  },
  address: {
    type: 'text',
    label: 'Address',
    placeholder: 'Enter an address',
    validate: v => Validate.String(v),
  },
})
