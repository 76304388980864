import { useCallback, useRef } from 'react'
import { createContext, useContextSelector as _useContextSelector } from 'use-context-selector'
import { getOsAlert } from '@/app'
import { APIClient } from '@/services'
import { useMemo, onUpdate, useUnmount, TypeGuards, AnyFunction } from '@codeleap/common'
import { Placeholder, ProfileRole, PublicationStatus, Selector, UseContextType } from '@/types'
import { Navigation, PublicationUtils, disableScroll, formatFileName } from '@/utils'
import { AppStatus } from '@/redux'
import { PublicationFormProps, TPublicationContext } from './types'
import { SubmitManuscriptAlertModal } from '@/components'
import { ErrorModule, ModuleRef } from '../modules'

type SavePublicationParams = { status?: keyof typeof PublicationStatus; onSuccess?: AnyFunction }
const PublicationFormContext = createContext({} as TPublicationContext)

const f = () => {}
const getLazyRef = () => useRef<ModuleRef>(null)
const getModuleError = (modules: TPublicationContext['modulesRefs']): ErrorModule => {
  for (const module of Object.values(modules)) {
    const validation = module.current.onValidate()

    if (!validation.isValid) {
      return validation
    }
  }
}

export const PublicationFormProvider = (props: PublicationFormProps) => {
  const {
    children,
    authors,
    isAuthor,
    isEditor,
    isPublisher,
    mediaList,
    isLoaded,
    possibleReviewers,
    publication,
    publicationQuery,
    article,
    isNew,
  } = props

  const publications = APIClient.Publications.publicationsManager.useUpdate()

  const modulesRefs: TPublicationContext['modulesRefs'] = {
    coverLetter: getLazyRef(),
    article: getLazyRef(),
    attachments: getLazyRef(),
    authors: getLazyRef(),
    reviewers: getLazyRef(),
    details: getLazyRef(),
    disclaimers: getLazyRef(),
  }

  const cleanPublication = () => Object.values(modulesRefs).forEach(m => m.current?.onClear())

  const { fileName, canAuthorEdit, canEditorEdit, isPublicationArchived, publicationStatus } = useMemo(() => {
    const allowAuthorStatus = ['saved_in_drafts', 'revision_requested']
    const disallowEditorStatus = ['saved_in_drafts', 'accepted', 'rejected', 'rejected_resubmit']
    const archivedStatus = ['accepted', 'rejected', 'rejected_resubmit', 'withdraw']

    return {
      fileName: formatFileName(publication?.file),
      canAuthorEdit: isAuthor && allowAuthorStatus.includes(publication.status),
      canEditorEdit: (isEditor || isPublisher) && !disallowEditorStatus.includes(publication.status),
      isPublicationArchived: archivedStatus.includes(publication.status),
      publicationStatus: PublicationUtils.getPublicationBooleans(publication?.status),
    }
  }, [publication])

  const isSavePublicationEnabled: { [x in ProfileRole]?: boolean } = useMemo(() => {
    const publicationChanged =
      modulesRefs.details.current?.hasChanges ||
      modulesRefs.article.current?.hasChanges ||
      modulesRefs.coverLetter.current?.hasChanges

    return {
      author: true,
      publisher: publicationChanged,
      editor: publicationChanged,
    }
  }, [publicationStatus, modulesRefs])

  onUpdate(() => {
    if ((isEditor || isPublisher) && publicationStatus?.isSaved_in_drafts) {
      Navigation.navigate('Manuscripts.List')
    }
  }, [isEditor, isPublisher, publicationStatus?.isSaved_in_drafts])

  useUnmount(() => {
    cleanPublication()
  })

  const handleGoToReviewers = useCallback(async () => {
    Navigation.navigate('Manuscripts.ReviewersAndInvites', { routeParams: { id: String(publication?.id) }})
  }, [publication])

  const refetchPublication = useCallback(async (refetchAllQueries = false) => {
    const promises: Promise<any>[] = [publicationQuery.refetch().catch(f)]
    if (refetchAllQueries) {
      promises.push(
        possibleReviewers.query.refetch().catch(f),
        authors.query.refetch().catch(f),
        mediaList.query.refetch().catch(f),
        APIClient.Publications.searchReviewers(article).catch(f),
        APIClient.Articles.checkPlagiarismStatus(article).catch(f),
      )
    }
    await Promise.all(promises)
  }, [publicationQuery, possibleReviewers, authors, mediaList])

  const savePublication = useCallback(async (params: SavePublicationParams = {}) => {
    const { status = publication?.status, onSuccess = null } = params

    disableScroll()
    AppStatus.set('loading')

    const publicationFragments = Object.values(modulesRefs)
      .filter(m => m.current?.saveOnPublication)
      .map(m => m.current.onSave())

    const publicationData = Object.assign({ status }, ...publicationFragments)

    try {
      if (isAuthor && publicationStatus?.isSaved_in_drafts) {
        await Promise.all(Object.values(modulesRefs).map(m => m.current?.onSave()))
      }
      await publications.update({ id: publication.id, ...publicationData })

      AppStatus.set('done')

      if (TypeGuards.isFunction(onSuccess)) await onSuccess()

      await refetchPublication(publicationStatus.isSaved_in_drafts)
    } catch (err) {
      AppStatus.set('idle')
      logger.error('Error updating publication', err, 'Publications')
      logger.slack.echo('ERROR - manuscript submittion', { err })
      getOsAlert()
    } finally {
      disableScroll(false)
    }
  }, [publication])

  const updatePublicationStatus = useCallback(async (status: keyof typeof PublicationStatus) => {
    await savePublication({ status })
    return publication
  }, [savePublication])

  const handleSubmitPublication = useCallback(async () => {
    const moduleError = getModuleError(modulesRefs)

    if (!!moduleError && !moduleError?.isValid) {
      setTimeout(() => SubmitManuscriptAlertModal.open(moduleError), 300)
      return
    }

    window.scrollTo(0, 0)
    await savePublication({
      status: publicationStatus.isSaved_in_drafts ? 'submitted_for_review' : 'revision_requested',
      onSuccess: () => publicationStatus.isSaved_in_drafts
        ? Navigation.navigate('Manuscripts.List', { state: { isNew: true, hasChanges: true }})
        : null,
    })
  }, [modulesRefs, publicationStatus])

  const value: TPublicationContext = {
    article,
    fileName,
    isNew,
    ...publicationStatus,
    publication,
    canAuthorEdit,
    canEditorEdit,
    isPublicationEditable: canAuthorEdit || canEditorEdit,
    isSavePublicationEnabled,
    savePublication,
    refetchPublication,
    updatePublicationStatus,
    publicationLoaded: isLoaded,
    handleGoToReviewers,
    isPublicationArchived,
    publicationQuery,
    handleSubmitPublication,
    cleanPublication,

    authors,
    possibleReviewers,
    modulesRefs,
    mediaList,
  }

  return (
    <PublicationFormContext.Provider value={value}>
      {children}
    </PublicationFormContext.Provider>
  )
}

export function usePublicationForm<SL extends Selector<TPublicationContext, any> = Selector<TPublicationContext, Placeholder>>(
  selector?: SL,
): UseContextType<TPublicationContext, SL> {
  const value = _useContextSelector(PublicationFormContext, selector ?? ((value) => value))
  return value
}

export * from './types'
