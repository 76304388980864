import { journalsDisclaimerManager, journalsManager } from '@/services/api/journals'
import { Journal } from '@/types'

type UseLoadJournalsProps = {
  journalId: Journal['id']
}

export const useLoadJournals = (props: UseLoadJournalsProps) => {
  const isCreate = !props?.journalId || props?.journalId?.length < 1

  const journal = !isCreate
    ? journalsManager.useItem({
      id: props?.journalId,
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    })
    : ({} as ReturnType<typeof journalsManager.useItem>)

  const previousDisclaimers = !!props?.journalId
    ? journalsDisclaimerManager.useItem({
      id: props?.journalId,
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    })
    : ({} as ReturnType<typeof journalsDisclaimerManager.useItem>)

  const isLoaded = (journal?.query?.isSuccess && !!journal?.data) &&
    previousDisclaimers?.query?.isSuccess

  return {
    isCreate,
    journal,
    previousDisclaimers,
    isLoaded,
  }
}
