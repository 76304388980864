import { TypeGuards } from '@codeleap/common'
import { format, isValid, parse, subDays } from 'date-fns'

const formatDate = (date: string | Date, formatString: string) => format(TypeGuards.isNumber(date) ? date : new Date(date), formatString)

const subtractDays = (date: string, daysToSub: number) => {
  const parsedDate = parse(date, 'dd/MM/yyyy', new Date())

  const isValidFormat = isValid(parsedDate) && format(parsedDate, 'dd/MM/yyyy') === date

  if (!isValidFormat) {
    return date
  }

  const newDate = subDays(parsedDate, daysToSub)

  const formattedDate = format(newDate, 'dd/MM/yyyy')

  return formattedDate
}

export const DateUtils = {
  formatDate,
  subtractDays,
}
