import { variantProvider } from '@/app'
import { View } from '@/components'

type HiddenProps = {
  elements: JSX.Element[]
}

export const Hidden = (props: HiddenProps) => {

  return <View style={styles.wrapper}>
    {props.elements.map((el, index) => {
      return <View key={index}>{el}</View>
    })}
  </View>
}

const styles = variantProvider.createComponentStyle(() => ({
  wrapper: {
    right: -1600,
    position: 'fixed',
    flexDirection: 'column',
  },
}), true)
