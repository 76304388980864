import { getJournalDomain } from '@/utils'
import { createForm, yup } from '@codeleap/common'
import { JOURNAL_URL_DOMAIN } from '../constants'

export const journalRichTextFieldsMaxLength = 20000

export const emailTemplatesMinLength = 22
export const emailTemplatesMaxLength = 10000

function validateSlug(value: string) {
  if (value === null || value === undefined) {
    return { valid: true, value, message: '' }
  }

  value = getJournalDomain(value)

  value = value.trim().toLowerCase()

  if (value.length < 3) {
    return { valid: false, message: 'Domain name must be at least 3 characters long' }
  }

  if (value.length > 20) {
    return { valid: false, message: 'Domain name must be at most 20 characters long' }
  }

  if (value.includes(' ')) {
    return { valid: false, message: 'Spaces not allowed in domain name' }
  }

  const isAlpha = /^[a-zA-Z0-9]*$/

  if (!isAlpha.test(value)) {
    return { valid: false, message: 'Domain name must be alphanumeric' }
  }

  return { valid: true, value: value, message: '' }
}

export const templates = [
  'reviewer_custom_email',
  'revision_decision_template',
  'accepted_decision_template',
  'rejected_decision_template',
  'rejected_resubmission_decision_template',
  'manuscript_transferred_template',
  'revise_reminder_template',
  'editor_assigment_template',
  'reviews_complete_template',
  'completed_review_template',
  'late_review_reminder_template',
  'review_invite_accepted_template',
  'manuscript_submission_template',
]

export const journalForm = createForm('journal', {
  title: {
    type: 'text',
    label: 'Journal name',
    placeholder: 'Name',
    validate: yup
      .string()
      .required('This is a required field')
      .min(6, 'Minimum of 6 characters')
      .max(256, 'Maximum of 256 characters'),
  },
  description: {
    type: 'text',
    label: 'Description',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .max(journalRichTextFieldsMaxLength)
      .required('This is a required field'),
  },
  image: {
    type: 'file',
  },
  cover: {
    type: 'file',
  },
  publisher_logo: {
    type: 'file',
  },
  url_domain: {
    type: 'text',
    defaultValue: JOURNAL_URL_DOMAIN + 'your_domain',
    label: 'URL Domain',
    placeholder: JOURNAL_URL_DOMAIN + 'your_domain',
    validate: (value) => {
      return validateSlug(value)
    },
  },
  reviewer_instructions: {
    type: 'text',
    label: 'Instructions for Reviewers',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .notRequired()
      .max(journalRichTextFieldsMaxLength),
  },
  author_instructions: {
    type: 'text',
    label: 'Instructions for Authors',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .notRequired()
      .max(journalRichTextFieldsMaxLength),
  },
  about_journal: {
    type: 'text',
    label: 'About the journal',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .notRequired()
      .max(journalRichTextFieldsMaxLength),
  },
  contact_info: {
    type: 'text',
    label: 'Contact information',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .notRequired()
      .max(journalRichTextFieldsMaxLength),
  },
  issn: {
    label: 'ISSN',
    type: 'text',
    masking: {
      type: 'custom',
      options: {
        maskChar: '_',
        mask: '0000-0000',
        placeholder: '____-____',
        formatChars: {
          '0': '[0123456789]',
        },
      },
    },
    validate: (vl) => {
      const value = vl.replace(/[-_]/g, '')
      return { valid: value.length === 0 || value.length === 8, message: `Minimum of 8 characters` }
    },
  },
  aims_scope: {
    type: 'text',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .notRequired()
      .max(journalRichTextFieldsMaxLength),
  },
  editorial_board: {
    type: 'text',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .notRequired()
      .max(journalRichTextFieldsMaxLength),
  },
  portal_footer: {
    type: 'text',
    placeholder: 'Description',
    multiline: true,
    validate: yup
      .string()
      .notRequired()
      .max(journalRichTextFieldsMaxLength),
  },
  requires_blindfolded_review: {
    type: 'switch',
    label: null,
    defaultValue: false,
  },
  ...templates.reduce((acc, template) => {
    acc[template] = {
      type: 'text',
      placeholder: 'Description',
      multiline: true,
      validate: yup
        .string()
        .notRequired()
        .min(emailTemplatesMinLength, `Minimum of ${emailTemplatesMinLength} characters`)
        .max(emailTemplatesMaxLength),
    }
    return acc
  }, {}),
})

