import { AppForms, React } from '@/app'
import { TextInput, usePublicationForm } from '@/components'
import { PublicationUtils, useMemoizedForm } from '@/utils'
import { deepEqual } from '@codeleap/common'
import { useCallback, useMemo } from 'react'

export const DetailsForm = () => {
  const isPublicationEditable = usePublicationForm((v) => v.isPublicationEditable)
  const publication = usePublicationForm((v) => v.publication)
  const ref = usePublicationForm((v) => v.modulesRefs.details)

  const initialState = useMemo(() => ({
    conflicts_of_interest: publication?.conflicts_of_interest,
    consent: publication?.consent,
    data_availability_statement: publication?.data_availability_statement,
    ethical_approval: publication?.ethical_approval,
    funding_source: publication?.funding_source,
    guarantor: publication?.guarantor,
    provenance_peer_review: publication?.provenance_peer_review,
  }), [publication])

  const form = useMemoizedForm(AppForms.editPublicationDetails, {
    validateOn: 'change',
    initialState,
  })

  const onClear = () => form.setFormValues({
    provenance_peer_review: '',
    conflicts_of_interest: '',
    consent: '',
    data_availability_statement: '',
    ethical_approval: '',
    funding_source: '',
    guarantor: '',
  })

  const onSave = () => form.values
  const onValidate = () => ({ isValid: form.isValid, anchor: PublicationUtils.fieldAnchors.detailFields })

  React.useImperativeHandle(ref, () => ({
    onValidate,
    onSave,
    onClear,
    showError: () => form.validateAll(true),
    saveOnPublication: true,
    hasChanges: !deepEqual(form.values, initialState),
  }))

  const handleForm = useCallback((field) => {
    const fieldFormError = form.fieldErrors[field]
    const fieldValidation = form.validateField(field)

    return {
      ...form.register(field),
      _error: fieldFormError && !fieldValidation.valid ? fieldValidation.message ?? fieldFormError : '',
      disabled: !isPublicationEditable,
    }
  }, [form])

  return (
    <>
      <TextInput {...handleForm('ethical_approval')} />
      <TextInput {...handleForm('consent')} />
      <TextInput {...handleForm('funding_source')} />
      <TextInput {...handleForm('conflicts_of_interest')} />
      <TextInput {...handleForm('guarantor')} />
      <TextInput {...handleForm('provenance_peer_review')} />
      <TextInput {...handleForm('data_availability_statement')} />
    </>
  )
}
