import { View, TextInput, Text } from '@/components'
import { APIClient } from '@/services'
import { Publication } from '@/types'
import { PublicationUtils } from '@/utils'

type SectionsProps = {
  publication: Publication
  disabled: boolean
  sections: any
  setSections: any
}

export const Sections = ({ publication, disabled, sections, setSections }: SectionsProps) => {
  const { isEditor, isPublisher } = APIClient.Session.useSession()

  const sectionBreakdown = PublicationUtils.sortSections(publication)

  const onValueChange = (key: string, vl: string) => {
    setSections((prev) => ({ ...prev, [key]: vl }))
  }

  return (
    <View variants={['flex', 'gap:1', 'column', 'marginTop:1']}>
      <Text variants={['p2', 'semiBold', 'color:neutral7']} text='Section Breakdown' />
      <View
        variants={[
          'fullWidth',
          'column',
          'gap:2',
          'paddingHorizontal:2',
          'paddingVertical:1',
          'border-radius:tiny',
          `border-color:${isEditor || isPublisher ? 'neutral2' : 'neutral5'}`,
          'border-width:small',
          'border-style:solid',
        ]}
      >
        {sectionBreakdown?.breakdown?.length &&
          sectionBreakdown.breakdown?.map((section) => (
            <TextInput
              key={section[0]}
              label={section[0]}
              value={sections[section[0]]}
              onChangeText={(t) => onValueChange(section[0], t)}
              multiline
              required={false}
              debugName={'Manuscript section: ' + section[0]}
              variants={['noError', 'fullWidth', 'publicationSections']}
              disabled={disabled}
            />
          ))}
      </View>
    </View>
  )
}
