import { AppForms, variantProvider } from '@/app'
import { View, TextInput, usePublicationForm, SelectForm } from '@/components'
import { APIClient } from '@/services'
import { PublicationUtils, useMemoizedForm } from '@/utils'
import { deepEqual, TypeGuards, useCallback, useImperativeHandle, useMemo, useState } from '@codeleap/common'
import { Sections } from './Sections'

export const ArticleForm = () => {
  const isPublicationEditable = usePublicationForm(v => v.isPublicationEditable)
  const canAuthorEdit = usePublicationForm(v => v.canAuthorEdit)
  const inDrafts = usePublicationForm(v => v.isSaved_in_drafts)
  const publication = usePublicationForm(v => v.publication)
  const ref = usePublicationForm(v => v.modulesRefs.article)

  const { categories, subcategories, regions, article_types } = APIClient.Session.useSession()

  const initialState = useMemo(() => ({
    title: publication?.title,
    short_title: publication?.short_title,
    region: publication?.region,
    article_type: publication?.article_type?.id,
    category: publication?.category,
    subcategory: publication?.subcategory,
    keywords: TypeGuards.isArray(publication.keywords) ? publication.keywords.join(', ') : '',
  }), [publication])

  const form = useMemoizedForm(AppForms.editPublication, { validateOn: 'change', initialState })

  const handleKeywords = useCallback(() => {
    const { keywords = '' } = form.values
    return Array.from(new Set(keywords.split(/[,.;_/\\|]/).map(i => i.trim()))).sort()
  }, [publication])

  const subcategoriesFiltered = useMemo(() => {
    return subcategories ? subcategories.filter(s => s?.category === form.values?.category) : []
  }, [form.values?.category, subcategories])

  const [sections, setSections] = useState(() => {
    if (publication?.sections) {
      return Object.fromEntries(Object.entries(publication.sections).map(([k, s]) => [k, s.text]))
    }

    return {}
  })

  const onCategoryChange = (category: number) => {
    form.setFormValues({
      ...form.values,
      category,
      subcategory: category !== form.values.category ? null : form.values.subcategory,
    })
  }

  const onClear = () => {
    setSections({})
    form.setFormValues({
      title: '',
      short_title: '',
      region: null,
      article_type: null,
      category: null,
      subcategory: null,
      keywords: '',
    })
  }

  const onSave = () => {
    const keywords = handleKeywords()
    const updatedSections = Object.keys(sections).reduce((acc, item) => ({
      ...acc,
      [item]: { ...publication.sections[item], text: sections[item] },
    }), {})

    const publicationFormData = {
      ...form.values,
      keywords: keywords,
      title: form.values.title?.trim(),
      short_title: form.values.short_title?.trim(),
      sections: updatedSections,
    }

    return PublicationUtils.verifyPublicationChanges(publication, publicationFormData)
  }

  const onValidate = () => {
    const hasSubcategories = subcategoriesFiltered?.length >= 1
    const subcategoryValid = hasSubcategories ? form?.values?.subcategory !== null : true
    const fieldsToValidate = ['title', 'short_title', 'keywords', 'region', 'article_type', 'category']

    return {
      isValid: form?.validateMultiple?.(fieldsToValidate)?.valid && subcategoryValid,
      anchor: PublicationUtils.fieldAnchors.publicationFields,
    }
  }

  useImperativeHandle(ref, () => ({
    onValidate,
    onSave,
    onClear,
    showError: () => form.validateAll(true),
    hasChanges: !deepEqual(form.values, initialState),
    saveOnPublication: true,
  }))

  const handleForm = useCallback(field => {
    const fieldFormError = form.fieldErrors[field]
    const fieldValidation = form.validateField(field)

    return {
      ...form.register(field),
      disabled: !isPublicationEditable,
      _error: fieldFormError && !fieldValidation.valid ? fieldValidation.message ?? fieldFormError : '',
    }
  }, [form])

  return (
    <View variants={['gap:2', 'column']}>
      <TextInput {...handleForm('title')} />
      <TextInput {...handleForm('short_title')} />
      <View style={styles.grid}>
        <SelectForm {...handleForm('region')} options={regions} />
        <SelectForm {...handleForm('article_type')} options={article_types} />
        <SelectForm {...handleForm('category')} options={categories} onValueChange={e => onCategoryChange(e)} />
        {subcategoriesFiltered?.length ? (
          <SelectForm
            {...handleForm('subcategory')}
            placeholder={'Select a subcategory'}
            loadingMessage='Select a subcategory'
            options={subcategoriesFiltered}
          />
        ) : null}
      </View>

      <TextInput
        {...handleForm('keywords')}
        disabled={!isPublicationEditable || !inDrafts}
        variants={['fullWidth', 'resizable:multiline']}
      />

      <Sections
        publication={publication}
        disabled={!canAuthorEdit || !inDrafts}
        sections={sections}
        setSections={setSections}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    grid: {
      display: 'grid',
      // prevent grid from overflowing horizontally when large texts are typed on the text input
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      columnGap: theme.spacing.value(5),
      rowGap: theme.spacing.value(3),
      [theme.media.down('small')]: {
        // prevent grid from overflowing horizontally when large texts are typed on the text input
        gridTemplateColumns: 'minmax(0, 1fr)',
      },
    },
  }),
  true,
)
