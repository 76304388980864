import { variantProvider } from '@/app'
import { ViewComposition, ViewPresets } from '@codeleap/web'

const createViewStyle = variantProvider.createVariantFactory<ViewComposition>()

export const AppViewStyles = {
  ...ViewPresets,
  default: createViewStyle((t) => ({
    wrapper: {
      display: 'flex',
    },
  })),
  border: createViewStyle((theme) => ({
    wrapper: {
      width: '100%',
      border: 'solid',
      borderWidth: 0.5,
      borderColor: theme.colors.neutral5,
      borderRadius: theme.borderRadius.small,
    },
  })),
  thinSeparator: createViewStyle((theme) => ({
    wrapper: {
      width: '100%',
      height: 1,
      backgroundColor: theme.colors.neutral5,

      [theme.media.down('mid')]: {
        height: 4,
      },
    },
  })),
  separator: createViewStyle((theme) => ({
    wrapper: {
      width: '100%',
      height: 2,
      backgroundColor: theme.colors.neutral5,

      [theme.media.down('mid')]: {
        height: 4,
      },
    },
  })),
  dot: createViewStyle((theme) => ({
    wrapper: {
      width: theme.spacing.value(0.5),
      height: theme.spacing.value(0.5),
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.neutral8,
    },
  })),
  borderBottom: createViewStyle((theme) => ({
    wrapper: {
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.neutral3,
    },
  })),
  'height:default': createViewStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
    },
  })),
  'display:none': createViewStyle((theme) => ({
    wrapper: {
      display: 'none',
    },
  })),
  'blindfoldedModalItemWrapper': createViewStyle((theme) => ({
    wrapper: {
      minHeight: theme.values.itemHeight.default,
      height: 'auto',
      borderRadius: theme.borderRadius.tiny,
      ...theme.spacing.paddingVertical(1),
      ...theme.spacing.paddingLeft(2),
      ...theme.spacing.marginBottom(1),
      ...theme.presets.alignCenter,
      ...theme.presets.fullWidth,
    },
  })),
}
