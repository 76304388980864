import { api, getOsAlert } from '@/app'
import { Publication, PublicationPlagiarism } from '@/types/publication'
import { ReactQuery, useState } from '@codeleap/common'

const BASE_URL = '/article/similarity_report_status/'

export const checkPlagiarismStatus = async (id: Publication['id']) => {
  try {
    const response = await api.get<PublicationPlagiarism>(BASE_URL, {
      params: {
        article: id,
      },
    })

    return response.data
  } catch (e) {
    return {} as PublicationPlagiarism
  }
}

export const getPlagiarismReport = async (id: Publication['id']) => {
  try {
    const response = await api.get<{ url: string }>('/article/similarity_report/', {
      params: {
        article: id,
      },
    })

    return response.data
  } catch (e) {
    return {} as { url: string }
  }
}

export const getSubmissionTerms = async () => {
  try {
    const response = await api.get<{ url: string }>('/article/turnitin_eula/')
    return response.data
  } catch (e) {
    return {} as { url: string }
  }
}

export type UseCheckPlagiarismProps = {
  id: Publication['id']
  enable?: boolean
  checkInterval?: number
}

export const useCheckPlagiarism = ({ id, enable = true, checkInterval = 15000 }: UseCheckPlagiarismProps) => {
  const enablePolling = !!id && enable
  const [loading, setLoading] = useState(false)
  const query = ReactQuery.useQuery({
    queryKey: ['checkPlagiarism', id],
    enabled: enablePolling,
    queryFn: () => checkPlagiarismStatus(id),
    refetchInterval: checkInterval,
  })

  const check = (query.data ?? {}) as PublicationPlagiarism

  const isReady = check?.status === 'ready'

  // const reportLinkQuery = ReactQuery.useQuery({
  //   queryKey: ['getPlagiarismReport', id],
  //   enabled: enablePolling && isReady,
  //   queryFn: () => getPlagiarismReport(id),
  //   retry: 3,
  // })

  // const reportLink = reportLinkQuery.data?.url

  const onViewReport = async () => {
    setLoading(true)
    const win = window.open()
    try {
      const url = (await getPlagiarismReport(id)).url

      win.location = url

    } catch (e) {
      console.error(e)
      getOsAlert('plagiarismViewError')
      win.close()
    }
    setLoading(false)
  }

  return {
    ...check,

    onViewReport,
    reportLoading: !isReady || loading,
  }
}
