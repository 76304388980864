import React, { useCallback, useMemo, useRef, useState } from 'react'
import { modal, ModalFlowProps, useDerivedState, useFileCategoryOptions } from '@/utils'
import { DropzoneInnerFilePreviewProps, DropzoneProps, DropzoneRef } from '@codeleap/web'
import { Dropzone, Text, View } from '..'
import { APIClient } from '@/services'
import { UploadFilesOutput } from './types'
import { FilePreview } from '../Publication/Forms/FilePreview'
import { FormTypes, TypeGuards } from '@codeleap/common'
import { uploadFilesStyles } from './styles'
import { UploadFooterButton } from './FooterButton'
import { Attachment, AttachmentWithCategory } from '@/types'

type UploadFilesParams = {
  selectionLimit?: number
  withCategory?: boolean
  categoryFilters?: Parameters<typeof useFileCategoryOptions>[0]
  accept?: DropzoneProps['accept']
  validate?: FormTypes.ValidatorFunctionWithoutForm<AttachmentWithCategory[]>
  description?: string
  initialAttachments?: (AttachmentWithCategory | Attachment)[]
  initialFiles?: File[]
  withConfirmation?: boolean
  scan?: ReturnType<typeof APIClient.Articles.useCheckFilesScan>
  showErrors?: boolean
}

const attachmentsToFiles = (attachments:AttachmentWithCategory[]) => attachments.map(a => a.file)
const filesToAttachments = (files:File[], currentAttachments: AttachmentWithCategory[]):AttachmentWithCategory[] => {
  return files.map((f, index) => ({ file: f, file_category: currentAttachments?.[index]?.file_category }))

}
const ValidationMessage = ({ validator }) => {
  if (TypeGuards.isArray(validator?.message)) {
    return <View variants={['column', 'marginTop:0.5', 'gap:1']}>
      {validator.message.map((message) => (
        <Text variants={['p3', 'color:destructive2', 'textCenter']} text={message} />
      ))}
    </View>
  }

  return <Text variants={['p3', 'color:destructive2', 'textCenter']} text={validator?.message} />
}

const areAttachmentsEqual = (a: AttachmentWithCategory[], b: AttachmentWithCategory[]) => {
  if (a.length !== b.length) return false

  return a.every((att, idx) => {
    return att.file === b[idx].file && att.file_category?.value === b[idx].file_category?.value
  })
}

export const UploadFilesModal = modal<UploadFilesParams, UploadFilesOutput>().content((props) => {
  const {
    nextOrToggle,
    request,
    flow,
    selectionLimit = 1,
    withCategory,
    categoryFilters,
    accept,
    validate,
    description = null,
    initialAttachments = [],
    initialFiles = [],
    withConfirmation = false,
    showErrors: _showErrors = false,
    scan,
  } = props as ModalFlowProps<'fileUpload', UploadFilesParams, UploadFilesOutput>

  const file_categories = useFileCategoryOptions(categoryFilters)

  const initialState = useMemo(() => {
    if (initialAttachments) {
      return initialAttachments.map(a => {
        if (TypeGuards.isNumber(a.file_category)) {
          return {
            file: a.file,
            file_category: file_categories.find(fc => fc.value === a.file_category),
          }
        }

        return a as AttachmentWithCategory
      })
    }

    if (initialFiles) {
      return filesToAttachments(initialFiles, [])
    }

    return []
  }, [])

  const attachmentState = useState<AttachmentWithCategory[]>(initialState)

  const [showErrors, setShowErrors] = useState(_showErrors)

  const [acceptedFiles, setAcceptedFiles] = useDerivedState(attachmentState, {
    derive: attachmentsToFiles,
    toOriginal: filesToAttachments,
  })

  UploadFilesModal.useProps({
    description: description ? <Text variants={[`p1`]} text={description} /> : null,
  }, [description])

  const [attachments, setAttachments] = attachmentState

  const multiple = selectionLimit > 1

  const attachmentsChanged = useMemo(() => {

    return !areAttachmentsEqual(attachments, initialState)
  }, [attachments])

  const validation = validate ? validate?.(attachments) : { message: '', valid: true }

  const onContinue = async () => {
    if (!validation.valid) {
      setShowErrors(true)
      return
    }

    if (!flow && request) {
      request.resolve({

        attachments,
      })
      return
    }

    if (attachmentsChanged) {
      nextOrToggle({
        attachments,
        confirmOnComplete: withConfirmation,
      })
    } else {
      request.resolve({
        attachments,
      })
    }

  }

  const dropzoneRef = useRef<DropzoneRef>(null)

  const filePreview = useCallback((props: DropzoneInnerFilePreviewProps) => {
    const attachment = attachments[props?.index]
    return (
      <FilePreview

        onChangeCategory={c => {
          setAttachments(prev => {
            const newAttachments = [...prev]
            newAttachments[props.index].file_category = c
            return newAttachments
          })
        }}
        category={attachment?.file_category?.value}
        file={props.file}
        // setAttachments={(action) => {
        //   setInternalFiles(prev => action(prev))
        //   validateOnCategoryChange()
        // }}
        onRemove={() => {
          setAttachments((prev) => {
            const newAttachments = [...prev]
            newAttachments.splice(props.index, 1)
            return newAttachments
          })
        }}
        fileCategories={file_categories}
        variants={['ellipsis', 'media']}
      />
    )
  }, [attachments, setAttachments])

  const hasFiles = !!acceptedFiles.length

  const selectFileButton = <UploadFooterButton
    title='Select file'
    onPress={() => dropzoneRef.current?.open()}
  />

  const continueButton = <UploadFooterButton
    title='Continue'
    onPress={onContinue}
    disabled={showErrors && !validation.valid}
  />

  let button = null

  if (!hasFiles) {
    button = selectFileButton
  } else {
    button = continueButton
  }

  return <>
    <View variants={['column']} id='dropzone_wrapper'>
      <Dropzone
        ref={dropzoneRef}
        icon={'add-file'}
        withImagePreview={false}
        acceptedFiles={acceptedFiles}
        setAcceptedFiles={setAcceptedFiles}
        multiple={multiple}
        placeholder={`Please choose a file for upload, or simply drag and drop it here.`}
        variants={withCategory ? ['ellipsis', 'media'] : []}
        FilePreviewComponent={withCategory ? filePreview : undefined}
        accept={accept}
      />
      {hasFiles && showErrors ? <ValidationMessage validator={validation} /> : null}
      {button}
    </View>
  </>
})

UploadFilesModal.props({
  title: 'Upload files',
  variants: ['centered'],
  styles: {
    box: uploadFilesStyles.box,
    title: uploadFilesStyles.title,
  },
})

