import { reviewerValidationRules } from '@/app/forms'
import { PossibleReviewer } from '@/types'
import { PublicationUtils } from '@/utils'
import { deepEqual, TypeGuards } from '@codeleap/common'

export function hasReviewersChanges(forms: Partial<PossibleReviewer>[], initialState: Partial<PossibleReviewer>[]): boolean {
  if (forms.length !== initialState.length) return true

  return !deepEqual(forms, initialState)
}

export function isReviewersValid(forms: Partial<PossibleReviewer>[]): boolean {
  return forms.every((reviewer) => {
    return (
      reviewer?.full_name?.trim().length >= reviewerValidationRules.full_name.min &&
      PublicationUtils.validateEmail(reviewer?.email?.trim()) &&
      reviewer?.organisation?.trim().length >= reviewerValidationRules.organisation.min &&
      TypeGuards.isArray(reviewer?.tags) &&
      reviewer?.tags.length > 0
    )
  })
}

export function isReviewersUnique(forms: Partial<PossibleReviewer>[]): boolean {
  const emails = forms.map(({ email }) => email?.toLowerCase())
  const uniqueEmails = [...new Set(emails)]

  return uniqueEmails.length === emails.length
}
