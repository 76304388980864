import { modalFlow } from '@/utils'
import { InviteReviewerModal } from './InviteReviewer'
import { PreviewInviteReviewerModal } from './PreviewInviteReviewer'

export const InviteReviewersFlow = modalFlow([
  InviteReviewerModal,
  PreviewInviteReviewerModal,
])

// export const AuthFlow = modalFlow([
//   SignupModal,
//   LoginModal,
//   ForgotPasswordModal,
// ])
