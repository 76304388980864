import { modal, useSorter, useSortModal } from '@/utils'
import React, { useState } from 'react'
import { Content, Item } from './components'
import { FilterDataItem, SortModalProps } from './Sort'
import { useRegions } from '@/services/api/session'
import { TypeGuards } from '@codeleap/common'
import { SearchInput } from '@codeleap/web'

export type SearchRegionModalProps = SortModalProps & React.PropsWithChildren<{}>

export const SearchRegionModal = modal<SearchRegionModalProps, FilterDataItem[]>().content((props) => {
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { data: regions } = useRegions(search || '')

  const _regions = TypeGuards.isNil(regions) ? [] : regions
  const sortParams = useSortModal({ options: _regions, initialState: props.initialState, request: props.request, limit: props.limit })
  const hasSelectedItems = sortParams?.handle?.selectedItems[0]?.length > 0

  const header = <SearchInput
    debugName='Dashboard SearchRegion:SearchInput'
    placeholder='Search a country'
    onSearchChange={setSearch}
    debounce={800}
    clearable
    onTypingChange={(loading) => {
      setIsLoading(loading)
    }}
  />

  return <>
    <Content
      RenderItem={Item}
      headerContent={
        header
      }
      confirmDisabled={!hasSelectedItems}
      isLoading={isLoading}
      {...sortParams}
      {...props}
    />
  </>
})

export const useCountrySorter = () => {
  const regions = useRegions()

  const sorter = useSorter({
    key: 'DASHBOARD_SORT_COUNTRY',
    options: regions?.data,
    modal: SearchRegionModal,
    limit: Infinity,
    hasScroll: true,
    enableLocalStorage: false,
  })

  return {
    sorter,
  }
}

SearchRegionModal.props({
  variants: ['sectionFiltersModal', 'centered'],
  title: 'Select a country',
})

