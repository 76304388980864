import { AnyFunction, PropsOf } from '@codeleap/common'
import { Checkbox, Text, Touchable, View } from '.'

type JournalRecommendationInputProps = {
    label: string
    disabled: boolean
    onPress: () => void
    value: boolean
    checkboxProps?: PropsOf<typeof Checkbox>
}

export const JournalRecommendationInput = (props: JournalRecommendationInputProps) => {

  const {
    onPress,
    label,
    value,
    disabled,
    checkboxProps,
  } = props

  let bgColor = null
  let textColor = 'neutral8'
  let selected = null

  if (label === 'Accept') {
    bgColor = 'positive2'
    textColor = 'neutral1'
    selected = 'positive'
  } else if (label === 'Revise') {
    bgColor = 'warning3'
    textColor = 'neutral1'
    selected = 'neutral'
  } else if (label === 'Reject') {
    bgColor = 'destructive2'
    textColor = 'neutral1'
    selected = 'negative'
  }

  const labelBGColor = `backgroundColor:${bgColor}`
  const labelTextColor = `color:${textColor}`

  const selectedColor = `selected:${selected}`

  return (
    <View variants={['row', 'marginBottom:1']} >
      <Checkbox
        value={value}
        onValueChange={onPress}
        debugName={`Reviewer questions form - ${label} checkbox `}
        variants={['modal', selectedColor as any]}
        disabled={disabled}
        {...checkboxProps}
      />
      <Touchable
        onPress={onPress}
        variants={[labelBGColor as any, 'paddingHorizontal:0.625', 'paddingBottom:0.375', 'marginLeft:1', 'border-radius:nano']}
        debugName={`Checkbox onpress - item ${label}`}
        disabled={disabled}
        style={{ cursor: disabled && 'not-allowed' }}
      >
        <Text variants={[`p3`, 'color:neutral7', 'marginTop:0.2', labelTextColor as any]} text={label}/>
      </Touchable>
    </View>
  )
}
