import { React, getOsAlert, variantProvider } from '@/app'
import { AppStatus } from '@/redux'
import { IconProps, SectionFilters, SectionFiltersProps } from '@codeleap/web'
import { useMemo, useState } from 'react'
import { ActionIcon, ReviewsOverview, Text, Toaster, View, usePublicationForm } from '..'
import { APIClient } from '@/services'
import { modal } from '@/utils'

export const RejectAndTransferManuscriptModal = modal({ independent: true }).content((props) => {
  const { toggle } = props

  const refetchPublication = usePublicationForm(v => v.refetchPublication)
  const publication = usePublicationForm(v => v.publication)
  const authors = usePublicationForm(v => v.authors)

  const publicationAuthor = authors.items[0]?.full_name

  const allJournals = APIClient.Journals.journalsManager.useList({ filter: { limit: 100 }}) // add infinity scroll

  const historyList = APIClient.Publications.publicationHistoryManager.useList({
    filter: {
      article: publication?.id,
    },
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: false,
    },
  })

  const [journal, setJournal] = useState([])

  const data = useMemo(() => {
    const journals: SectionFiltersProps['data'] = [{
      id: 'journals-list',
      options: [],
      selectedItemProps: { rightIcon: 'check' as IconProps['name'] },
    }]
    allJournals?.items?.map?.(journal => {
      if (journal?.id !== publication?.journal?.id) {
        journals[0]?.options?.push({
          value: journal?.id,
          label: journal?.title,
        })
      }
    })
    return !journals[0]?.options?.length ? [] : journals
  }, [allJournals])

  const selectedJournal = journal?.['journals-list']

  const hasJournalsToTransfer = data[0]?.options?.length

  const onCancelTransfer = () => {
    toggle()
    setJournal([])
    ReviewsOverview.open()
  }

  const onTransferManuscript = async () => {
    AppStatus.set('loading')

    try {
      const response = await APIClient.Publications.onRejectAndTransferManuscript({ article: publication?.id, journal: selectedJournal?.value })
      if (response) {
        await refetchPublication()
        await historyList.query.refetch()
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      getOsAlert()
    }
  }

  return (
    <>

      <Text variants={['h3', 'color:neutral9']} text={`Reject and transfer manuscript`}/>

      <Toaster.Component
        title={publication?.title}
        description={publicationAuthor}
        variants={['status', 'bordered', 'marginVertical:4']}
        isVisible={true}
      />

      <View variants={['column']}>
        <Text variants={['p2', 'semiBold']} text={'Current journal:'}/>
        <Text variants={['p1', 'color:neutral8']} text={publication?.journal?.title}/>
      </View>

      <View variants={['marginTop:4', 'gap:1', 'column', 'fullWidth']}>
        <Text variants={['p2', 'semiBold']} text={`Transfer to:`}/>

        {hasJournalsToTransfer ? (
          <SectionFilters
            toggle={toggle}
            title='Sorting'
            applyButtonText='Transfer'
            clearButtonText='Cancel'
            variants={['transferManuscriptFilter']}
            filterOnOptionPress={true}
            draftItems={journal}
            setDraftItems={setJournal}
            onApplyItems={toggle}
            onClearItems={toggle}
            data={data}
            clearFilterButtonProps={{
              disabled: false,
              onPress: onCancelTransfer,
              variants: ['large', 'text:semiBold', 'text:neutral10'],
            }}
            applyFilterButtonProps={{
              onPress: () => {
                toggle()
                getOsAlert(
                  'onConfirmTransferModal',
                  { toggle, onAccept: onTransferManuscript, onCancel: RejectAndTransferManuscriptModal.open },
                )
              },
              variants: ['large', 'border-radius:tiny', 'noWrap', 'bg:alert4', 'text:semiBold'],
              text: 'Reject and transfer',
              disabled: !selectedJournal,
              icon: 'x-circle',
            }}
          />
        ) : (
          <View variants={['padding:2', 'column', 'center', 'border-radius:tiny']} style={styles.emptyPlaceholderWrapper}>
            <ActionIcon
              icon='clipboard'
              variants={['backgroundColor:neutral2', 'border-radius:rounded', 'neutral9', 'size:3']}
              debugName={'Journal Disclaimers - List empty placeholder icon'}
              onPress={null}
            />
            <Text
              variants={[`p1`, 'marginTop:2', 'color:neutral7']}
              responsiveVariants={{ mid: ['textCenter'] }}
              text={`There are no available journals to transfer.`}
            />
          </View>
        ) }
      </View>
    </>

  )
}).props({ styles: { box: { maxWidth: 750, width: 750 }}})

const styles = variantProvider.createComponentStyle((theme) => ({
  emptyPlaceholderWrapper: {
    minHeight: 200,
    ...theme.border.neutral3({ width: theme.values.borderWidth.small }),
    ...theme.spacing.marginBottom(4),
  },
}), true)
