import { SectionFilters, View, SectionFilterItemProps, useSectionFilters, TSectionFilterItem, Button } from '@/components'
import { FilterDataItem } from '../Sort'
import { ActivityIndicator, SearchInputProps } from '@codeleap/web'
import { React, variantProvider } from '@/app'

type ContentProps<T = TSectionFilterItem> = {
  RenderItem: (props: SectionFilterItemProps<FilterDataItem>) => JSX.Element
  handle?: ReturnType<typeof useSectionFilters<T>>

  onClear?: () => void
  onSort?: () => void
  renderFooter?: (props: DefaultFooterProps) => JSX.Element
  hasScroll?: boolean
  searchInputProps?: Partial<SearchInputProps>

  headerContent?: React.ReactNode

  isLoading?: boolean
  confirmDisabled?: boolean
}

type DefaultFooterProps = {
  onClear: () => void
  onSort: () => void
  disabled: boolean
}

const DefaultFooter = (props: DefaultFooterProps) => {
  const { onClear, onSort, disabled } = props

  return (
    <View variants={['fullWidth', 'column']}>      <View variants={['fullWidth', 'alignCenter', 'marginTop:2']}>
      <Button debugName='Cancel' text='Clear all' onPress={onClear} variants={['flex', 'marginRight:2', 'flat', 'large']} />
      <Button debugName='Confirm' text='Sort' onPress={onSort} variants={['flex', 'large']} disabled={disabled} />
    </View>
    </View>
  )
}

const Scroller = ({ children }) => <View style={styles.scrollWrapper}>{children}</View>

export const Content = (props: ContentProps) => {
  const { RenderItem, handle, onClear, onSort, renderFooter, hasScroll, headerContent = null, isLoading = false, confirmDisabled = false } = props

  const Footer = renderFooter || DefaultFooter

  return (
    <View variants={['column', 'fullWidth', 'alignStretch']}>
      {headerContent}

      {isLoading ? (
        <View variants={['center']}><ActivityIndicator debugName={'SortModal loading'} /></View>
      ) : (
        <SectionFilters
          components={{
            Item: RenderItem,
            List: hasScroll ? Scroller : null,
          }}
          handle={handle}
        />
      )}

      <Footer onClear={onClear} onSort={onSort} disabled={confirmDisabled} />
    </View>
  )
}

const MAX_HEIGHT = 400

const styles = variantProvider.createComponentStyle(() => ({
  scrollWrapper: {
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column',
  },
}), true)
