import { TypeGuards } from '@codeleap/common'
import { useCallback, useMemo } from 'react'

type DerivedStateMapping<T, D> = { derive: (val:T) => D; toOriginal?: (val: D, currentOriginal?: T) => T }

export function useDerivedState<T, D>(state: [T, React.Dispatch<React.SetStateAction<T>>], mapping: DerivedStateMapping<T, D>) {
  const [value, setValue] = state

  const derived = useMemo(() => mapping.derive(value), [value, mapping.derive])

  const setDerived = useCallback((newValue: React.SetStateAction<D>) => {
    if (mapping.toOriginal) {
      if (TypeGuards.isFunction(newValue)) {
        const newDerivedValue = newValue(derived)
        setValue(mapping.toOriginal(newDerivedValue, value))
      } else {
        setValue(mapping.toOriginal(newValue, value))
      }

    }
  }, [derived, mapping.toOriginal, setValue])

  return [derived, setDerived] as const
}
