import html2canvas from 'html2canvas'

type Options = {
  id: number
  value: number
  title: string
  label: string
  short_name?: string
}

const canvasBlob = (canvas: HTMLCanvasElement, type: string, quality: any) => new Promise<Blob>((resolve) => canvas.toBlob(resolve, type, quality))

async function screenshot(node: HTMLElement, name = '') {
  const canvas = await html2canvas(node, {
    allowTaint: true,
    useCORS: true,

  })

  const format = 'png'
  const mimeType = `image/${format}`

  const croppedCanvas = document.createElement('canvas')
  const croppedCanvasContext = croppedCanvas.getContext('2d') // init data

  const cropPositionTop = 0
  const cropPositionLeft = 0

  const cropWidth = canvas.width
  const cropHeight = canvas.height

  croppedCanvas.width = cropWidth
  croppedCanvas.height = cropHeight
  croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop)

  const blob = await canvasBlob(croppedCanvas, mimeType, 1)

  const file = new File([blob], `${name}.${format}`, { type: mimeType })

  return file

}

const getLabelsByValues = (options: Options[], values: number[]) => {
  return options
    ?.filter(item => values.includes(item.value))
    ?.map(item => item.label)
}

export const DashboardUtils = {
  canvasBlob,
  screenshot,
  getLabelsByValues,
}
