import React, { useState } from 'react'
import { Mapper, memoize } from '@codeleap/common'
import { ActionIcon, Text, TextEditor, View, Button, useJournalCrudContext } from '@/components'
import { JournalQuestion } from '@/types'
import { uuid4 } from '@sentry/utils'
import { modal } from '@/utils'

type JournalQuestionItemProps = {
  onEdit: (question: JournalQuestion) => void
  onDelete: (question: JournalQuestion) => void
  question: JournalQuestion
}

type JournalQuestionModalProps = {
  question: Partial<JournalQuestion>
}

const MODAL_MAX_WIDTH = 642

const JournalQuestionModal = modal<JournalQuestionModalProps>({ id: 'journalQuestionModal', independent: true }).content((props) => {
  const { request, question } = props
  const [currQuestion, setCurrQuestion] = useState(question)

  return <>
    <Text text='Question' variants={['h3', 'marginBottom:2']} />

    <Text text='Question title' variants={['p2', 'color:neutral6', 'marginBottom:1']} />

    <TextEditor
      toolbarComponentProps={{ excludeIds: ['image', 'fileComponent', 'highlight', 'textStyles'] }}
      textEditorProps={{ variants: ['editor:adaptiveHeight', 'multiline'], styles: { editor: { maxHeight: 120 }}}}
      value={currQuestion?.question}
      onChangeValue={({ editor }) => setCurrQuestion(prev => ({ ...prev, question: editor.getHTML() }))}
    />

    <View variants={['center', 'fullWidth', 'gap:2', 'marginTop:2']}>
      <Button
        text={'Save'}
        icon='save'
        disabled={!currQuestion?.question}
        debugName={'Journals question'}
        onPress={() => request?.resolve(currQuestion)}
        variants={['large']}
      />

      <Button
        variants={[`flat`, 'large']}
        text={`Cancel`}
        debugName={'Journals question'}
        onPress={request?.reject}
      />
    </View>
  </>
}).props({
  variants: ['centered'],
  showClose: false,
  closable: false,
  closeOnEscape: false,
  style: { width: MODAL_MAX_WIDTH },
})

const JournalQuestionItem = (props: JournalQuestionItemProps) => {
  const { question, onDelete, onEdit } = props

  return (
    <View variants={['fullWidth', 'row', 'justifySpaceBetween', 'alignCenter', 'paddingHorizontal:1', 'paddingVertical:0']}>
      <Text dangerouslySetInnerHTML={{ __html: question.question }} />

      <View variants={['row', 'gap:2']}>
        <ActionIcon
          name={'edit-2'}
          onPress={() => onEdit(question)}
          debugName={'edit journal question icon'}
          variants={['backgroundColor:neutral1', 'primary5', 'iconSize:2', 'normalize']}
        />

        <ActionIcon
          name={'x'}
          onPress={() => onDelete(question)}
          debugName={'delete journal question icon'}
          variants={['backgroundColor:neutral1', 'destructive2', 'iconSize:2', 'normalize']}
        />
      </View>
    </View>
  )
}

const EmptyPlaceholder = () => (
  <View variants={['gap:2', 'column', 'fullWidth', 'center', 'paddingVertical:8']}>
    <ActionIcon
      icon='article'
      variants={['backgroundColor:neutral2', 'border-radius:rounded', 'neutral9', 'size:3']}
      debugName={'Journal Questions - List empty placeholder icon'}
      onPress={null}
    />
    <Text text={`You don't have any questions for now.`} variants={['p1', 'color:neutral7']} />
  </View>
)

const JournalQuestionsComponent = () => {
  const { questions, setQuestions, journalId } = useJournalCrudContext()

  const onEdit = (question: JournalQuestion) => {
    JournalQuestionModal.request({ question }).then(onSave).catch(() => null)
  }

  const onDelete = (question: JournalQuestion) => {
    setQuestions(questions?.filter(q => q?.id !== question?.id))
  }

  const onAdd = () => {
    const initialQuestion = {
      question: '',
      journal: journalId,
      id: ('temp-' + uuid4()) as any,
    }

    JournalQuestionModal.request({ question: initialQuestion }).then(onSave).catch(() => null)
  }

  const onSave = (updatedQuestion) => {
    const newQuestions = questions?.filter(q => q?.id !== updatedQuestion?.id)

    setQuestions([...newQuestions, updatedQuestion as JournalQuestion])
  }

  const hasQuestions = questions?.length >= 1

  return (
    <>
      <View variants={['column', 'fullWidth', 'gap:2']}>
        <View variants={['fullWidth', 'column', 'gap:2', 'padding:2', 'border', 'border-radius:small']}>
          <Text text='Questionnaire' variants={['h5', 'color:neutral8']} />

          {hasQuestions ? <>
            <Text text='QUESTION TITLE' variants={['p4', 'marginLeft:1']} />

            <Mapper
              data={questions}
              renderItem={({ item, isLast }) => (
                <View variants={['column', 'gap:2']}>
                  <JournalQuestionItem question={item} onDelete={onDelete} onEdit={onEdit} />
                  {isLast ? null : <View variants={['borderBottom']} />}
                </View>
              )}
            />
          </> : <EmptyPlaceholder />}

          <View variants={['justifyStart', 'fullWidth', 'marginTop:2']}>
            <Button
              variants={[`flat`]}
              text={`Add Question`}
              debugName={'Journals question'}
              icon={'plus'}
              onPress={onAdd}
            />
          </View>

        </View>
      </View>

      <JournalQuestionModal.Component />
    </>
  )
}

export const JournalQuestions = memoize(JournalQuestionsComponent)
