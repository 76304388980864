import { Journal, JournalQuestion } from '@/types'
import { PaginationResponse, QueryManager, RetrieveOptions } from '@codeleap/common'
import { queryClient } from '../../queryClient'
import { api } from '@/app'

const BASE_URL = 'publisher/questions/'

async function retrieve(id: Journal['id']) {
  const response = await api.get<PaginationResponse<JournalQuestion>>(BASE_URL, {
    params: { journal: id },
  })

  return { ...response.data, results: (response.data?.results || [])?.reverse() }
}

type QuestionFilters = {
    journal?: string
}

export const publisherQuestionsManager = new QueryManager<JournalQuestion>({
  itemType: {} as JournalQuestion,
  name: 'publisherQuestions',
  queryClient: queryClient.client,

  /*@ts-ignore*/
  retrieveItem: async (id: Journal['id']) => {
    const response = await retrieve(id)
    return response || null
  },
})

export const useRetrieveQuestions = ({ journal, ...rest }: {journal:Journal['id']} & RetrieveOptions<JournalQuestion>) => {
  const data = publisherQuestionsManager.useRetrieve({
    id: journal,
    queryOptions: { enabled: !!journal, initialData: null, refetchOnWindowFocus: false },
    ...rest,
  })

  return data
}
