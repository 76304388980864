import { usePhoneOptions, useRegionPhoneMask } from '@/utils'
import { TextInput } from '@/components'

type PhoneTextInputProps = {
  regionValue: number
  placeholder: string
  value: string
  isOptional?: boolean
}

const removeNonNumericCharsRegex = /\D/g

export const PhoneTextInput = (props: PhoneTextInputProps) => {
  const { regionValue, placeholder, isOptional, ...rest } = props

  const { options } = usePhoneOptions({})
  const { defaultMaskingOptions } = useRegionPhoneMask({ options, regionValue })

  const validatorPhone = (value: string) => {
    const numericValue = value?.replace(removeNonNumericCharsRegex, '')
    const currentPhoneNumericValue = defaultMaskingOptions?.mask?.replace(removeNonNumericCharsRegex, '')
    const hasContent = numericValue.length > 0

    if (isOptional && !hasContent) {
      return { valid: true, message: '' }
    }

    const match = numericValue?.length >= currentPhoneNumericValue?.length
    const invalidPhoneMessage = !match ? 'This field is invalid or incomplete' : ''

    return {
      valid: match,
      message: invalidPhoneMessage,
    }
  }

  return (
    <TextInput
      variants={['description:p3']}
      masking={{
        type: 'custom',
        options: {
          placeholder: placeholder,
          ...defaultMaskingOptions,
        },
      }}
      {...rest}
      validate={validatorPhone}
    />
  )
}
