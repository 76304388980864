import { React } from '@/app'
import { AuthModalWrapper, BeforeForgotPasswordForm, AfterForgotPasswordForm } from '@/components'
import { modal } from '@/utils'
import { useState } from '@codeleap/common'

export const ForgotPasswordModal = modal<{type: 'beforeForgot' | 'afterForgot'}>({ id: 'forgotPasswordModal' }).content((props) => {
  const { type } = props
  const [step, setStep] = useState(type === 'afterForgot' ? 2 : 1)

  const [resendEmail, setResendEmail] = useState(null)

  const toggleForm = (email) => {
    setResendEmail(email)
    setStep(2)
  }

  return (
    <AuthModalWrapper step={step} type={type}>
      {
        step === 1
          ? <BeforeForgotPasswordForm isModal onRequest={toggleForm}/>
          : <AfterForgotPasswordForm isModal email={resendEmail} />
      }
    </AuthModalWrapper>
  )
}).props({ renderHeader: () => null })
