import { variantProvider } from '@/app'

const WIDTH = 900

export const uploadFilesStyles = variantProvider.createComponentStyle((theme) => ({
  box: {
    maxWidth: WIDTH,
    width: '100%',
    overflow: 'visible',
    ...theme.spacing.padding(4),
    [theme.media.down('mid')]: {
      ...theme.spacing.padding(2),
    },
  },
  verificationBox: {
    maxWidth: WIDTH,
  },
  btnText: {
    marginRight: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  title: { margin: 0 },
}), true)

