import { React, Theme, variantProvider } from '@/app'
import { View, Text, ActionIcon, LoginModal, ForgotPasswordModal, SignupModal } from '@/components'
import { Navigation } from '@/utils'
import { APIClient } from '@/services'

type AuthModalProps = {
  children: React.ReactNode
  isOpen: boolean
  type: 'login' | 'signup' | 'beforeForgot' | 'afterForgot'
  step?: number
}

const BREAKPOINT = 'mid'

export const AuthModalWrapper = (props: AuthModalProps) => {
  const {
    children,
    isOpen,
    type,
    step,
    ...modalProps
  } = props
  const { profile } = APIClient.Session.useSession()

  const onClose = () => {
    if (type === 'login') LoginModal.close()
    else if (type === 'signup') SignupModal.close()
    else ForgotPasswordModal.close()

    if (profile) Navigation.navigate('Manuscripts.List')
  }

  return (
    <View css={[styles.formWrapper]}>
      <View variants={['flex', 'row', 'marginBottom:1', 'justifySpaceBetween', 'alignCenter']}>
        {type !== 'login' ? (
          <Text
            variants={['h4', 'color:neutral8']}
            text={`Step ${step}/3`}
          />
        ) : null}
        <ActionIcon
          name={'x'}
          variants={['minimal', 'primary3', 'marginLeft:auto']}
          iconProps={{
            size: Theme.values.iconSize[3],
          }}
          onPress={onClose}
        />
      </View>
      {children}
    </View>
  )
}

const FORM_WIDTH = 480
const LOGO_WIDTH = 300

const styles = variantProvider.createComponentStyle((theme) => ({
  formWrapper: {
    ...theme.presets.column,

    [Theme.media.up('small')]: {
      width: FORM_WIDTH,
    },
  },
  wrapper: {
    minHeight: '90vh',
    ...theme.presets.fullWidth,
    ...theme.presets.row,

    [theme.media.down(BREAKPOINT)]: {
      flexDirection: 'column',
      overflow: 'auto',
    },
  },
  leftContainer: {
    ...theme.presets.flex,
    ...theme.presets.alignCenter,
    backgroundColor: theme.colors.primary1,
    [theme.media.down(BREAKPOINT)]: {
      ...theme.presets.justifyCenter,
    },
    position: 'relative',
  },
  rightContainer: {
    width: '35vw',
    ...theme.spacing.padding(10),
    ...theme.presets.center,

    [theme.media.down(BREAKPOINT)]: {
      width: '100%',
      maxWidth: '100vw',
      ...theme.spacing.padding(2),
      ...theme.spacing.paddingTop(4),
    },
  },
  image: {
    ...theme.presets.full,
  },
  logo: {
    position: 'absolute',
    width: LOGO_WIDTH,
    top: 30,
    left: 30,
  },
}), true)
