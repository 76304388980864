import { Disclaimer, JournalQuestion } from '@/types'
import { uuid4 } from '@sentry/utils'

export const portalImageResolutionText = 'Recommended image resolution: 1720 x 400 pixels'
export const journalImageResolutionText = 'Recommended image resolution: 900 x 1200 pixels'

export const defaultQuestions = [
  {
    'question': '<p>How innovative and impactful is the research presented in the manuscript?</p>',
    id: ('temp-' + uuid4()) as any,
  },
  {
    'question': '<p>How well-designed and executed are the methods employed in the research?</p>',
    id: ('temp-' + uuid4()) as any,
  },
  {
    'question': '<p>How clear and logically organized is the presentation of ideas and results?</p>',
    id: ('temp-' + uuid4()) as any,
  },
  {
    'question': '<p>To what extent does the manuscript contribute new knowledge or insights to the relevant field of study?</p>',
    id: ('temp-' + uuid4()) as any,
  },
  {
    'question': '<p>How relevant is the research to current issues or challenges in the field, and what potential impact could it have?</p>',
    id: ('temp-' + uuid4()) as any,
  },
] as Partial<JournalQuestion>[]

export const defaultRecommendationQuestion = {
  question: '<p>What is your recommendation for this manuscript?</p>',
  choices: [
    {

      'label': 'Accept',
      'value': 'Accept',
    },
    {

      'label': 'Revise',
      'value': 'Revise',
    },
    {

      'label': 'Reject',
      'value': 'Reject',
    },
  ],
} as Partial<JournalQuestion>

const readAndAgreed = 'I have read and agreed to the '

let idCounter = Math.floor(Date.now() / 1000)

export const defaultAuthorDisclaimer = [
  {
    body: 'I understand that, as the Corresponding Author, I am responsible for completing the Authorship information on behalf of all authors listed on the manuscript.',
    title: 'Authorship responsibility agreement',
    id: idCounter++,
  },
  {
    body: 'All contributing authors listed on the manuscript are aware of and agree to the submission of this manuscript.',
    title: 'Authorship agreement',
    id: idCounter++,
  },
  {
    title: 'ICMJE Criteria for Authorship agreement',
    id: idCounter++,
    body: 'All authors have read and confirmed that they meet the ICMJE criteria for authorship.',

  },
  {
    body: 'Any persons named in the acknowledgements section of the manuscript have agreed to being so named.',
    title: 'Acknowledgements naming agreement',
    id: idCounter++,
  },
  {
    title: 'Generic and Trade Names agreement',
    id: idCounter++,
    body: "Articles must give a balanced view of therapeutic options. Authors’ use of generic names will contribute to this impartiality. If trade names are used, those of several companies should be used rather than only that of a single support company. It is each author's responsibility to disclose in the Work any commercial support related directly or indirectly to the subject of the Work. It is also each author's responsibility to include the Food and Drug Administration clearance status of any device or drug requiring FDA approval discussed or described in their presentation or to describe the lack of FDA clearance for any “off label” uses discussed.",
  },
  {
    title: 'Unlabeled Uses of Products agreement',
    id: idCounter++,
    body: `I understand that, as the Corresponding Author, when an unlabeled use of a commercial product, or an investigational use not yet approved for any purpose is discussed during an educational activity, the presenter is required to disclose that the product is not labeled for the use under discussion or that the product is still investigational.`,
  },
  {
    title: 'US Government Employee agreement',
    id: idCounter++,
    body: `A work prepared by a government employee as part of his or her official duties is called a "work of the U.S.Government" and is not subject to copyright. If it is not prepared as part of the employee's official duties, it may be subject to copyright. If Government Worker is selected the following text will be placed on the article: “This article is written work prepared by employees of the Federal Government as part of their official duties is, under the U.S. Copyright Act, a “work of the United States Government” for which copyright protection under Title 17 of the United States Code is not available. As such, copyright does not extend to the contributions of employees of the Federal Government`,
  },
  {
    body: 'If your manuscript reports a clinical trial or research requiring registration, the name of the registry and the registration number/identifier of the trial are included on the title page (if applicable).',
    title: 'Clinical Trial Registration agreement',
    id: idCounter++,
  },
  {
    title: 'Statement of Non-duplication agreement',
    id: idCounter++,
    body: 'To the best of my knowledge and conviction: The content of the Manuscript, all or in part, has not been published and is not being considered for publication. All intellectual contributions, technical help, financial or material support, and all financial or other relationships that may constitute or lead to a conflict of interest have been acknowledged or disclosed in the Manuscript.',
  },
  {
    title: 'Statement of Originality agreement',
    id: idCounter++,
    body: 'The author warrants that this Work contains no libelous or unlawful statements and does not infringe on the rights of others. If excerpts (eg, text, figures, tables, illustrations, or audio/video files) from copyrighted works are included, a written release will be secured by the authors prior to submission, and credit to the original publication will be properly acknowledged. The author further warrants that he or she has obtained, prior to submission, written releases from patients whose names or likenesses are submitted as part of the Work.',
  },
  {
    title: 'Open Access License',
    id: idCounter++,
    body: '<p>Premier Science applies the <a target="_blank" rel="noopener noreferrer" onclick="event.stopPropagation(); window.open(event.currentTarget.href, &quot;_blank&quot;); return false;" href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution (CC BY) license</a> to <a target="_blank" rel="noopener noreferrer" onclick="event.stopPropagation(); window.open(event.currentTarget.href, &quot;_blank&quot;); return false;" href="https://creativecommons.org/licenses/by/4.0/">articles and other works we publish</a>. When you submit your paper for publication by Premier Science, you agree to have the CC BY license applied to your work.</p><p>Under the CC BY Open Access license, you as the author agree that anyone can <a target="_blank" rel="noopener noreferrer" onclick="event.stopPropagation(); window.open(event.currentTarget.href, &quot;_blank&quot;); return false;" href="https://creativecommons.org/licenses/by/4.0/">reuse your article in whole or part for any purpose</a>, for free, even for commercial purposes. Anyone may copy, distribute, or reuse the content&nbsp;as long as the author and original source are properly cited. This facilitates freedom in re-use and also ensures that Premier Science content can be mined without barriers for the needs of research.</p>',
  },
  {
    title: 'Article transfer policy',
    id: idCounter++,
    body: '<p>Premier Science is a collection of journals with varying scopes. Your article may be deemed more suitable for an alternate journal within the collection, rather than the one you submitted to by our Editors. If this occurs, your article will be automatically transferred to that journal along with any associated files/attachments and peer-reviews/editorial judgements.</p>',
  },
  {
    title: 'Instructions for Authors and Editorial Policies',
    id: idCounter++,
    body: '<p>You agree that your manuscript is in line with our Instructions for Authors: <a target="_blank" rel="noopener noreferrer" onclick="event.stopPropagation(); window.open(event.currentTarget.href, &quot;_blank&quot;); return false;" href="https://premierscience.com/instructions-for-authors/">https://premierscience.com/instructions-for-authors/</a> and Editorial Policies: <a target="_blank" rel="noopener noreferrer" onclick="event.stopPropagation(); window.open(event.currentTarget.href, &quot;_blank&quot;); return false;" href="https://premierscience.com/editorial-policies/">https://premierscience.com/editorial-policies/ </a></p>',
  },
  {
    title: 'Provenance and peer review',
    id: idCounter++,
    body: '<p>Articles will labelled whether they are commissioned or unsolicited.<br></p><p>All articles published in the Journal are subject to an initial review by a Manuscript Administrator, Managing Editor or the Editor-in-Chief. Subsequent to this initial review, manuscripts will either be rejected, or independent external peer reviewers and/or Editorial Board members/internal reviewers will be invited for comment through a single or double-blind process.</p>',
  },
  {
    title: 'Authorship responsibility agreement',
    id: idCounter++,
    body: '<p>I understand that, as the Corresponding Author, I am responsible for completing the Authorship information on behalf of all authors listed on the manuscript. This information includes names, institutions, emails, degrees, etc.</p>',
  },
] as Partial<Disclaimer>[]

export const emailFieldProps = {
  'reviewer_invitation': {
    field: 'reviewer_custom_email',
    from: '',
    to: '',
  },
  'revision_decision': {
    field: 'revision_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'rejected_decision': {
    field: 'rejected_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'editor_assigment': {
    field: 'editor_assigment_template',
    from: 'Editor in chief',
    to: 'Editor',
  },
  'manuscript_transferred': {
    field: 'manuscript_transferred_template',
    from: 'Editor',
    to: 'Author',
  },
  'reviews_complete': {
    field: 'reviews_complete_template',
    from: 'Editor',
    to: 'Author',
  },
  'review_invite_accepted': {
    field: 'review_invite_accepted_template',
    from: 'Editor',
    to: 'Reviewer',
  },
  'completed_review': {
    field: 'completed_review_template',
    from: 'Editor',
    to: 'Reviewer',
  },
  'manuscript_submission': {
    field: 'manuscript_submission_template',
    from: 'Editor',
    to: 'Author',
  },
  'rejected_resubmission_decision': {
    field: 'rejected_resubmission_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'accepted_decision': {
    field: 'accepted_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'revise_reminder': {
    field: 'revise_reminder_template',
    from: 'Editor',
    to: 'Author',
  },
  'late_review_reminder': {
    field: 'late_review_reminder_template',
    from: 'Editor',
    to: 'Reviewer',
  },
}
