import { APIClient } from '@/services'
import { DateUtils, useManagerTable } from '@/utils'
import { createContext, MutableRefObject, useContext, useRef } from 'react'
import { useArticleTypeSorter, useCountrySorter, useDateSorter, useJournalSorter, useJournalTypeSorter } from '../SortModal'
import { TypeGuards } from '@codeleap/common'
import { endOfYear, format, startOfYear } from 'date-fns'
import { JournalSubmissionsDetails } from '@/types'

const defaultStartDate = format(startOfYear(new Date()), 'dd/MM/yyyy')
const defaultEndDate = format(endOfYear(new Date()), 'dd/MM/yyyy')

type UseManagerTableReturn = ReturnType<typeof useManagerTable>

export type FilterType = {
  journal: string
  limit: number
  date_start: string
  date_end: string
  filter_type: string
  region: string
  article_type: string
}

type TDashboardContext = {
  refs: {
    chartRef: MutableRefObject<any>
    tableRef: MutableRefObject<any>
    coverRef: MutableRefObject<any>
  }
  filter: FilterType
  limit: number

  isDetailsLoading: boolean
  isRefetching: boolean
  details: JournalSubmissionsDetails
  startDate: string
  endDate: string
  dashboardJournals: UseManagerTableReturn[1]
  tableProps: UseManagerTableReturn[0]

  invalidStartDate: boolean
  invalidEndDate: boolean

  sorters: {
    journalTypeSorter: ReturnType<typeof useJournalTypeSorter>
    countrySorter: ReturnType<typeof useCountrySorter>
    dateSorter: ReturnType<typeof useDateSorter>
    articleTypeSorter: ReturnType<typeof useArticleTypeSorter>
    journalSorter: ReturnType<typeof useJournalSorter>
  }
}

type DashboardProps = {
  children: React.ReactNode
}

const DashboardContext = createContext({} as TDashboardContext)

export const DashboardProvider = (props: DashboardProps) => {
  const { children } = props
  const limit = 30
  const today = format(new Date(), 'dd/MM/yyyy')

  const chartRef = useRef(null)
  const tableRef = useRef(null)
  const coverRef = useRef(null)

  const countrySorter = useCountrySorter()
  const dateSorter = useDateSorter()
  const articleTypeSorter = useArticleTypeSorter()
  const journalSorter = useJournalSorter()
  const journalTypeSorter = useJournalTypeSorter()

  const dateCurrentOption = dateSorter?.sorter?.sortBy[0]
  const hasSelectedPredefinedDate = !TypeGuards.isNil(dateCurrentOption)

  const startDate = hasSelectedPredefinedDate
    ? DateUtils.subtractDays(today, dateCurrentOption)
    : format(new Date(dateSorter?.datePicker?.startDate), 'dd/MM/yyyy')

  const endDate = hasSelectedPredefinedDate ? today : format(new Date(dateSorter?.datePicker?.endDate), 'dd/MM/yyyy')

  const invalidStartDate = startDate === '31/12/1969'
  const invalidEndDate = startDate === '31/12/1969'

  const filter = {
    journal: journalSorter?.sorter?.sortBy[0],
    limit,
    date_start: TypeGuards.isNil(dateSorter?.datePicker?.startDate) && TypeGuards.isNil(dateSorter?.sorter?.currentOption) ? defaultStartDate : startDate,
    date_end: TypeGuards.isNil(dateSorter?.datePicker?.endDate) && TypeGuards.isNil(dateSorter?.sorter?.currentOption) ? defaultEndDate : endDate,
    filter_type: journalTypeSorter?.sorter?.sortBy[0],
    region: countrySorter?.sorter?.sortBy?.map((region) => region)?.join(','),
    article_type: articleTypeSorter.sorter?.sortBy?.map((articleType) => articleType)?.join(','),
  }

  const [tableProps, dashboardJournals] = useManagerTable(APIClient.Dashboard.dashboardManager, {
    listOptions: { queryOptions: {} },
    filter: filter,
    limit,
  })

  const { details, isLoading: isDetailsLoading, isRefetching } = APIClient.Dashboard.useJournalsDetails({
    ...filter,
    limit,
  })

  const value: TDashboardContext = {
    refs: {
      chartRef,
      tableRef,
      coverRef,
    },
    filter,
    limit,
    invalidStartDate,
    invalidEndDate,

    isDetailsLoading,
    isRefetching,
    details,
    startDate,
    endDate,
    dashboardJournals,
    tableProps,
    sorters: {
      countrySorter,
      dateSorter,
      articleTypeSorter,
      journalSorter,
      journalTypeSorter,
    },

  }

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboard = () => {
  const ctx = useContext(DashboardContext)

  return ctx
}
