import { api } from '@/app'
import { Journal, JournalAnswer, Publication, Review } from '@/types'
import { ExternalRequestClientConfig, PaginationResponse, QueryManager, ReactQuery, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from './queryClient'
import { Attachment } from '@/components'

const BASE_URL = 'article/review'

type ListFiltersProps = {
  article: Publication['id']
  review?: number
}

type JournalAnswerItemProps = {
  question: number
  answer: number
}

type PostArticleProps = {
  article: Publication['id']
  comment: string
  status: string
}

type PostJournalAnswersProps = {
  review: number
  answers: JournalAnswerItemProps[]
}

type PostArticleReviewMediaProps = {
  files: Attachment[]
  review: Review
}

async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Journal>>(`${BASE_URL}/`, {
    params,
  })

  return response.data
}

export async function createMedia(props, params?: ExternalRequestClientConfig) {
  const response = await api.post(`article/review_media/`, props?.media, { multipart: true, ...params })
  return response.data
}

export const JournalAnswers = async (data: PostJournalAnswersProps) => {
  const response = await api.post(`article/journal_answers/answer_many/`, { ...data })
  return response.data
}

export const GetJournalAnswers = async (data: { review: JournalAnswer['review'] }) => {
  const response = await api.get(`article/journal_answers/`, { params: { review: data?.review }})

  return response.data
}

export const postArticleReview = async (props) => {
  const response = await api.post(`${BASE_URL}/`, { ...props })
  return response.data
}

export const updateArticleReview = async (props) => {
  const { id, ...data } = props
  const response = await api.patch(`${BASE_URL}/${id}/`, { ...data })
  return response.data
}

export const postArticleReviewMedia = async (props: PostArticleReviewMediaProps) => {

  const { files, review } = props

  const sendFile = async (file: any) => {
    const media = {
      data: { review, file_size: file.file.size },
      file: file.file,
    }
    const response = await createMedia({ media })
    return response
  }

  const responses = await Promise.all(files.map(sendFile))

  return responses
}

export const onDeleteMediaAttachment = async ({ media }) => {
  const responses = []
  media?.forEach(async (id) => {
    const response = await api.delete(`article/review_media/${id}/`)
    responses.push(response)
  })
  return responses
}

export const deleteReviewDraft = async ({ id, article }) => {
  const response = await api.delete(`article/review/${id}/`, { params: { article }})
  return String(response?.status)?.startsWith('20')
}

export const reviewManager = new QueryManager<Review>({
  itemType: {} as Review,
  name: 'reviews',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFiltersProps) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  createItem: async (data: PostArticleProps) => {
    const response = await postArticleReview(data)
    return response
  },

  updateItem: async (data) => {
    const response = await updateArticleReview(data)
    return response
  },
})

const reviewQueryKey = queryClient.dynamicQueryKey<Review>((x: [Publication['id'], Review['id']]) => ['review', ...x])

export const useReview = ({ id, article }) => {

  return ReactQuery.useQuery({
    queryKey: reviewQueryKey.key([article, id]),
    queryFn: async () => {
      const response = await api.get<Review>(`${BASE_URL}/${id}/`, { params: { article }})
      return response.data
    },
    refetchOnWindowFocus: false,
    initialData: null,
  })
}

export const useArticleReviews = ({ article }) => {
  return ReactQuery.useQuery({
    queryKey: [`review-${article}`],
    queryFn: async () => {
      const response = await api.get(`${BASE_URL}/`, { params: { article }})
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount(q) {
      return true
    },
    retry: false,
    enabled: true,
  })
}
