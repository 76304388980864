import { EditorOptions, useEditor } from '@tiptap/react'
import { useDebouncer } from './useDebouncer'

export const useDebouncedEditor = (options: Partial<EditorOptions>) => {
  const { content, onUpdate, ..._options } = options

  const [_content, _onUpdate] = useDebouncer(content, onUpdate)

  const editor = useEditor({
    ..._options,
    content: _content,
    onUpdate: _onUpdate,
  })

  return editor
}
