import { useCallback, useRef, useState } from 'react'

export function useDebouncer(value, setValue) {
  const [_value, _setValue] = useState(value)
  const timeout = useRef<NodeJS.Timeout>()

  const handleChange = useCallback((v) => {
    _setValue(v)
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(() => {
      setValue(v)
      timeout.current = null
    }, 100)
  }, [setValue])

  return [_value, handleChange]
}
