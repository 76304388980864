import { APIClient } from '@/services'
import { FileCategory, Publication } from '@/types'
import { TypeGuards } from '@codeleap/common'

type FileCategoryHookOptions = {
  version?: Publication['version']
  status?: Publication['status']
  show_on_revision?: boolean
  show_on_submission?: boolean
  refers_to_main_document?: boolean
  in?: FileCategory['id'][]
}

export const useFileCategoryOptions = (props?: FileCategoryHookOptions) => {

  const {
    version,
    status,
  } = props ?? {}

  const isFirstPublicationVersion = version === 1
  const isSubmission = (isFirstPublicationVersion || !version) && status !== 'revision_requested'

  const show_on_revision = props?.show_on_revision || !isSubmission
  const show_on_submission = props?.show_on_submission || isSubmission

  const file_categories = APIClient.QueryKeys.fileCategory.getData()

  const filter = (category: FileCategory) => {

    if (!TypeGuards.isNil(props?.in)) {
      const included = props.in.includes(category.id)

      if (!included) {
        return false
      }
    }

    if (!TypeGuards.isNil(props?.refers_to_main_document)) {
      return category.refers_to_main_document == props.refers_to_main_document
    }

    return true
  }

  if (show_on_revision) {
    return file_categories?.show_on_revision.filter(filter)
  } else if (show_on_submission) {
    return file_categories?.show_on_submission.filter(filter)
  } else {
    return [...file_categories?.show_on_revision, ...file_categories?.show_on_submission].filter(filter)
  }

}
