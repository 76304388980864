import { Button, Text, View } from '@/components'
import { QueryKeys } from '@/services/api'
import { DashboardExportParams } from '@/services/api/dashboard'
import { modal } from '@/utils'

type ExportModalProps = {
  type?: string
  setType?: (state: string) => void
  handleExport: () => void
}

const extensions: DashboardExportParams['type'][] = ['csv', 'xlsx', 'pdf', 'pptx']

export const ExportModal = modal<ExportModalProps>({ id: 'exportModal', independent: true }).content((props) => {
  const { type, setType, handleExport } = props

  const publisherTitle = QueryKeys.me.getData().publisher_data.title

  return (
    <View variants={['column', 'gap:3']}>
      <View variants={['column', 'fullWidth', 'gap:0.5']}>
        <Text text='Exporting information from:' variants={['p3', 'color:neutral7', 'semiBold']} />
        <Text text={publisherTitle} variants={['h5', 'color:neutral10', 'semiBold']} />
      </View>

      <View variants={['column', 'gap:1']}>
        {extensions.map((ext) => {
          const isSelected = ext === type
          return <Button
            debugName={'ExportInformationModal:export'}
            text={ext}
            onPress={() => setType(ext)}
            variants={['large', 'text:uppercase', 'minimal', 'flat:hover', isSelected ? 'default' : '', 'text:left', 'fontWeight:400']}
            rightIcon={isSelected ? 'check' : ''}
            styles={isSelected ? { wrapper: { justifyContent: 'space-between' } } : { wrapper: { justifyContent: 'flex-start' } }}
          />
        })}
      </View>

      <Button
        debugName={'ExportInformationModal:export'}
        text='Export'
        onPress={handleExport}
        variants={['large']}
        disabled={!type}
      />
    </View>
  )
}).props({
  title: 'Export Information',
  variants: ['fullWidth'],
  styles: { box: { minWidth: 420 } },
})
