import { AppStatus } from '@/redux'
import { DashboardExportParams } from '@/services/api/dashboard'
import { DashboardUtils } from '../dashboard'
import { APIClient } from '@/services'
import { downloadFileFromURL } from '../misc'
import { getOsAlert, OSAlert } from '@/app'
import { FilterType } from '@/components'
import { MutableRefObject } from 'react'

type UseExportDashboardReturn = {
  onExport: (ext: DashboardExportParams['type']) => void
}

type UseExportDashboardProps = {
  chartRef: MutableRefObject<any>
  tableRef: MutableRefObject<any>
  coverRef: MutableRefObject<any>
  filter: FilterType
}

export const useExportDashboard = (props: UseExportDashboardProps): UseExportDashboardReturn => {
  const { coverRef, chartRef, tableRef, filter } = props

  const onExport = async (ext: DashboardExportParams['type']) => {
    try {
      AppStatus.set('loading')

      let files = []

      if (ext === 'pdf' || ext === 'pptx') {
        const coverImage = await DashboardUtils.screenshot(coverRef.current, 'cover')
        const chartImage = await DashboardUtils.screenshot(chartRef.current, 'chart')
        const tableImage = await DashboardUtils.screenshot(tableRef.current, 'table')

        files = [coverImage, chartImage, tableImage]
      }

      const url = await APIClient.Dashboard.getExportUrl({
        type: ext?.toLowerCase() as DashboardExportParams['type'],
        files,
        filters: filter,
      })

      await downloadFileFromURL({ file: url, name: 'dashboard_information', avoidPopUpBlocker: true })
      AppStatus.set('done')
    } catch (err) {
      console.log(err)
      AppStatus.set('idle')
      getOsAlert()
    }
  }

  return {
    onExport,
  }
}
