import React from 'react'
import { APIClient } from '@/services'
import { ActivityIndicator, Select, Text, View } from '@codeleap/web'
import { useState } from 'react'
import { components, SingleValueProps } from 'react-select'
import { Theme } from '@/app'

const SubmittingAt = ({ journal, loading }) => (
  <>
    <View variants={['column']} style={{ pointerEvents: 'none' }}>

      <Text text='Submitting at:' variants={['p2', 'semiBold']} />
      {loading ? <ActivityIndicator debugName={'JournalSelector:ActivityIndicator'} variants={['small']}/>
        : <Text text={journal ?? 'No Journal Selected'} variants={['h4']} />}

    </View>
  </>
)
const SingleValue = (props: SingleValueProps) => {

  return <components.SingleValue {...props} >
    <SubmittingAt journal={props?.data?.label} />

  </components.SingleValue>
}

export function JournalSelector() {
  const journals = APIClient.Journals.useJournalOptions()
  const options = journals.options

  const { profile } = APIClient.Session.useSession()

  const updateProfile = APIClient.Session.useUpdateProfile()

  const [selectedJournalId, setSelectedJournal] = useState(profile?.current_journal ?? null)

  const onChange = (value) => {
    if (selectedJournalId === value) return
    updateProfile.mutateAsync({
      current_journal: value,
      id: profile?.id,
    })
    setSelectedJournal(value)
  }

  if (!options?.length || journals.loading) {
    return (
      <Select
        key={'JournalSelector:loading'}
        debugName='JournalSelector:loading'
        onValueChange={onChange}
        variants={['borderless', 'strongOption', 'noError']}
        components={{
          Placeholder: (props) => <SubmittingAt {...props} loading/>,
        }}
        styles={{
          wrapper: {
            ...Theme.spacing.marginHorizontal(0.5),
          }}}
      />
    )
  } else {
    return <Select
      debugName='JournalSelector'
      key={'JournalSelector'}
      options={options}
      value={selectedJournalId}
      onValueChange={onChange}
      variants={['borderless', 'strongOption', 'noError']}
      components={{
        SingleValue: SingleValue,
        Placeholder: SubmittingAt,
      }}
      controlShouldRenderValue
      loadInitialValue
      styles={{
        wrapper: {
          ...Theme.spacing.marginHorizontal(0.5),
        },
      }}
    />
  }
}
