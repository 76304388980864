import { authorValidationRules } from '@/app/forms'
import { PublicationAuthor } from '@/types'
import { PublicationUtils } from '@/utils'
import { deepEqual } from '@codeleap/common'

export function hasAuthorsChanges(forms: Partial<PublicationAuthor>[], initialState: Partial<PublicationAuthor>[]): boolean {
  if (forms.length !== initialState.length) return true

  return !deepEqual(forms, initialState)
}
export function isAuthorsValid(forms: Partial<PublicationAuthor>[]): boolean {
  return forms.every((author) => {
    return (
      author?.contribution?.trim().length >= authorValidationRules.contribution.min &&
      PublicationUtils.validateEmail(author?.email?.trim()) &&
      author?.region &&
      author?.full_name?.trim().length >= authorValidationRules.full_name.min &&
      author?.organisation?.trim().length >= authorValidationRules.organisation.min &&
      Array.isArray(author?.tags) &&
      author?.tags.length > 0 &&
      authorValidationRules.order.validate(author?.order).valid
    )
  })
}

export function validateAuthorOrder(forms: Partial<PublicationAuthor>[]): boolean {
  const authorOrders = forms?.map(({ order }) => Number(order))?.filter((order) => !isNaN(order))

  return authorOrders?.every((authorOrder, index, arr) => {
    const isUnique = arr?.indexOf(authorOrder) === index
    const isPositive = authorOrder > 0
    const isInRange = authorOrder <= arr?.length

    return isUnique && isPositive && isInRange
  })
}
