import { DashboardDatePicker } from './DatePicker'
import { DashboardOverviewCard } from './OverviewCard'
import { JournalData } from './Sections/JournalData'
import { statusChartColors } from './Charts/chartColors'
import { Chart } from './Sections/Chart'
import { JournalTable } from './Sections/JournalTable'
import { Export } from './Sections/Export'
import { Header } from './Header'
import { ExportModal, ExportWrapper, Hidden } from './Exports'
import { HorizontalBarChart } from './Charts'

export const Dashboard = {
  DatePicker: DashboardDatePicker,
  OverviewCard: DashboardOverviewCard,
}

export const DashboardChartColors = {
  statusChartColors,
}

export const DashboardSections = {
  JournalData,
  JournalTable,
  Chart,
  Export,
}

export const DashboardExports = {
  Header: Header,
  Hidden: Hidden,
  Wrapper: ExportWrapper,
  Modal: ExportModal,
}

export const DashboardCharts = {
  HorizontalBar: HorizontalBarChart,
}

export * from './predefinedFilters'
export * from './useDashboard'
