import { variantProvider } from '@/app'
import { ReviewsOverview, View, Text, ActionIcon, Button, prepareFilesForUpload } from '@/components'
import { DecisionMedia } from '@/types'
import { downloadFileFromURL, formatFileName, humanizedFileSize } from '@/utils'
import { NamePreview } from './NamePreview'

type AttachmentsFormProps = {
  savedAttachments: DecisionMedia[]
  newAttachments: File[] // AttachmentWithCategory is not defined on this PR
  publicationId: number
  disabled?: boolean
  addAttachment: (attachment: File[]) => void // AttachmentWithCategory is not defined on this PR
  deleteAttachment: (attachment: { id?: number; index?: number }) => void
}

type AttachmentProps = Partial<DecisionMedia>
& Pick<AttachmentsFormProps, 'disabled' | 'publicationId'>
& { position: number; isLocal?: boolean; onDelete: () => void }

const Attachment = ({ file, file_size, owner_role, reviewer_email, reviewer_name, reviewer_id, disabled, publicationId, position, isLocal, onDelete }: AttachmentProps) => {

  return (
    <View variants={['justifySpaceBetween', 'borderBottom', 'paddingVertical:2', 'alignStart', 'marginBottom:3', 'gap:2', 'column', 'fullWidth']}>
      <View variants={['gap:1', 'row', 'alignCenter', 'fullWidth']}>

        <NamePreview
          onCloseInfo={ReviewsOverview.open}
          reviewer={{ reviewer_email, reviewer_name, reviewer_id }}
          publicationId={publicationId}
          position={position}
          isEditor={owner_role !== 'reviewer'}
        />

        <View variants={['gap:1', 'alignCenter']}>
          {
            !isLocal && (
              <ActionIcon
                variants={['small', 'minimal']}
                icon='download'
                debugName='attachaments download'
                onPress={() => downloadFileFromURL({ file, avoidPopUpBlocker: true })}
              />

            )
          }
          {!disabled && <ActionIcon
            icon='x'
            debugName='attachments delete'
            onPress={onDelete}
            variants={['small', 'destructive:minimal']}
          />}
        </View>

      </View>

      <View variants={['row', 'fullWidth', 'justifySpaceBetween', 'alignStart', 'gap:2']}>
        <Text
          variants={[`p1`]}
          style={{ wordBreak: 'break-all' }}
          text={formatFileName(file)}
        />
        <Text variants={[`p1`, 'noWrap']} text={humanizedFileSize(file_size)} />
      </View>

    </View>
  )
}

export const AttachmentsForm = (props: AttachmentsFormProps) => {
  const { savedAttachments, newAttachments, publicationId, disabled, addAttachment, deleteAttachment } = props

  const openUploadModal = async () => {

    const result = await prepareFilesForUpload({
      showConfirmation: false,
      withCategory: false,
      pickerDescription: null,
    })

    if (!result) return

    addAttachment(result.attachments)
  }

  return (
    <View style={styles.scroll}>
      {savedAttachments?.map?.((item, key) => <Attachment
        key={key}
        position={key}
        publicationId={publicationId}
        onDelete={() => deleteAttachment({ id: item.id, index: key })}
        disabled={disabled}
        {...item}
      />)}
      {newAttachments?.map?.((item, key) => <Attachment
        key={key}
        position={key}
        isLocal
        publicationId={publicationId}
        onDelete={() => deleteAttachment({ index: key })}
        file={item.name}
        file_size={item.size}
        {...item}
      />)}

      {!disabled && <Button
        debugName='Decision: Add more'
        variants={['flat', 'large', 'marginHorizontal:auto', !savedAttachments?.length && 'marginTop:2']}
        text='Add more'
        icon='file-plus'
        onClick={e => {
          e.stopPropagation()
          openUploadModal()
        }}
      />
      }
    </View>

  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.spacing.paddingRight(1),
    ...theme.spacing.paddingRight(1),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: 100,
    maxHeight: 300,
  },
}), true)
