import { APIClient } from '@/services'
import { SelectJournalModalProps } from '@/components'
import { Journal, Member, Permission, PermissionLevel, Publisher } from '@/types'
import { useCallback, useMemo, useState } from '@codeleap/common'
import { EmptyPlaceholder, ListProps } from '@codeleap/web'
import { useSyncedState } from './useSyncedState'
import { PermissionUtils } from '../permissions'
import { DefinedUseQueryResult } from '@tanstack/react-query'
import { AppStatus } from '@/redux'

// TODO: We need to create another type changing the journal type to { value, label } instead { id, title }

const debug = false as const

const levels: PermissionLevel[] = ['role_publisher', 'role_managing_editor', 'role_editor_chiefe', 'role_editor']

function getJournalValue(permission: Permission) {
  return { value: permission.journal.id, label: permission.journal.title }
}

function getParsedPermissions(permissions: Permission[], member: Member) {
  return {
    target: member.profile.id,
    // @ts-ignore
    permissions: permissions.map(p => ({ ...p, journal: p.journal.value })),
  }
}

function permissionIsValid(permission: Partial<Permission>) {
  const valid = levels.some(l => permission[l])
  // @ts-ignore
  return valid && permission.journal.value !== ''
}

function ListEmptyComponent() {
  // @ts-ignore
  return <EmptyPlaceholder icon='file' variants={['table']} title='No Journals found.' />
}

export type UsePermissionFormProps = {
  publisher: Publisher['id']
  initialPermissions?: Permission[]
  onlyPublisher?: boolean
  member: Member
  query?: DefinedUseQueryResult<Member>
}

export const usePermissionForm = (params: UsePermissionFormProps) => {
  const { onlyPublisher = true, publisher, member, query } = params

  // @ts-ignore
  const initialState: Partial<Permission>[] = useMemo(() => {
    if (debug) console.log('initial state', member.permissions)

    if (member.permissions?.length) return member.permissions.map(p => ({ ...p, journal: getJournalValue(p) }))
    return [PermissionUtils.permissionInitialState]
  }, [member.permissions])

  const [forms, setForms] = useSyncedState(initialState, debug)
  const [journalSearch, setJournalSearch] = useState('')

  const { delete: deletePermission } = APIClient.Permissions.permissionsManager.useDelete()

  const { listProps } = APIClient.Journals.useJournals({
    publisher,
    title: journalSearch,
    only_publisher: onlyPublisher,
    limit: 50,
    listOptions: { noMoreItemsText: null },
  })

  const isFormsValid = useMemo(() => forms.every(f => permissionIsValid(f)), [forms])

  const selectJournalModalProps: SelectJournalModalProps = useMemo(() => {
    // @ts-ignore
    const selectedJournals = forms.map((p) => p.journal?.value)

    const journalOptions = ((listProps.data ?? []) as Journal[])
      .filter((journal) => !selectedJournals.includes(journal.id))
      .map((journal) => ({ label: journal.title, value: journal.id }))

    return {
      listProps: { ...listProps, ListEmptyComponent, data: journalOptions } as any as ListProps,
      searchInputProps: {
        placeholder: 'Search for publications',
        onSearchChange: setJournalSearch,
        debounce: 1000,
      },
    }
  }, [listProps])

  const handlePermissionDelete = useCallback(async (permission: Permission) => {
    if (permission.id) {
      try {
        AppStatus.set('loading')
        await deletePermission(permission)
        await query.refetch()
        AppStatus.set('done')
      } catch (err) {
        logger.error(err)
        AppStatus.set('idle')
      }
    }
  }, [])

  const handlePermissionSave = useCallback(async () => {
    const parsedPermissions = getParsedPermissions(forms as any as Permission[], member)

    try {
      AppStatus.set('loading')
      await APIClient.Permissions.createPermission(parsedPermissions)
      await query.refetch()
      AppStatus.set('done')
    } catch (err) {
      logger.error(err)
      AppStatus.set('idle')
    }

  }, [forms])

  return {
    selectJournalModalProps,
    handlePermissionDelete,
    handlePermissionSave,
    isFormsValid,
    forms,
    setForms,
  }
}
