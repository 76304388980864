import { Button, Link } from '@/components'
import { APIClient } from '@/services'
import { Publication } from '@/types'

type SummaryProps = { publication: Publication['id']; version: Publication['version'] }

const DownloadSummary = (props: SummaryProps) => {
  const summary = APIClient.Publications.usePublicationSummary({ id: props?.publication, version: props?.version })

  return (
    <Button
      debugName={'Download manuscript'}
      variants={['small', 'center', 'paddingHorizontal:2', 'flat', 'loadingLeft']}
      icon='download'
      styles={{ text: { fontWeight: '600' }}}
      text={'Download PDF'}
      onPress={summary.download}
      loading={summary.isDownloading}
      loadingShowText={summary.isDownloading}
    />
  )
}

const ViewSummary = (props: SummaryProps) => {
  const summary = APIClient.Publications.usePublicationSummary({ id: props?.publication, version: props?.version })

  const button = (
    <Button
      debugName={'View manuscript'}
      variants={['small', 'center', 'paddingHorizontal:2', 'flat', 'loadingLeft']}
      icon='eye'
      styles={{ text: { fontWeight: '600' }}}
      text={'View PDF'}
      onPress={summary?.url ? null : summary.warnUnavailable}
    />
  )

  if (!summary.url) return button

  return (
    <Link openNewTab to={summary.url} variants={['noUnderline']}>
      {button}
    </Link>
  )
}

export const SummaryButtons = {
  DownloadSummary,
  ViewSummary,
}
