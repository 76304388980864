import { Member, Publisher } from '@/types'
import {
  PaginationResponse,
  QueryManager,
  UsePaginationTypes,
} from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { useManagerTable } from '@/utils'

const BASE_URL = 'profiles/member/'

type ListParams = UsePaginationTypes.PaginationParams & {
  publisher?: Publisher['id']
}

async function list(params?: ListParams) {
  const response = await api.get<PaginationResponse<Member>>(BASE_URL, {
    params,
  })
  return response.data
}

async function create(newMember: Member) {
  const response = await api.post(BASE_URL, newMember)
  return response.data
}

async function update(member: Member & { id: Member['id'] }) {
  const response = await api.patch<Member>(
    `${BASE_URL}${member.id}/`,
    member,
  )
  return response.data
}

async function remove(member: Member) {
  await api.delete(`${BASE_URL}${member.id}/`)
  return member
}

async function retrieve(memberId: Member['id']) {
  const response = await api.get<Member>(`${BASE_URL}${memberId}/`)
  return response.data
}

export const membersManagers = new QueryManager<Member>({
  itemType: {} as Member,
  name: 'members',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  createItem: async (data: Member) => {
    const response = await create(data)
    return response
  },

  retrieveItem: async (id: Member['id']) => {
    const response = await retrieve(id)
    return response || null
  },

  updateItem: async (data: Member) => {
    const response = await update(data)
    return response
  },

  deleteItem: async (data: Member) => {
    const response = await remove(data)
    return response
  },
})

export type UseMembersProps = Partial<ListParams>

export const useMembers = (props?: UseMembersProps) => {
  return useManagerTable(membersManagers, {

    filter: props,

  })

}
