import { retrieve, linkedInAuth, update } from './views'
import { QueryKeys } from '../queryKeys'
import { useLocation } from '@reach/router'
import { AppStatus } from '@/redux'
import type Firebase from 'firebase/auth'
import { getFirebase } from '../../firebaseApp'
import { useLinkedInAuth } from './linkedIn'
import { Profile } from '@/types'
import { Navigation, Modal } from '@/utils'
import { getOsAlert } from '@/app'

const SCOPE = 'Social Authentication'

type FirebaseUser = Firebase.User

type SocialLoginResult = {
  customToken?: string
  credential?: Firebase.AuthCredential
  extraData?: Partial<FirebaseUser> & { error: any }
}

export const CredentialProviders = {
  google: async (): Promise<SocialLoginResult> => {
    try {
      const firebase = await getFirebase()
      const res = await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())

      return {
        credential: res?.credential,
      }
    } catch (err) {
      logger.error({ err })
    }
  },
  linkedIn: async (linkedinToken: string): Promise<SocialLoginResult> => {
    const { profile, token } = await linkedInAuth(linkedinToken)

    return {
      customToken: token,
      extraData: {
        displayName: profile?.localizedFirstName + ' ' + profile?.localizedLastName,
        email: profile?.email,
      },
      credential: null,
    }
  },
  apple: async (): Promise<SocialLoginResult> => {
    try {
      const firebase = await getFirebase()
      const provider = new firebase.auth.OAuthProvider('apple.com')

      provider.addScope('email')
      provider.addScope('name')

      const res = await firebase.auth().signInWithPopup(provider)

      return {
        credential: res?.credential,
      }
    } catch (err) {
      logger.error({ err })
    }
  },
}

export type SocialProviderTypes = keyof typeof CredentialProviders

export type User = Partial<Pick<Profile, 'first_name' | 'last_name' | 'email' | 'id'>>

export const authProvidersList = Object.keys(
  CredentialProviders,
) as SocialProviderTypes[]

export type TrySocialLoginArgs = {
  withProvider?: SocialProviderTypes
}

type SocialLoginOptions = {
  provider: SocialProviderTypes
  params?: { profile?: Profile; [x: string]: any }
  type?: 'login' | 'signup'
}

type SocialLoginReturn = {
  alreadyExistsInBackend: boolean
  user?: User | null
}

export async function socialLogin(options: SocialLoginOptions): Promise<SocialLoginReturn> {
  try {
    const { provider, params = {}, type } = options

    AppStatus.set('loading')

    let firebaseUser: FirebaseUser = null

    let extraData: SocialLoginResult['extraData'] = null

    const firebase = await getFirebase()

    const socialCredential = await CredentialProviders[provider](params)

    extraData = socialCredential?.extraData

    let firebaseCredential = null

    if (socialCredential?.customToken) {
      firebaseCredential = await firebase.auth().signInWithCustomToken(socialCredential.customToken)
    } else {
      firebaseCredential = await firebase.auth().signInWithCredential(socialCredential.credential)
    }

    firebaseUser = firebaseCredential?.user

    let profile = null

    try {
      profile = await retrieve()
    } catch (e) {
      logger.info('Failed to get profile from API during socialLogin ' + provider, e, SCOPE)
    }

    const userExistsOnBackend = !!profile

    if (userExistsOnBackend) {
      if (profile) await update({ id: profile?.id, current_journal: params?.profile?.current_journal, current_publisher: params?.profile?.current_publisher })
      await QueryKeys.me.refresh()
      AppStatus.authFinished()
      AppStatus.set('done')
      return {
        alreadyExistsInBackend: true,
        user: profile,
      }
    }

    if (firebaseUser) {
      const user: User = {
        id: firebaseUser?.uid,
        email: firebaseUser?.email,
      }

      const nameParts = (firebaseUser?.displayName || extraData?.displayName)?.split?.(' ') || ['', '']

      user.first_name = nameParts?.[0]

      if (nameParts.length > 1) {
        user.last_name = nameParts?.[1]
      }

      if (nameParts.length > 0) {
        user.name = nameParts?.join?.(' ')
      }

      // if (type !== 'signup') AppStatus.set('idle') // something is happening
      AppStatus.set('idle')

      return {
        alreadyExistsInBackend: false,
        user: user,
      }
    }
  } catch (e) {
    AppStatus.set('idle')

    if (e?.code === '12501' || e?.code === 'EUNSPECIFIED') return // signup cancel codes google and apple

    logger.error('Error on socialLogin ' + options?.provider, e, SCOPE)

    return {
      alreadyExistsInBackend: false,
      user: null,
    }
  }
}

export const useSocialLogin = (handle: (user: User, provider: SocialProviderTypes) => void, type: 'login' | 'signup', profile?: Partial<Profile>, canSignUp = true) => {
  const location = useLocation()

  const isAuth = location?.pathname?.includes('login') || Modal.getInstance('login').isVisible || Modal.getInstance('signup').visible

  const checkSocialAction = (socialResult: SocialLoginReturn, provider: SocialProviderTypes) => {
    const { user, alreadyExistsInBackend } = socialResult
    const isModal = Modal.getInstance(type).isVisible

    if (Modal.getInstance('signup').visible) {
      Modal.getInstance('signup').close()
    }

    if (isAuth && !alreadyExistsInBackend) {
      if (!!user?.id) {
        Modal.getInstance('login').close()
        const userData = {
          id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email,
        }

        if (canSignUp) setTimeout(() => Modal.getInstance('signup').open({ formValues: userData, provider, data: profile }))
        else getOsAlert('signupError')
      }

      return false
    } else if (!isAuth && alreadyExistsInBackend) {
      if (isModal) {
        Modal.getInstance('signup').close()
        Modal.getInstance('login').close()

        QueryKeys.me.setData(user as Profile)
        QueryKeys.me.refresh()
      }

      Navigation.navigate('Manuscripts.List')

      return false
    }

    return true
  }

  const checkSocialResult = (socialResult: SocialLoginReturn, provider: SocialProviderTypes) => {
    const checked = checkSocialAction(socialResult, provider)
    if (checked) {
      handle?.(socialResult?.user, provider)
    }
  }

  const linkedInAuth = useLinkedInAuth((result) => checkSocialResult(result, 'linkedIn'))

  const handleSocial = async (provider: SocialProviderTypes) => {
    if (provider === 'linkedIn') {
      linkedInAuth()
      return
    }

    const socialResult = await socialLogin({ provider, type, params: { profile }})

    checkSocialResult(socialResult, provider)
  }

  return handleSocial
}
