import React, { useState } from 'react'
import { PropsOf, useUnmount } from '@codeleap/common'
import { Button, List, Touchable, View } from '@/components'
import { ListProps, SearchInput, TextInput } from '@codeleap/web'
import { assignTextStyle, variantProvider } from '@/app'
import { modal } from '@/utils'

export type SelectItemProps = {
  label: string
  value: string
}

type SearchInputProps = PropsOf<typeof SearchInput>

export type SelectJournalModalProps = {
  searchInputProps?: Omit<SearchInputProps, 'debugName'>
  listProps?: Partial<ListProps>
  value?: SelectItemProps
}

export const SelectJournalModal = modal<SelectJournalModalProps>({
  independent: true,
  id: 'selectJournalModal',
}).content(props => {
  const { request, searchInputProps, listProps, value } = props

  const [selectedOption, setSelectedOption] = useState<SelectItemProps>(value)

  useUnmount(() => searchInputProps.onSearchChange(''))

  const renderItem = ({ item }) => {
    const isSelected = selectedOption?.value === item.value

    return (
      <Button
        debugName={item.value}
        text={item.label}
        variants={['fullWidth']}
        onPress={() => setSelectedOption(item)}
        rightIcon={isSelected && 'check'}
        onDoubleClick={() => request.resolve(item)}
        styles={{
          text: {
            ...styles.itemText,
            ...(isSelected && styles['itemText:selected']),
          },
          wrapper: {
            ...styles.itemWrapper,
            ...(isSelected && styles['itemWrapper:selected']),
          },
          rightIcon: styles.itemRightIcon,
        }}
      />
    )
  }

  return (
    <>
      <SearchInput debugName='SelectJournalModal:Search' placeholder='Search' {...searchInputProps} />
      <List debugName='SelectJournalModal:List' renderItem={renderItem} {...listProps} />

      <View css={styles.footer}>
        <Button
          text='Save'
          debugName='SelectJournalModal:Save'
          css={styles.button}
          onPress={() => request.resolve(selectedOption)}
        />
      </View>
    </>
  )
})

type SelectJornalProps = {
  value: SelectItemProps
  onValueChange: (value: SelectItemProps) => void
}

export const SelectJornal = ({ value, onValueChange }: SelectJornalProps) => {
  const handlePress = async () => {
    const selectedOption = await SelectJournalModal.request({ value }, true)
    if (selectedOption) onValueChange(selectedOption as SelectItemProps)
  }

  return (
    <Touchable debugName='SelectJornal:Touchable' onPress={handlePress}>
      <TextInput
        debugName='SelectJornal:TextInput'
        value={value.label}
        variants={['noError']}
        styles={{ wrapper: { pointerEvents: 'none' }}}
        rightIcon={{
          icon: 'chevron-down' as '__ICON__',
          debugName: 'SelectJornal:TextInput:rightIcon',
        }}
      />
    </Touchable>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  itemWrapper: {
    width: '100%',
    height: '100%',
    minHeight: theme.values.itemHeight.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.small,
    ...theme.spacing.padding(2),
    backgroundColor: theme.colors.neutral1,

    transition: 'all 0.2s',

    '&:hover': {
      backgroundColor: theme.colors.neutral2,
    },
  },
  'itemWrapper:selected': {
    backgroundColor: theme.colors.primary1,

    '&:hover': {
      backgroundColor: theme.colors.primary2,
    },
  },
  'itemWrapper:selectedFocused': {
    backgroundColor: theme.colors.primary3,

    '&:hover': {
      backgroundColor: theme.colors.primary3,
    },
  },
  itemText: {
    ...assignTextStyle('p1')(theme).text,
    color: theme.colors.neutral10,
    textAlign: 'left',
  },
  'itemText:selected': {
    ...assignTextStyle('h5')(theme).text,
  },
  itemRightIcon: {
    height: theme.values.iconSize[2],
    width: theme.values.iconSize[2],
    color: theme.colors.neutral10,
  },
  footer: {
    ...theme.presets.justifyCenter,
    ...theme.presets.fullWidth,
  },
  button: {
    maxWidth: 300,
    ...theme.presets.fullWidth,
    ...theme.spacing.marginTop(2),
  },
}), true)

const MODAL_WIDTH = 480

SelectJournalModal.props({
  title: 'Select a Journal',
  variants: ['selectModal'],
  style: { maxWidth: MODAL_WIDTH },
})
