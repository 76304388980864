import { createForm, yup } from '@codeleap/common'
import { Validate } from './validate'

export const reviewerValidationRules = {
  full_name: {
    min: 3,
    max: 30,
  },
  organisation: {
    min: 3,
    max: 250,
  },
}

export const reviewerForm = createForm('createManualInvite', {
  full_name: {
    type: 'text',
    label: 'Full name',
    placeholder: 'Enter a name',
    validate: v => Validate.String(v, { min: reviewerValidationRules.full_name.min, max: reviewerValidationRules.full_name.max }),
  },
  organisation: {
    type: 'text',
    label: 'Organisation',
    placeholder: 'Enter a organisation',
    validate: v => Validate.String(v || '', { min: reviewerValidationRules.organisation.min, max: reviewerValidationRules.organisation.max }),
  },
  email: {
    type: 'text',
    label: 'Email',
    placeholder: 'example@email.co.uk',
    validate: v => Validate.String(v, { isEmail: true }),
  },
  tags: {
    type: 'select',
    label: 'Scientific fields',
    placeholder: 'Search a field',
    multiple: true,
    searchable: true,
    validate: Validate.Select,
  },
})
