import { toolbarOptions } from './ToolbarOptions'
import { useContext } from 'react'
import { useMemo } from '@codeleap/common'
import React from 'react'
import { ToolBarContextProps, ToolBarContextProviderData } from './types'

const ToolBarContext = React.createContext<ToolBarContextProviderData>({} as ToolBarContextProviderData)

export const ToolBarContextProvider = ({ children, toolbarProps }: ToolBarContextProps) => {
  const { editor, fileInputRef, excludeIds, ...rest } = toolbarProps

  const options = useMemo(() => {
    if (excludeIds) {
      return toolbarOptions.map(group => group.filter(option => !excludeIds.includes(option.id)))
    } else {
      return toolbarOptions
    }
  }, [excludeIds?.length])

  return (
    <ToolBarContext.Provider value={{
      editor,
      fileInputRef,
      excludeIds,
      options,
      toolbarProps: rest,
    }}>
      {children}
    </ToolBarContext.Provider>
  )
}

export const useToolBarContext = () => {
  const ctx = useContext(ToolBarContext)
  return ctx
}
