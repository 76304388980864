import { ReviewsOverview, View } from '@/components'
import { NamePreview, NamePreviewProps } from './NamePreview'
import { ReactNode } from 'react'

export type FormItemWrapperProps = Partial<NamePreviewProps> & { children: ReactNode }

export const FormItemWrapper = ({ children, ...namePreviewProps }: FormItemWrapperProps) => {
  return (
    <View variants={['fullWidth', 'column', 'gap:1']}>
      <NamePreview
        onCloseInfo={ReviewsOverview.open}
        {...namePreviewProps}
      />

      {children}
    </View>
  )
}
