import { variantProvider } from '@/app'
import { View, Text, ActivityIndicator, Toaster, Link, Button } from '@/components'
import { APIClient } from '@/services'
import { Publication, PublicationPlagiarismError, PublicationPlagiarismStatus } from '@/types'
import { plagiarismErrors, statusData } from './constants'

export type PlagiarismCheckerProps = {
  id: Publication['id']

}

export const PlagiarismChecker = ({ id }: PlagiarismCheckerProps) => {
  const { status, report, error_code, onViewReport, reportLoading } = APIClient.Articles.useCheckPlagiarism({ id })

  const matchPercentage = report?.overall_match_percentage

  return <PlagiarismStatusView
    status={status}
    error_code={error_code}
    matchPercentage={matchPercentage}
    reportLoading={reportLoading}
    onViewReport={onViewReport}
  />
}

type PlagiarismStatusViewProps = {
  status: PublicationPlagiarismStatus
  reportLink?: string
  matchPercentage?: number
  reportLoading?: boolean
  error_code?: PublicationPlagiarismError
  onViewReport: () => void
}

export const PlagiarismStatusView = (props: PlagiarismStatusViewProps) => {
  const { status, onViewReport, matchPercentage, error_code, reportLoading = false } = props

  const { isAuthor } = APIClient.Session.useSession()

  return (
    <View variants={['column', 'gap:3']}>
      <Toaster.Plagiarism error_code={error_code} status={status} />

      {!!matchPercentage && (
        <View variants={['gap:0.5']}>
          <Text variants={['neutral-8']}>
            {isAuthor ? `Your manuscript is` : `The manuscript is`}
          </Text>

          <Text variants={['link', 'bold', 'neutral-8']}>{matchPercentage}% similar</Text>

          <Text variants={['neutral-8']}>
            to others, including quotes and bibliography.
          </Text>
        </View>
      )}

      <Button
        disabled={reportLoading}
        variants={['large']}
        debugName='PlagiarismChecker:ViewReport'
        text='View report'
        icon='expand'
        css={styles.button}
        loading={reportLoading}
        onPress={onViewReport}
      />

    </View>
  )
}
const styles = variantProvider.createComponentStyle((theme) => ({
  statusCard: {
    ...theme.spacing.padding(2),
    ...theme.presets.alignCenter,
    borderRadius: theme.borderRadius.small,
    gap: theme.spacing.value(2),
  },
  'statusCard:pending': {
    backgroundColor: theme.colors.neutral2,
  },
  'statusCard:ready': {
    backgroundColor: theme.colors.positive1,
  },
  button: {
    maxWidth: 160,
  },
}), true)
