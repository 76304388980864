/* eslint-disable max-len */
import React from 'react'
import { APIClient } from '@/services'
import {
  View,
  Avatar,
  Text,
  Link,
  ActionIcon,
  Touchable,
  RoleSelector,

  PublisherSelector,
  AnnouncementsList,
} from '@/components'
import { variantProvider } from '@/app'
import { useMediaQueryDown, Navigation } from '@/utils'
import { ActionIconProps, Badge } from '@codeleap/web'
import { AppStatus } from '@/redux'
import { IconPlaceholder } from '@codeleap/common'

export const BREAKPOINT = 'mid'

type NotificationButtonProps = {
  notifications: boolean
  icon: IconPlaceholder
} & Partial<ActionIconProps>

const NotificationButton = (props: NotificationButtonProps) => {

  const { notifications, icon } = props
  const debugName = 'Header Notification Button'

  return (
    <Touchable
      variants={['relative', 'cursor:pointer']}
      onPress={AnnouncementsList.open}
      debugName={debugName}
    >
      <ActionIcon
        debugName={debugName}
        variants={['bg:neutral1', 'neutral10', 'iconSize:7']}
        name={icon}
        {...props}
      />
      {notifications ? (
        <Badge style={styles.badge} color={'red'} />
      ) : null}
    </Touchable>
  )
}

export const HeaderItems = () => {

  const { profile } = APIClient.Session.useSession()
  const isMobile = useMediaQueryDown(BREAKPOINT)

  const { badges } = APIClient.Badges.useBadges(profile)

  function toggle() {
    AppStatus.setModal('drawer')
    Navigation.navigate('Profile.Edit')
  }

  return (
    <View style={styles.wrapper}>
      {!isMobile ? (
        <>
          <NotificationButton
            icon='bell'
            notifications={badges?.announcements}
            onPress={AnnouncementsList.open}
          />
          <Link variants={['noUnderline']} to='/profile/edit'>
            <Text
              variants={['p1', 'color:neutral9']}
              text={profile?.first_name}
            />
          </Link>
          <Avatar
            variants={[!isMobile && 'small']}
            debugName={'Header Avatar'}
            onPress={() => {
              Navigation.navigate('Profile.Edit')
            }}
          />
        </>
      ) : (
        <View
          variants={[
            'column',
            'gap:2',
            'fullWidth',
            'justifyCenter',
            'alignCenter',
          ]}
        >
          <Touchable
            onPress={toggle}
            debugName={'Profile redirect'}
            variants={['justifyCenter', 'column', 'gap:1']}
          >
            <Avatar
              variants={[!isMobile && 'small']}
              debugName={'Header Avatar'}
              onPress={() => {
                Navigation.navigate('Profile.Edit')
              }}
            />
            <Link variants={['noUnderline']} to='/profile/edit'>
              <Text
                variants={['p1', 'color:neutral9']}
                text={profile?.first_name}
              />
            </Link>
          </Touchable>
          <PublisherSelector />
          <RoleSelector />
          <NotificationButton
            icon='bell'
            notifications={badges?.announcements}
          />
        </View>
      )}
    </View>
  )
}

const NOTIFICATION_BADGE_SIZE = 12
const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.flex,
      // justifyContent: 'flex-end',
      alignItems: 'center',
      // ...theme.presets.alignStart,
      ...theme.spacing.gap(2),
      flexDirection: 'row',
      [theme.media.down('mid')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'start',
        alignItems: 'center',
      },
    },
    badge: {
      width: NOTIFICATION_BADGE_SIZE,
      height: NOTIFICATION_BADGE_SIZE,
      top: 6,
      left: 10,
    },
  }),
  true,
)
