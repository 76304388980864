import { getOsAlert, Settings } from '@/app'
import { UploadFiles } from '@/redux'
import { APIClient } from '@/services'
import { AnyFunction, TypeGuards, WebInputFile } from '@codeleap/common'
import { base64ToFile, compressBase64Image, fileToBase64, getBase64Size } from './misc'

const splitFilesIntoChunks = (files: File[]): File[][] => {
  const chunks = []
  let currentChunk = []
  let currentSize = 0

  files.forEach(file => {
    if (currentSize + file.size > Settings.FilePickerConfigs.Attachments.max) {
      chunks.push(currentChunk)
      currentChunk = []
      currentSize = 0
    }
    currentChunk.push(file)
    currentSize += file.size
  })

  if (currentChunk.length) {
    chunks.push(currentChunk)
  }

  return chunks
}

const scanFiles = async (files: File[], signal?: AbortSignal, onError?: AnyFunction) => {
  const fileChunks = splitFilesIntoChunks(files)
  const scanIds: string[] = []

  try {
    const sendChunk = async (chunk: File[]) => {
      const response = await APIClient.Articles.scanFiles(chunk, signal)
      scanIds.push(response.scan_id)
      return response.data
    }

    await Promise.all(fileChunks.map(sendChunk))

    return scanIds
  } catch (error) {
    UploadFiles.clear()
    onError?.()
    getOsAlert('scanFilesError')
  }
}

const compressAndValidateImage = async (
  file: WebInputFile | File,
  maxFileSize = Settings.FilePickerConfigs.Attachments.max,
) => {

  let _file: string = (file as WebInputFile)?.preview ?? null

  if (TypeGuards.isNil(_file)) _file = await fileToBase64(file as File)

  const compressedBase64Image = await compressBase64Image(_file)
  const imageSize = getBase64Size(compressedBase64Image)

  if (imageSize > maxFileSize) {
    getOsAlert('tooLargeFileError')
    return null
  }

  const newFile = base64ToFile(compressedBase64Image, file as any)
  return { compressedBase64Image, newFile }
}

export const FileUtils = {
  scanFiles,
  splitFilesIntoChunks,
  compressAndValidateImage,
}
