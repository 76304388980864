import { ReactQuery } from '@codeleap/common'
import { QueryKeys } from '..'
import { update } from './views'

export function useUpdateProfile() {
  const updateProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: update,
    onSuccess: QueryKeys.me.refresh,
  })

  return updateProfile
}
