import { React, AppForms, Theme } from '@/app'
import { AppStatus, ModalName, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { AnyFunction, TypeGuards, useForm, useI18N } from '@codeleap/common'
import { Button, Text, TextInput, View, AuthFormComponents, Link as LinkCP, Select, ForgotPasswordModal, LoginModal } from '@/components'
import { navigate } from 'gatsby'
import { useKeydown } from '@/utils'
import { useMediaQuery } from '@codeleap/web'

type BeforeForgotPasswordFormProps = {
  isModal?: boolean
  title?: string
  visible?: boolean
  modalName?: ModalName
  onRequest?: AnyFunction
}

const BREAKPOINT = 'mid'

export const BeforeForgotPasswordForm = ({ isModal = true, onRequest }: BeforeForgotPasswordFormProps) => {
  const isFocused = useAppSelector(state => state?.AppStatus?.modals?.beforeForgot)
  const isMobile = useMediaQuery(Theme.media.down('mid'), { getInitialValueInEffect: false })

  const form = useForm(AppForms.forgotPassword, {})

  const { t } = useI18N()

  const { requestPasswordReset } = APIClient.Session.useSession()

  const onRequestReset = async () => {
    await requestPasswordReset(form.values.email?.toLowerCase())
    if (TypeGuards.isFunction(onRequest)) onRequest(form.values.email?.toLowerCase())

    if (isModal) {
      ForgotPasswordModal.setParams({ type: 'afterForgot' })
    }
  }

  const onLogin = async () => {
    if (isModal) {
      ForgotPasswordModal.close()
      LoginModal.open()
    } else {
      navigate('/auth/login/')
    }
  }

  useKeydown(() => {
    if (!form.isValid) return
    onRequestReset?.()
  }, [isFocused, isModal, form?.values], 'enter')

  return (
    <View variants={['flex', 'column']}>
      {!isModal && <Text variants={['h4', 'color:neutral8', 'fullWidth']} text={t('ForgotPassword.step1')} />}

      <Text
        text={t('ForgotPassword.title1')}
        variants={['h1', 'marginBottom:1', isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />

      <Text
        text={t('ForgotPassword.subtitle')}
        variants={['p1', `marginBottom:${isMobile ? 4 : 5}`, isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />

      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <TextInput
          {...form.register('email')}
          leftIcon={{ name: 'mail' }}
        />

        <Button
          onPress={onRequestReset}
          disabled={!form.isValid}
          debugName={`submit reset password`}
          text={t('ForgotPassword.submit')}
          variants={['marginHorizontal:auto', 'fullWidth', 'large']}
        />

        <AuthFormComponents.RedirectModal
          text={t('ForgotPassword.logintxt')}
          textAction={t('ForgotPassword.login')}
          onPress={onLogin}
        />
      </View>
    </View>
  )
}
