import { React } from '@/app'
import { AuthModalProps, AuthModalWrapper, LoginForm } from '@/components'
import { modal } from '@/utils'

export const LoginModal = modal<AuthModalProps>({ id: 'login' }).content((props) => {
  const { data } = props

  return (
    <AuthModalWrapper type='login'>
      <LoginForm journalData={data} isModal/>
    </AuthModalWrapper>
  )
}).props({ renderHeader: () => null })
