import { APIClient } from '@/services'
import { useSession } from './useSession'
import { Profile } from './views'

export function useDeleteAccount() {

  const { logout } = useSession()

  const onDeleteAccount = (id: Profile['id']) => {
    logout()
    APIClient.Session.deleteProfile(id)
  }

  return {
    onDeleteAccount,
  }
}
