import { AppForms, React } from '@/app'
import { Text, View, Button, TextInput, TextEditor, TextEditorRef } from '@/components'
import { useForm, useRef } from '@codeleap/common'
import { DisclaimerItemProps, DisclaimerModalProps } from '@/types'
import { modal } from '@/utils'

export const DisclaimerModal = modal<DisclaimerModalProps>({ id: 'disclaimerModal', independent: true }).content((props) => {
  const { item = null, order = 0, request } = props

  const type = !!item ? 'edit' : 'create'
  const textEditorRef = useRef<TextEditorRef>({})
  const form = useForm(AppForms.journalDisclaimerForm, { initialState: {
    title: item?.title,
    description: item?.body,
  }})

  const onSubmit = () => {

    const data: DisclaimerItemProps = {
      id: item?.id,
      created_datetime: new Date().toISOString(),
      title: form?.values?.title,
      body: form?.values?.description,
      order,
    }

    request?.resolve?.({ type, data })
  }

  const shoudDisableEditSubmit = [
    form?.values?.title === item?.title && form?.values?.description === item?.body,
    (textEditorRef.current?.editor?.isEmpty || form?.values?.title?.length === 0),
  ].some(x => x)

  const submitDisabled = item ? shoudDisableEditSubmit : !form.isValid

  return (
    <>
      <Text variants={[`h3`, 'marginBottom:3']} text={`Author disclaimer`} />

      <TextInput {...form.register('title')} />

      <View variants={['fullWidth', 'column']}>
        <Text text={'Description'} variants={['p2', 'color:neutral7', 'paddingBottom:1']} />
        <TextEditor
          ref={textEditorRef}
          onChangeValue={({ editor }) => form.setFieldValue('description', editor.getHTML())}
          value={form.values.description}
          placeholder='Text your message here'
          textEditorProps={{ variants: ['disclaimerModalTextEditor', 'multiline'], styles: { editor: { maxHeight: 180 }}}}
          toolbarComponentProps={{ excludeIds: ['image', 'fileComponent'], variants: ['disclaimerToolbar'] }}
        />
      </View>

      <View variants={['fullWidth', 'marginTop:3', 'marginBottom:4', 'center', 'gap:2']}>
        <Button
          text={`Save`}
          icon={'save'}
          debugName={`On ${type} disclaimer`}
          onPress={onSubmit}
          disabled={submitDisabled}
          variants={['large']}
        />
        <Button
          text={`Cancel`}
          debugName={`Disclaimer Modal - close modal`}
          onPress={request.reject}
          variants={['flat', 'large']}
        />
      </View>
    </>

  )
}).props({
  showClose: false,
  closable: false,
  closeOnEscape: false,
  variants: ['centered', 'disclaimerModal'],
})
