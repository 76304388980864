import { AppForms, OSAlert, Settings, getOsAlert, readableApiError } from '@/app'
import { prepareFilesForUpload, UploadFilesModal, useUploadFilesContext } from '..'
import { DropzoneProps, DropzoneRef } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { CreateOptions, onUpdate, TypeGuards, useRef, useState } from '@codeleap/common'
import { fileSizeValidator } from '@/app/forms'
import { FilePreview } from '../Publication/Forms/FilePreview'
import React from 'react'
import { Attachment, AttachmentWithCategory, Publication, PublicationPlagiarismError } from '@/types'
import { Navigation } from '@/utils/navigation'
import { useFileCategoryOptions } from '@/utils'
import { PrepareFilesForUploadParams } from '../FileUpload/flow'

type PublicationUploadError = {
  code: PublicationPlagiarismError
}

const uploadErrors: Record<PublicationPlagiarismError, string> = {
  UNSUPPORTED_FILETYPE: 'The uploaded filetype is not supported',
  PROCESSING_ERROR: 'An unspecified error occurred while processing the submissions',
  CANNOT_EXTRACT_TEXT: 'The submisison does not contain text or the word count of the submissions is 0',
  TOO_LITTLE_TEXT: 'The submission does not have enough text (at least 20 words)',
  TOO_MUCH_TEXT: 'The submission has too much text, it\'s text content should not exceed 2MB',
  TOO_MANY_PAGES: 'The submission has too many pages (a submission cannot contain more than 800 pages)',
  FILE_LOCKED: 'The file is locked by a password or similar',
  CORRUPT_FILE: 'The file is corrupt and cannot be processed',
}

const initialValidation = { message: '', valid: true }

export const UploadFiles = (props) => {

  const {
    visible,
    onSubmit: _onSubmit,
    validateOnCategoryChange: _validateOnCategoryChange,
    validateOnChange: _validateOnChange,
    showCategory,
    onSendError,
    publication: publicationID,
    onSuccess,
    showConfirmationStep,
    dropzoneProps,
    file_categories: _file_categories,
    initialStep,
    files,
    shouldResetFlowOnSubmit,
    showImageStatement,
  } = useAppSelector(state => state.AppStatus.modals.uploadFiles)

  const { data: publication } = APIClient.Publications.publicationsManager.useRetrieve({ id: publicationID })
  const file_categories = useFileCategoryOptions({ version: publication?.version, status: publication?.status })

  const dropzoneRef = useRef<DropzoneRef>(null)

  const {
    internalFiles,
    setInternalFiles,
    currentModal,
    reducerState,
  } = useUploadFilesContext()

  const [validation, setValidation] = useState(initialValidation)

  const toggle = () => AppStatus.setModal(['uploadFiles', false, { dropzoneProps: null }])

  onUpdate(() => {
    setInternalFiles(reducerState[currentModal]?.acceptedFiles?.map((e) => ({ file: e })))
  }, [reducerState?.[currentModal]?.acceptedFiles])

  function onSubmit() {
    const files = showCategory ? internalFiles : reducerState?.[currentModal]?.acceptedFiles
    _onSubmit(files)
  }

  function validateOnCategoryChange() {
    if (TypeGuards.isFunction(_validateOnCategoryChange)) {
      const files = showCategory ? internalFiles : reducerState?.[currentModal]?.acceptedFiles
      const response = _validateOnCategoryChange(files)
      setValidation(response)
    } else {
      return { message: '', valid: true }
    }
  }

  function validateOnChange(file: File) {
    const onFileChange = _validateOnChange?.(file)
    const onCategoryChange = validateOnCategoryChange?.()
    let vl = initialValidation
    if (!onFileChange?.valid) vl = onFileChange
    else if (!onCategoryChange?.valid) vl = onCategoryChange
    setValidation(vl)
    return fileSizeValidator(file)
  }

  return (
    <UploadFilesModal
      ref={dropzoneRef}
      visible={visible}
      toggle={toggle}
      onSubmit={onSubmit}
      onSendError={onSendError}
      validate={validation}
      showConfirmationStep={showConfirmationStep}
      showImageStatement={showImageStatement}
      initialStep={initialStep}
      shouldResetFlowOnSubmit={shouldResetFlowOnSubmit}
      onSuccess={onSuccess}
      publication={publication}
      files={files}
      dropzoneProps={{
        icon: 'add-file' as any,
        withImagePreview: false,
        multiple: true,
        placeholder: `Please choose a file for upload, or simply drag and drop it here.`,
        variants: showCategory ? ['ellipsis', 'media'] : [],
        validator: validateOnChange,
        ...dropzoneProps,
        FilePreviewComponent: showCategory ? (props) => (
          <FilePreview
            {...props}
            category={internalFiles[props?.index]?.file_category}
            file={props?.file}
            setAttachments={(action) => {
              setInternalFiles(prev => action(prev))
              validateOnCategoryChange()
            }}
            fileCategories={_file_categories.length > 0 ? _file_categories : file_categories}
            disabled={false}
            variants={['ellipsis', 'media']}
          />
        ) : null,
      }}
    />
  )
}

type OpenPublicationParams = {
  create: (data: Partial<Publication>, options?: CreateOptions<Publication>) => Promise<Publication>
  createAttachments: (data: Partial<any>, options?: CreateOptions<any>) => Promise<any>
  dropzoneProps?: Partial<DropzoneProps>
  resetPreviousPublication?: () => void
  showImageStatement?: boolean
}

export async function openUploadPublicationFiles(params: OpenPublicationParams) {

  const {
    create,
    createAttachments,
    dropzoneProps = {},
    resetPreviousPublication = () => null,
    showImageStatement = false,
  } = params

  const profile = APIClient.QueryKeys.me.getData()
  const categories = APIClient.QueryKeys.fileCategory.getData()
  const mainDocCategory = categories.show_on_submission?.find?.(cat => !!cat?.refers_to_main_document)

  const uploadFile = async (acceptedFiles: { file: File; file_category: number }[]) => {
    AppStatus.setModal(['uploadFiles', false, { dropzoneProps: null }])

    AppStatus.set('sectionBreakdown')
    try {

      const files = acceptedFiles
      const mainDocIdx = files.findIndex(main => main.file_category === mainDocCategory.value)

      const res = await create({ files: [files[mainDocIdx]?.file], journal: profile?.current_journal })
      files.splice(mainDocIdx, 1)

      if (res) {
        await createAttachments({ publication: res?.id, files: files })
        await APIClient.Publications.breakdownSections(res?.id)
        AppStatus.set(null)
        resetPreviousPublication?.()
        Navigation.navigate('Manuscripts.List', {
          route: res.id,
          state: {
            isNew: true,
          },
        })
        return
      }
    } catch (err) {
      AppStatus.set(null)
      const readableError = readableApiError<PublicationUploadError>(err)

      if (readableError?.code) {
        getOsAlert('invalidFile', { body: uploadErrors[readableError.code] })
        return
      }
      logger.error('Error creating publication', err, 'Publications')
      logger.slack.echo('ERROR - manuscript creation', { err })

      getOsAlert()
    }
    AppStatus.set(null)

  }

  const validate = ({ newFile = null, attachments = [] }: { newFile?: File; attachments?: Attachment[] }) => {
    const mainDocFiles = attachments?.filter(file => file?.file_category === mainDocCategory?.value)

    if (mainDocFiles.length === 1) {
      const extension = mainDocFiles[0]?.file?.name?.split?.('.').pop()?.toLowerCase()
      const isExtValid = Settings.FilePickerConfigs.CreatePublication.acceptedExtensions.includes(extension)
      return { message: isExtValid ? '' : 'Main manuscript must be .docx, .pdf or .doc', valid: isExtValid }
    }

    if (mainDocFiles.length === 0) {
      return { message: `There must be one file categorized as "${mainDocCategory?.label}"`, valid: false }
    }

    if (mainDocFiles.length > 1) {
      return { message: `There cannot be more than one "${mainDocCategory?.label}"`, valid: false }
    }

    return { message: ``, valid: true }
  }

  AppStatus.setModal(['uploadFiles', true, {
    onSubmit: uploadFile,
    onSendError: () => AppStatus.setModal(['uploadFiles', false, null]),
    showCategory: true,
    validateOnCategoryChange: (attachments) => validate({ attachments }),
    validateOnChange: (file) => validate({ newFile: file }),
    shouldResetFlowOnSubmit: true,
    showConfirmationStep: true,
    publication: null,
    showImageStatement,
    file_categories: [],
    initialStep: null,
    dropzoneProps: {
      ...dropzoneProps,
      multiple: true,
    },
  }])
}

export async function createPublication(files: AttachmentWithCategory[]) {

  AppStatus.set('sectionBreakdown')

  try {

    const [mainDoc, attachmentFiles] = files.reduce((acc, val) => {

      if (val.file_category?.refers_to_main_document) {
        acc[0] = val
      } else {
        acc[1].push(val)
      }

      return acc
    }, [null, []] as [AttachmentWithCategory, AttachmentWithCategory[]])

    const profile = APIClient.QueryKeys.me.getData()

    const publication = await APIClient.Publications.create({ file: mainDoc?.file, journal: profile?.current_journal })

    APIClient.Publications.publicationsManager.addItem({
      item: publication,
    })

    if (publication) {
      await Promise.all([
        APIClient.Publications.createAttachments({ publication: publication?.id, files: attachmentFiles }),
        APIClient.Publications.breakdownSections(publication?.id),
      ])
      AppStatus.set(null)
      // resetPreviousPublication?.()
      Navigation.navigate('Manuscripts.List', {
        route: publication.id,
        state: {
          isNew: true,
        },
      })
      return
    }
  } catch (err) {
    AppStatus.set(null)
    const readableError = readableApiError<PublicationUploadError>(err)

    if (readableError?.code) {
      getOsAlert('invalidFile', { body: uploadErrors[readableError.code] })
      return
    }
    logger.error('Error creating publication', err, 'Publications')
    logger.slack.echo('ERROR - manuscript creation', { err })

    getOsAlert()
  }
  AppStatus.set(null)

}

export function selectPublicationUploadFiles(params?: Partial<PrepareFilesForUploadParams>) {

  return prepareFilesForUpload({
    withCategory: true,
    selectionLimit: Infinity,
    pickerCategoriesFilter: {
      show_on_submission: true,
    },
    showErrors: true,
    pickerDescription: 'Please submit the images for the manuscript separately.',
    showConfirmation: true,
    validator: (attachments) => {
      const mainDocs = attachments.filter(att => att.file_category?.refers_to_main_document)

      if (!mainDocs.length) {
        return {
          message: 'There must be one file categorized as "Main document"',
          valid: false,
        }
      }

      if (mainDocs.length > 1) {
        return {
          message: 'There must be only one file categorized as "Main document"',
          valid: false,
        }
      }

      const mainDoc = mainDocs[0]

      const ext = mainDoc.file.name.split('.').pop().toLowerCase()

      if (!['pdf', 'doc', 'docx'].includes(ext)) {
        return {
          message: 'The main document must be a .pdf, .doc or .docx file',
          valid: false,
        }
      }

      const sizeValidation = AppForms.fileSizeValidator(attachments)
      if (!sizeValidation.valid) return sizeValidation

      return {
        message: '',
        valid: true,
      }
    },
    ...(params ?? {}),
  })

}
